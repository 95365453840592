import React, {useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {mafInvoiceSubmissionStatuses} from '../../mia-entities/invoice-submission';
import {ButtonSecondary, Card, ContentBlock, ContentItem, Icon, Info, Pill, StickyBar, useStyles, eyeIcon} from '../../ui-components';
import {invoiceSubmissionStatusesIcons} from '../constants/invoiceSubmisionStatuses';
import Overlay from '../../ui-components/overlays/Overlay';
import InvoiceSummaryScreen from './InvoiceSummaryScreen';

const InvoiceSubmission = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('invoice_submission');
    const {invoiceSubmissionId, paidAmount, status, statusDescription} = props;
    const {creationDate, invoiceSubmission, invoicingRemarks, paymentInfo, files} = props;
    const [isSummaryViewActive, setIsSummaryViewActive] = useState(false);
    const totalRequestedInvoicingAmount = invoiceSubmission.services && invoiceSubmission.services.filter(service => (
        !!service.requestedInvoicingAmount
    )).map(service => (Number(service.requestedInvoicingAmount)))
        .reduce((accumulator, currentValue) => (accumulator + currentValue), 0);

    const toggleView = () => {
        setIsSummaryViewActive(!isSummaryViewActive);
    };

    return (
        <div
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
            ])}
        >
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--small-justify-space-between',
                ])}
            >
                <p
                    className={cx([
                        'global!ace-u-typography--variant-body-bold',
                        'global!ace-u-margin--small-bottom-4',
                        'global!ace-u-flex--medium-basis-45',
                    ])}
                >
                    {invoiceSubmission.services?.length > 1
                        ? translateTab('invoice_label.multiple_services')
                        : invoiceSubmission.services?.length > 0
                            ? translate(`global.service_type.${invoiceSubmission.services[0].type.toLowerCase()}`)
                            : ''}
                </p>
                <Icon icon={eyeIcon} className={cx('ace-c-icon--48')} onClick={toggleView} />
            </div>
            <p
                className={cx([
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-typography--color-medium-emphasis',
                    'global!ace-u-margin--small-bottom-4',
                ])}
            >
                {translateTab('text.case_number', {invoiceSubmissionId})}
            </p>
            <p
                className={cx([
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-typography--color-medium-emphasis',
                    'global!ace-u-margin--small-bottom-4',
                ])}
            >
                {`${invoiceSubmission.services?.length > 1
                    ? invoiceSubmission.services
                        .map(service => translate(`global.service_type.${service.type.toLowerCase()}`))
                        .join(', ')
                    : invoiceSubmission.services?.length > 0
                        ? translate(`global.service_type.${invoiceSubmission.services[0].type.toLowerCase()}`)
                        : ''}, ${translateTab('services_description.submission_date', {date: creationDate
                    ? moment(creationDate, moment.ISO_8601).format('DD.MM.YYYY')
                    : ''})}`}
            </p>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--small-bottom-4',
                    'global!ace-u-margin--small-top-8',
                ])}
            >
                <p className={cx('global!ace-u-typography--variant-caption')}>
                    {translateTab('text.amount_requested')}
                </p>
                <p className={cx('global!ace-u-typography--variant-caption')}>
                    {`${totalRequestedInvoicingAmount?.toFixed(2).toString().replace('.', ',')} €`}
                </p>
            </div>
            {(status === mafInvoiceSubmissionStatuses.PARTIAL_REFUND
                || status === mafInvoiceSubmissionStatuses.TOTAL_REFUND
                || status === mafInvoiceSubmissionStatuses.FINISHED) && (
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-space-between',
                            'global!ace-u-margin--small-bottom-4',
                        ])}
                    >
                        <p className={cx('global!ace-u-typography--variant-caption')}>
                            {translateTab('text.amount_granted')}
                        </p>
                        <p className={cx('global!ace-u-typography--variant-caption')}>
                            {paidAmount
                                ? `${paidAmount.toFixed(2).toString().replace('.', ',')} €`
                                : '-'}
                        </p>
                    </div>
            )
            }
            <Pill
                icon={invoiceSubmissionStatusesIcons[status]}
                className={cx(['global!ace-u-margin--small-bottom-8', 'global!ace-u-margin--small-top-8'], {
                    'ace-c-pill--pending': status === mafInvoiceSubmissionStatuses.CREDITING_REQUESTED,
                    'ace-c-pill--processing': status === mafInvoiceSubmissionStatuses.PROCESSING,
                    'ace-c-pill--positive': status === mafInvoiceSubmissionStatuses.PARTIAL_REFUND
                        || status === mafInvoiceSubmissionStatuses.TOTAL_REFUND,
                    'ace-c-pill--negative': status === mafInvoiceSubmissionStatuses.DENIED,
                })}
            >
                {translate(`global.invoice_submission_status.${status.toLowerCase()}`)}
            </Pill>
            {(status === mafInvoiceSubmissionStatuses.PARTIAL_REFUND
                    || status === mafInvoiceSubmissionStatuses.DENIED) && (
                        <Info className={cx('global!ace-u-margin--small-bottom-8')}>
                            {statusDescription}
                        </Info>
            )}
            {isSummaryViewActive && (
                <Overlay
                    type="modal"
                    id="summary-screen"
                    position={{top: 0, bottom: 0, left: 0}}
                    className={cx([
                        'global!ace-u-width--full',
                        'ace-c-overlay--modal-background',
                        'ace-c-overlay--scrollable',
                        'global!ace-u-padding--medium-64',
                    ])}
                >
                    <ContentBlock
                        className={cx([
                            'global!ace-u-full-height',
                            'global!ace-u-flex',
                            'global!ace-u-flex--medium-align-flex-start',
                            'global!ace-u-flex--small-align-center',
                            'global!ace-u-flex--medium-justify-center',
                            'global!ace-u-flex--small-justify-flex-start',
                            'global!ace-u-flex--small-direction-column',
                            'global!ace-u-flex--medium-direction-row',
                        ])}
                    >
                        <ContentItem
                            className={cx([
                                'global!ace-u-padding--medium-bottom-64',
                                'global!ace-u-width--small-full',
                                'ace-c-content-item--medium-span-7',
                                'ace-c-content-item--small-span-12',
                            ])}
                        >
                            <Card
                                className={cx([
                                    'global!ace-u-padding--32',
                                    'global!ace-u-flex--medium-grow-1',
                                    'global!ace-u-full-width',
                                    'ace-c-card--variant-white',
                                ])}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-padding--small-bottom-200',
                                        'global!ace-u-padding--medium-bottom-0',
                                    ])}
                                >
                                    <InvoiceSummaryScreen
                                        files={files}
                                        invoiceSubmission={invoiceSubmission}
                                        isEditable={false}
                                        invoicingRemarks={invoicingRemarks}
                                        paymentInfo={paymentInfo}
                                    />
                                </div>
                                <StickyBar
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--small-direction-column',
                                        'global!ace-u-flex--large-direction-row-reverse',
                                        'global!ace-u-flex--small-align-center',
                                        'ace-c-sticky-bar--bottom',
                                        'ace-c-sticky-bar--margin-small-32',
                                    ])}
                                >
                                    <ButtonSecondary
                                        name="closeSummary"
                                        type="button"
                                        onClick={toggleView}
                                        className={cx([
                                            'global!ace-u-width--small-full',
                                            'global!ace-u-width--medium-auto',
                                            'global!ace-u-margin--small-left-16',
                                        ])}
                                    >
                                        {translateTab('button_label.close')}
                                    </ButtonSecondary>
                                </StickyBar>
                            </Card>
                        </ContentItem>
                    </ContentBlock>
                </Overlay>
            )}
        </div>
    );
};

InvoiceSubmission.propTypes = {
    invoiceSubmissionId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    statusDescription: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
    paidAmount: PropTypes.string,
    invoiceSubmission: PropTypes.object,
    invoicingRemarks: PropTypes.string,
    paymentInfo: PropTypes.object,
    files: PropTypes.array.isRequired,
};

InvoiceSubmission.defaultProps = {
    paidAmount: PropTypes.number,
    invoiceSubmission: {},
    invoicingRemarks: '',
    paymentInfo: {},
};

export default InvoiceSubmission;
