import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {calendarIcon, DateField, Form, Input, TextAreaField, useStyles} from '../../ui-components';
import SearchAutocomplete from '../../google/ui-components/SearchAutocomplete';
import {getGeolocationFromString} from '../../google/googleMapFunctions';

const ReturnJourneyAndShortTripSubForm = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('return_journey_and_short_trip_sub_form');
    const {service} = props;
    const [emptyFromAddress, setEmptyFromAddress] = useState(false);
    const [emptyToAddress, setEmptyToAddress] = useState(false);
    const [locations, setLocations] = useState({
        startingAddress: service?.startingAddress,
        destinationAddress: service?.destinationAddress,
    });

    const parseAddress = (field, value) => {
        field === 'startingAddress' ? setEmptyFromAddress(!value) : setEmptyToAddress(!value);

        getGeolocationFromString(value).then(result => {
            let address = null;
            if (result && result.address) {
                address = {
                    street: result.address.street || '',
                    postCode: result.address.postCode,
                    city: result.address.city,
                    country: result.address.country,
                    formattedAddress: result.address.formattedAddress,
                    location: {
                        longitude: result.location?.lng,
                        latitude: result.location?.lat,
                    },
                };
                setLocations(prevState => ({
                    ...prevState,
                    [field]: address,
                }));
                return;
            }
            setLocations(prevState => ({
                ...prevState,
                [field]: address,
            }));
        });
    };

    return (
        <Form name="returnJourneyAndShortTrip">
            {formValues => (
                <Fragment>
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        {translateForm('title.add_details')}
                    </h3>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--small-bottom-16',
                        ])}
                    >
                        {translateForm('text.date_of_travel')}
                    </p>
                    <DateField
                        name="startDateTime"
                        label={translateForm('date_field_label.journey_date')}
                        icon={calendarIcon}
                        format="DD.MM.YYYY"
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--large-bottom-32',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                        value={service?.startDateTime
                            ? moment(service.startDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                            : ''}
                    />
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--small-bottom-16',
                        ])}
                    >
                        {translateForm('text.route')}
                    </p>
                    <SearchAutocomplete
                        placeholder={translateForm('input_placeholder.from_address')}
                        name="formattedStartingAddress"
                        label={translateForm('input_label.from_address')}
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--large-bottom-32',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                        onBlur={() => setEmptyFromAddress(!locations?.startingAddress)}
                        onOptionSelect={value => parseAddress('startingAddress', value)}
                        errors={emptyFromAddress ? [translate('global.error.empty_location')] : []}
                        value={formValues?.hasOwnProperty('formattedStartingAddress')
                            ? formValues.formattedStartingAddress
                            : service?.startingAddress?.formattedAddress || service?.startingAddress
                                ? service.startingAddress.formattedAddress
                                || Object.keys(service?.startingAddress)
                                    .filter(key => key !== 'country' && key !== 'location' && key !== 'persistenceState')
                                    .map(key => (service?.startingAddress[key]))
                                    .filter(value => !!value)
                                    .join(' ')
                                : ''}
                        onChange={() => setLocations(prevState => ({
                            ...prevState,
                            startingAddress: null,
                        }))}
                    />
                    <Input
                        name="startingAddress"
                        type="hidden"
                        value={locations?.startingAddress || ''}
                    />
                    <SearchAutocomplete
                        placeholder={translateForm('input_placeholder.to_address')}
                        name="formattedDestinationAddress"
                        label={translateForm('input_label.to_address')}
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--large-bottom-32',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                        onBlur={() => setEmptyToAddress(!locations?.destinationAddress)}
                        onOptionSelect={value => parseAddress('destinationAddress', value)}
                        errors={emptyToAddress ? [translate('global.error.empty_location')] : []}
                        value={formValues?.hasOwnProperty('formattedDestinationAddress')
                            ? formValues.formattedDestinationAddress
                            : service?.destinationAddress?.formattedAddress || service?.destinationAddress
                                ? service.destinationAddress.formattedAddress
                                || Object.keys(service?.destinationAddress)
                                    .filter(key => key !== 'country' && key !== 'location' && key !== 'persistenceState')
                                    .map(key => (service?.destinationAddress[key]))
                                    .filter(value => !!value)
                                    .join(' ')
                                : ''}
                        onChange={() => setLocations(prevState => ({
                            ...prevState,
                            destinationAddress: null,
                        }))}
                    />
                    <Input
                        name="destinationAddress"
                        type="hidden"
                        value={locations?.destinationAddress || ''}
                    />
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--small-bottom-16',
                        ])}
                    >
                        {translateForm('title.remarks')}
                    </p>
                    <TextAreaField
                        placeholder={translateForm('text_area_placeholder.additional_comment')}
                        name="comment"
                        value={service?.comment || ''}
                        maxLength={250}
                        isResizable={false}
                        className={cx(['ace-c-text-area--small', 'global!ace-u-margin--small-bottom-24', 'global!ace-u-full-width'])}
                        label={translateForm('text_area_label.additional_comment')}
                    />
                </Fragment>
            )}
        </Form>
    );
};

ReturnJourneyAndShortTripSubForm.propTypes = {
    service: PropTypes.object.isRequired,
};

export default ReturnJourneyAndShortTripSubForm;
