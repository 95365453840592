import {createSelector} from 'reselect';

export const getServiceCases = state => state.serviceCases.serviceCases;

export const createServiceCaseSelector = () => createSelector(
    [
        getServiceCases,
        (state, props) => {
            const {serviceCaseId} = props.match.params;
            return serviceCaseId;
        },
    ],
    (serviceCases, serviceCaseId) => {
        if (!serviceCases || !serviceCaseId || !serviceCases[serviceCaseId]) return null;
        return serviceCases[serviceCaseId];
    },
);
