import {mafInvoiceSubmissionStatuses} from '../../mia-entities/invoice-submission';
import {checkIcon, closeIcon, moreIcon, requestIcon} from '../../ui-components';

export const invoiceSubmissionStatusesIcons = {
    [mafInvoiceSubmissionStatuses.CREDITING_REQUESTED]: requestIcon,
    [mafInvoiceSubmissionStatuses.PROCESSING]: moreIcon,
    [mafInvoiceSubmissionStatuses.PARTIAL_REFUND]: checkIcon,
    [mafInvoiceSubmissionStatuses.TOTAL_REFUND]: checkIcon,
    [mafInvoiceSubmissionStatuses.DENIED]: closeIcon,
};

export const invoiceSubmissionStatusesTexts = {
    [mafInvoiceSubmissionStatuses.CREDITING_REQUESTED]: 'Rechnung eingereicht',
    [mafInvoiceSubmissionStatuses.PROCESSING]: 'In Bearbeitung',
    [mafInvoiceSubmissionStatuses.PARTIAL_REFUND]: 'Teilerstattung',
    [mafInvoiceSubmissionStatuses.TOTAL_REFUND]: 'Komplette Erstattung',
    [mafInvoiceSubmissionStatuses.DENIED]: 'Abgelehnt',
};
