import * as authActionTypes from './authActionTypes';

const initialState = {
    isUserSignIn: false,
    error: null,
};

const authReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case authActionTypes.SET_AUTH_ERROR: {
            const {error} = payload;
            state = {
                ...state,
                error,
            };
            break;
        }
        case authActionTypes.AUTH_SESSION_STARTED: {
            state = {
                ...initialState,
                isSessionActive: true,
            };
            break;
        }

        default:
        // no-op
    }
    return state;
};

export default authReducer;
