/**
 * @typedef {string} Service
 */

/**
 * Service
 *
 * @enum {Service}
 */

export default {
    TOWING: 'TOWING',
    ROADSIDE_ASSISTANCE: 'ROADSIDE_ASSISTANCE',
    OPENING: 'OPENING',
    RECOVERY: 'RECOVERY',
    NO_SERVICE: 'NO_SERVICE',
};
