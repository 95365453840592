export const LOGIN_USER = '[auth] LOGIN_USER';

export const LOGIN_USER_REQUEST = '[auth] LOGIN_USER_REQUEST';
export const LOGIN_USER_REQUEST_SENT = '[auth] LOGIN_USER_REQUEST_SENT';
export const LOGIN_USER_REQUEST_SUCCEEDED = '[auth] LOGIN_USER_REQUEST_SUCCEEDED';
export const LOGIN_USER_REQUEST_FAILED = '[auth] LOGIN_USER_REQUEST_FAILED';

export const SET_AUTH_ERROR = '[auth] SET_AUTH_ERROR';
export const AUTH_SESSION_STARTED = '[auth] AUTH_SESSION_STARTED';
export const ACCESS_CONTROL_INITIATED = '[auth] ACCESS_CONTROL_INITIATED';
