import {I18nService} from '@computerrock/formation-i18n';
import {ServiceManager} from '@computerrock/formation-core';
import {DatadogLoggerClient} from '@computerrock/datadog-logger-client';
import {MSClientMIAGateway} from './ms-client-mia-gateway';
import config from './config';
import defaultLocaleTranslations from './locale';

const serviceManager = new ServiceManager();

// initialize Datadog logger
if (config.DATADOG_ENABLE) {
    const ffwLoggerService = serviceManager.loadService('ffwLoggerService');
    ffwLoggerService.setLoggerClient(new DatadogLoggerClient({
        applicationId: config.DATADOG_RUM_APPLICATION_ID,
        clientToken: config.DATADOG_CLIENT_TOKEN,
        service: config.DATADOG_SERVICE,
        options: {
            env: config.DEPLOYMENT_ENV,
            version: `v${config.VERSION}`,
        },
    }));
}

serviceManager.registerService('miaService', new MSClientMIAGateway({
    SERVICE_URL: config.MIA_SERVICE_URL,
}));

serviceManager.registerService('i18nService', I18nService({
    LOCALE: 'de-DE',
    DEFAULT_LOCALE_TRANSLATIONS: defaultLocaleTranslations,
    LOCALE_RESOURCES: [
        {
            locale: 'en-US',
            url: config.MIA_LOCALE_TRANSLATION_EN_US_URL,
        },
        {
            locale: 'de-DE',
            url: config.MIA_LOCALE_TRANSLATION_DE_DE_URL,
        },
    ],
}));

export default serviceManager;
