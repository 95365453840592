import {fork, put, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {fetchRequest} from '@computerrock/formation-core';
import modalIds from '../../modalIds';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {InvoiceSubmission} from '../../mia-entities/invoice-submission';

const addInvoiceSubmissionServiceFlow = function* addInvoiceSubmissionServiceFlow() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.INITIATE_ADD_INVOICE_SUBMISSION_SERVICE_FLOW);
        const {invoiceSubmissionDraftServiceData, selectStep, currentStep, currentServiceIndex} = payload;
        const {invoiceSubmissionDraft} = yield select(state => state.invoiceSubmissions);
        if (!invoiceSubmissionDraft || !invoiceSubmissionDraftServiceData.type) return;

        const existingService = invoiceSubmissionDraft.services.length > 0
            ? invoiceSubmissionDraft.services[currentServiceIndex]
            : null;
        const services = invoiceSubmissionDraft.services?.map(service => service);
        existingService
            ? services[currentServiceIndex] = invoiceSubmissionDraftServiceData
            : services.push(invoiceSubmissionDraftServiceData);

        yield fork(fetchRequest,
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST,
            miaService.updateInvoiceSubmission, {
                invoiceSubmissionDraftData: InvoiceSubmission.objectToPatchDTO({
                    services: services.filter(service => !!service.type),
                }),
                invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
            });

        const actionResult = yield take([
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED,
        ]);

        if (actionResult.error) continue;

        yield* openModal(modalIds.ADD_INVOICE_SUBMISSION_SERVICE_MODAL);

        const {response} = actionResult.payload;
        const newServices = [...response.services];

        const chosenModalOption = yield take([
            invoiceSubmissionActionTypes.CONFIRM_ADD_INVOICE_SUBMISSION_SERVICE,
            invoiceSubmissionActionTypes.DECLINE_ADD_INVOICE_SUBMISSION_SERVICE,
        ]);

        if (chosenModalOption.type === invoiceSubmissionActionTypes.CONFIRM_ADD_INVOICE_SUBMISSION_SERVICE) {
            newServices.splice(currentServiceIndex + 1, 0, {});
        }

        yield put({
            type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
            payload: {
                invoiceSubmissionDraft: {
                    ...response,
                    services: newServices,
                },
            },
        });

        selectStep(currentStep + 1);

        yield* closeModal(modalIds.ADD_INVOICE_SUBMISSION_SERVICE_MODAL);
    }
};

export default addInvoiceSubmissionServiceFlow;
