import {all, fork} from 'redux-saga/effects';
import loginFlow from './sagas/loginFlow';

const authWatcher = function* authWatcher() {
    yield all([
        fork(loginFlow),
    ]);
};

export default authWatcher;
