import React from 'react';
import PropTypes from 'prop-types';
import {useStyles} from '../../ui-components';
import Card from '../../ui-components/app-layout/Card';

const ContactCard = props => {
    const {cx} = useStyles();

    return (
        <Card className={cx([
            'global!ace-u-flex--small-direction-column',
            'global!ace-u-flex--large-direction-row',
            'global!ace-u-padding--small-16',
            'global!ace-u-padding--large-32',
            'ace-c-card--variant-white',
            props.className,
        ])}
        >
            {props.children}
        </Card>
    );
};

ContactCard.propTypes = {
    className: PropTypes.string,
};

ContactCard.defaultProps = {
    className: '',
};

export default ContactCard;
