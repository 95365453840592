import {fork, select, take, put} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import {fetchRequest} from '@computerrock/formation-core';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import config from '../config';
import modalIds from '../modalIds';
import routePaths from '../routePaths';

export const loadServiceCases = function* loadServiceCases() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');
    yield put({
        type: serviceCaseActionTypes.STORE_REQUEST_STATE,
        payload: {isLoading: true},
    });

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.GET_SERVICE_CASES_REQUEST,
        miaService.getServiceCases,
        {
            page: config.defaultServiceCasesPage,
            size: config.defaultServiceCasesPageSize,
        },
    );

    const actionResult = yield take([
        serviceCaseActionTypes.GET_SERVICE_CASES_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.GET_SERVICE_CASES_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASES,
            payload: {
                serviceCasesDTO: actionResult.payload.response.data,
                serviceCasesTotalCount: actionResult.payload.response.totalCount,
                serviceCasesCurrentPage: config.defaultServiceCasesPage,
            },
        });
        yield put({
            type: serviceCaseActionTypes.STORE_REQUEST_STATE,
            payload: {isLoading: false},
        });
    }
};

export const loadExistingServiceCase = function* loadExisingServiceCase({payload}) {
    const {serviceCaseId} = payload.match.params;

    // do not load member data if the user is on the InS creation screen
    // and the CancelInvoiceSubmissionModal is open/closed
    const {location} = yield select(state => state.router);
    const invoiceSubmissionCreationScreenMatch = matchPath(location.pathname, {
        path: routePaths.INVOICE_SUBMISSION_CREATION,
        exact: true,
    });
    if (invoiceSubmissionCreationScreenMatch
        && (location.state?.modalId === modalIds.CANCEL_INVOICE_SUBMISSION_MODAL
            || location.state?.modalId === modalIds.ADD_INVOICE_SUBMISSION_SERVICE_MODAL)) {
        return;
    }

    yield put({
        type: serviceCaseActionTypes.STORE_REQUEST_STATE,
        payload: {isLoading: true},
    });

    const serviceCases = select(state => state.serviceCases.serviceCases);
    if (serviceCases[serviceCaseId]) {
        yield put({
            type: serviceCaseActionTypes.STORE_REQUEST_STATE,
            payload: {isLoading: false},
        });
        return;
    }


    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');
    yield fork(fetchRequest, serviceCaseActionTypes.GET_SERVICE_CASE_REQUEST,
        miaService.getServiceCase, {serviceCaseId});

    const actionResult = yield take([
        serviceCaseActionTypes.GET_SERVICE_CASE_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.GET_SERVICE_CASE_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASES,
            payload: {serviceCasesDTO: [actionResult.payload.response]},
        });
        yield put({
            type: serviceCaseActionTypes.STORE_REQUEST_STATE,
            payload: {isLoading: false},
        });
    }
};

export const loadNewServiceCase = function* loadNewServiceCase() {
    yield put({
        type: serviceCaseActionTypes.STORE_REQUEST_STATE,
        payload: {isLoading: false},
    });
};
