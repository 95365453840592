import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionDetails.module.scss';
import useStyles from '../useStyles';
import {deleteIcon, editIcon, Icon} from '../icons';

const SectionDetails = props => {
    const {cx} = useStyles(props, styles);
    const {children, onEditDetails, isEditable, onDelete, isDeletable} = props;

    return (
        <div className={cx('ace-c-section-details')}>
            <div className={cx('ace-c-section-details__details')}>
                {children}
            </div>
            <div
                className={cx([
                    'ace-c-section-details--no-border',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                    'global!ace-u-flex--align-center',
                ])}
            >
                {isEditable && (
                    <Icon
                        className={cx('ace-c-section-details__icon')}
                        onClick={onEditDetails}
                        icon={editIcon}
                    />
                )}
                {isDeletable && (
                    <Icon
                        className={cx('ace-c-section-details__icon')}
                        onClick={onDelete}
                        icon={deleteIcon}
                    />
                )}
            </div>
        </div>
    );
};

SectionDetails.propTypes = {
    onEditDetails: PropTypes.func,
    onDelete: PropTypes.func,
    isEditable: PropTypes.bool,
    isDeletable: PropTypes.bool,
};

SectionDetails.defaultProps = {
    onEditDetails: () => {},
    onDelete: null,
    isEditable: true,
    isDeletable: false,
};

export default SectionDetails;
