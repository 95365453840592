import React, {useState, Fragment, useContext, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {DatePicker} from '@ace-de/ui-components';
import useStyles from '../useStyles';
import {Icon} from '../icons';
import {withFormContext, withFormContextPropTypes, withFormContextDefaultProps} from '../form/withFormContext';
import DropDown from '../overlays/DropDown';
import DropDownContext from '../overlays/DropDownContext';
import withDropDownProvider from '../overlays/withDropDownProvider';
import FormField from '../form-elements/FormField';
import Input from './Input';
import styles from './DateField.module.scss';

const DateField = React.forwardRef((props, ref) => {
    const {cx} = useStyles(props, styles);
    const {translate} = useTranslate();
    const {value, onChange, label, placeholder, errors, direction, maxDate, minDate, isFieldMandatory} = props;
    const {format, name, defaultValue, isDisabled, icon, timePlaceholder, locale} = props;
    const {dropDownTriggerRef, openDropDown, isOpen} = useContext(DropDownContext);
    const [inputValue, setInputValue] = useState(defaultValue ? moment(defaultValue).format(format) : '');
    const [isTimeExist, setIsTimeExist] = useState(false);
    const [error, setError] = useState('');
    const [isDropDownAboveInput, setIsDropDownAboveInput] = useState(false);
    const [convertedMinDate, setConvertedMinDate] = useState(minDate);
    const [convertedMaxDate, setConvertedMaxDate] = useState(maxDate);
    const dropDownContainerRef = useRef(null);
    useEffect(() => {
        if (value) {
            if (moment(value, format, true).isValid()
                && (maxDate === '' || moment(maxDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).isSameOrAfter(moment(value, format)))
                && (minDate === '' || moment(minDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).isSameOrBefore(moment(value, format)))) {
                setInputValue(value);
                setError('');
            } else {
                let dateInputValue = moment(value, ['DD.MM.YYYY', 'D.M.YYYY'], true).isValid()
                    ? value
                    : moment(value, moment.ISO_8601).isValid()
                        ? moment(value, moment.ISO_8601).format(format)
                        : moment.parseZone(value).utc().isValid()
                            ? moment.parseZone(value).utc(true).format(format)
                            : moment(value, ['DD.MM.YYYY', 'D.M.YYYY']).format(format);
                if (!moment(dateInputValue, format).isValid()) dateInputValue = value;
                if (dateInputValue !== value
                    && (maxDate === ''
                            || moment(maxDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).isSameOrAfter(moment(dateInputValue, format)))
                    && (minDate === ''
                            || moment(minDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).isSameOrBefore(moment(dateInputValue, format)))) {
                    setError('');
                }

                setInputValue(dateInputValue);
            }
        }

        if (value === '') {
            setInputValue('');
        }

        if (format) {
            setIsTimeExist(formatTimeHandler(format));
        }
    }, [value, format, setIsTimeExist, isFieldMandatory, maxDate, minDate]);

    useEffect(() => {
        // we need to convert date in proper format for date picker component
        if (!maxDate && !minDate) return;

        if (minDate && !moment(minDate, moment.ISO_8601).isValid()) {
            setConvertedMinDate(moment(minDate, 'DD.MM.YYYY').format());
        }
        if (maxDate && !moment(maxDate, moment.ISO_8601).isValid()) {
            setConvertedMaxDate(moment(maxDate, 'DD.MM.YYYY').format());
        }
    }, [maxDate, minDate]);

    const handleOnChange = value => {
        if (typeof onChange === 'function') onChange(value);
    };

    const onInputChange = inputValue => {
        if (!/\d*([./]?\d+[./]?\d+)$/.test(inputValue)) {
            setInputValue(inputValue.replace(/[a-zA-Z!@#$%^&*()_+\-=[\]{};':"|,<>± §?]*$/, ''));
            return;
        }
        setInputValue(inputValue);
        setError('');
    };

    const onBlurHandler = () => {
        setIsDropDownAboveInput(false);
        if (error) {
            setError('');
        }
        if (!inputValue) {
            handleOnChange('');
            setInputValue('');
            if (isFieldMandatory) setError(translate('global.error.empty_mandatory_field'));
            return;
        }
        const date = moment(inputValue, format);
        if (date.isValid()
            && (maxDate === '' || moment(maxDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).isSameOrAfter(date))
            && (minDate === '' || moment(minDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).isSameOrBefore(date))) {
            setInputValue(date.format(format));
            handleOnChange(date.format());
            setError('');
            return;
        }

        handleOnChange(inputValue);
        setError(translate('global.error.invalid_date'));
    };

    const invalidDateError = error ? [error] : [];

    const formatTimeHandler = format => {
        let isTimeExist = false;
        const formatSplit = format && format.split(',')[1];
        const formatTime = formatSplit !== undefined && formatSplit.trim();
        if (formatTime === 'HH:mm') {
            isTimeExist = true;
        }

        return isTimeExist;
    };

    useEffect(() => {
        const handleOnScroll = () => {
            if (!dropDownContainerRef.current || !dropDownTriggerRef.current) return;

            const inputTopPosition = dropDownTriggerRef.current.getBoundingClientRect().top;
            const inputBottomPosition = dropDownTriggerRef.current.getBoundingClientRect().bottom;
            const dropDownTopPosition = dropDownContainerRef.current.getBoundingClientRect().top;
            const dropDownBottomPosition = dropDownContainerRef.current.getBoundingClientRect().bottom;

            if (inputTopPosition < dropDownBottomPosition
                && dropDownTopPosition < inputBottomPosition) {
                setIsDropDownAboveInput(true);
                return;
            }

            setIsDropDownAboveInput(false);
        };
        window.addEventListener('scroll', () => handleOnScroll(), true);
        return () => {
            window.removeEventListener('scroll', () => handleOnScroll(), true);
        };
    }, [dropDownContainerRef, dropDownTriggerRef]);

    return (
        <Fragment>
            <FormField
                {...props}
                label={label}
                errors={error ? [error] : errors || []}
            >
                <div className={cx('ace-c-date-field')}>
                    <Input
                        className={cx('ace-c-date-field__input', {
                            'ace-c-date-field__input--is-focused': isOpen,
                        })}
                        ref={dropDownTriggerRef}
                        isComposedIn={true}
                        name={`${name}Text`}
                        placeholder={placeholder}
                        value={inputValue}
                        isDisabled={isDisabled}
                        isCaretVisible={!isDropDownAboveInput}
                        errors={errors.length ? errors : invalidDateError}
                        onChange={onInputChange}
                        onBlur={onBlurHandler}
                        onClick={openDropDown}
                    />
                    {/* Note: icon will be visible in anyway per design */}
                    <Icon
                        icon={icon}
                        className={cx('ace-c-date-field__icon', 'global!ace-c-icon--m', {
                            'ace-c-date-field__icon--is-focused': isOpen,
                            'ace-c-date-field__icon--has-error': !!error,
                        })}
                    />
                </div>
            </FormField>
            <DropDown direction={direction}>
                <div ref={dropDownContainerRef}>
                    <DatePicker
                        ref={ref}
                        isComposedIn={true}
                        name={`input-date-picker-${name}`}
                        value={moment(inputValue, format).format()}
                        onChange={onChange}
                        timePlaceholder={timePlaceholder}
                        locale={locale}
                        hasTime={isTimeExist}
                        maxDate={convertedMaxDate}
                        minDate={convertedMinDate}
                    />
                </div>
            </DropDown>
        </Fragment>
    );
});

DateField.displayName = 'DateField';

DateField.propTypes = {
    ...withFormContextPropTypes,
    format: PropTypes.string,
    direction: PropTypes.string,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    isFieldMandatory: PropTypes.bool,
};

DateField.defaultProps = {
    ...withFormContextDefaultProps,
    format: 'DD.MM.YYYY',
    direction: null,
    maxDate: '',
    minDate: '',
    isFieldMandatory: true,
};

export default withFormContext(withDropDownProvider(DateField));
