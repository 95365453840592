import {put, take} from 'redux-saga/effects';

const deleteFileFlow = function* deleteFileFlow() {
    const {actionTypes} = this;
    while (true) {
        const {payload} = yield take(actionTypes.START_DELETE_FLOW);
        const {id} = payload;
        yield put({
            type: actionTypes.DELETE_FILE,
            payload: {id},
        });
    }
};

export default deleteFileFlow;
