import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';
import {Address} from '../address';
import {Location} from '../location';

/**
 * Damage entity type definition
 *
 * @typedef {Object} Damage
 * @property {?Address} address
 * @property {?string} formattedAddress
 * @property {?string} date
 * @property {?string} description
 * @property {?Location} location
 */

/**
 * Damage entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const damageEntityDTOMapping = {
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
    },
    description: {
        dtoProperty: 'description',
        defaultValue: '',
    },
    date: {
        dtoProperty: 'date',
        defaultValue: '',
    },
    location: {
        dtoProperty: 'location',
        defaultValue: new Location(),
        entity: Location,
    },
};

/**
 * Damage entity
 *
 * @class
 * @extends Entity
 * @type {Damage}
 */
export default class Damage extends Entity {
    [immerable] = true;

    static entityDTOMapping = damageEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
