import React from 'react';
import useStyles from '../useStyles';
import styles from './Overlay.module.scss';
import spinner from '../../assets/images/spinner.svg';
import Overlay from './Overlay';
import * as positions from './positions';

const Loader = props => {
    const {cx} = useStyles(props, styles);

    return (
        <Overlay id="popup" type="popup" position={positions.POPUP_POSITION} className={cx('ace-c-overlay--popup')}>
            <div
                className={cx(['ace-c-overlay__container'])}
            >
                <div className={cx('ace-c-overlay__inner')}>
                    <img className={cx('ace-c-overlay__spinner')} src={spinner} alt="spinner" />
                </div>
            </div>
        </Overlay>
    );
};

export default Loader;
