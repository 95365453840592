import {createSelector} from 'reselect';

export const getInvoiceSubmissions = state => state.invoiceSubmissions.invoiceSubmissions;
export const getActiveInvoiceSubmissionIds = state => state.invoiceSubmissions.activeInvoiceSubmissionIds;
export const getInactiveInvoiceSubmissionIds = state => state.invoiceSubmissions.inactiveInvoiceSubmissionIds;


export const createActiveInvoiceSubmissionsSelector = () => createSelector(
    [getInvoiceSubmissions, getActiveInvoiceSubmissionIds],
    (invoiceSubmissions, activeInvoiceSubmissionIds) => {
        if (!activeInvoiceSubmissionIds.length) return [];
        const activeInvoiceSubmissions = [];
        activeInvoiceSubmissionIds.forEach(invoiceSubmissionId => {
            const invoiceSubmission = invoiceSubmissions[invoiceSubmissionId];
            if (invoiceSubmission) activeInvoiceSubmissions.push(invoiceSubmission);
        });
        return activeInvoiceSubmissions;
    },
);

export const createInactiveInvoiceSubmissionsSelector = () => createSelector(
    [getInvoiceSubmissions, getInactiveInvoiceSubmissionIds],
    (invoiceSubmissions, inactiveInvoiceSubmissionIds) => {
        if (!inactiveInvoiceSubmissionIds.length) return [];
        const inactiveInvoiceSubmissions = [];
        inactiveInvoiceSubmissionIds.forEach(invoiceSubmissionId => {
            const invoiceSubmission = invoiceSubmissions[invoiceSubmissionId];
            if (invoiceSubmission) inactiveInvoiceSubmissions.push(invoiceSubmission);
        });
        return inactiveInvoiceSubmissions;
    },
);
