import {immerable} from 'immer';
import {Damage} from '../damage';
import {Towing} from '../towing';
import Service from './Service';
import {ThirdParty} from '../third-party';
import {Person} from '../person';

/**
 * VehicleService entity type definition
 *
 * @typedef {Object} VehicleService
 * @property {?string} tripPurpose
 * @property {?Person} driver
 * @property {?Person} owner
 * @property {?Damage} damage
 * @property {?Towing} towing
 * @property {?number} numberOfPassengers
 * @property {?string} endOfRecoveryDateTime
 * @property {?boolean} isDriversLicenseValid
 * @property {?boolean} isAccident
 * @property {?boolean} isAlcoholTestDone
 * @property {?string} alcoholTestResult
 * @property {?string} isInsuranceCoversCosts
 * @property {?string} insuranceName
 * @property {?boolean} isRecordedByPolice
 * @property {?Array} policeDepartment
 * @property {?string} policeRecordNumber
 * @property {ThirdParty} thirdParty
 */

/**
 * VehicleService entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

const vehicleAssignmentEntityDTOMapping = {
    thirdParty: {
        dtoProperty: 'thirdParty',
        defaultValue: null,
        entity: ThirdParty,
    },
    damage: {
        dtoProperty: 'damage',
        defaultValue: null,
        entity: Damage,
    },
    towing: {
        dtoProperty: 'towing',
        defaultValue: null,
        entity: Towing,
    },
    driver: {
        dtoProperty: 'driver',
        defaultValue: null,
        entity: Person,
    },
    owner: {
        dtoProperty: 'owner',
        defaultValue: null,
        entity: Person,
    },
    tripPurpose: {
        dtoProperty: 'tripPurpose',
        defaultValue: '',
    },
    numberOfPassengers: {
        dtoProperty: 'numberOfPassengers',
        defaultValue: 0,
    },
    endOfRecoveryDateTime: {
        dtoProperty: 'endOfRecoveryDateTime',
        defaultValue: '',
    },
    isDriversLicenseValid: {
        dtoProperty: 'isDriversLicenseValid',
        defaultValue: false,
    },
    isAccident: {
        dtoProperty: 'isAccident',
        defaultValue: false,
    },
    isAlcoholTestDone: {
        dtoProperty: 'isAlcoholTestDone',
        defaultValue: false,
    },
    alcoholTestResult: {
        dtoProperty: 'alcoholTestResult',
        defaultValue: '',
    },
    isInsuranceCoversCosts: {
        dtoProperty: 'isInsuranceCoversCosts',
        defaultValue: false,
    },
    insuranceName: {
        dtoProperty: 'insuranceName',
        defaultValue: '',
    },
    insuranceNumber: {
        dtoProperty: 'insuranceNumber',
        defaultValue: '',
    },
    policeDepartment: {
        dtoProperty: 'policeDepartment',
        defaultValue: '',
    },
    policeRecordNumber: {
        dtoProperty: 'policeRecordNumber',
        defaultValue: '',
    },
    isRecordedByPolice: {
        dtoProperty: 'isRecordedByPolice',
        defaultValue: false,
    },
};

/**
 * VehicleService entity
 *
 * @class
 * @extends Service
 * @type {VehicleService}
 */
export default class VehicleService extends Service {
    [immerable] = true;

    static entityDTOMapping = this.extendEntityDTOMapping(vehicleAssignmentEntityDTOMapping);

    static extendEntityDTOMapping = entityDTOMapping => {
        return {
            ...vehicleAssignmentEntityDTOMapping,
            ...entityDTOMapping,
        };
    };

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
