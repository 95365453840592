/**
 * @typedef {string} Case
 */

/**
 * Service
 *
 * @enum {Case}
 */

export default {
    PERSON: 'PERSON',
    VEHICLE: 'VEHICLE',
    HOUSEHOLD: 'HOUSEHOLD',
};
