import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';

/**
 * Service entity type definition
 *
 * @typedef {Object} Assignment
 * @property {?string} type
 * @property {?number} requestedInvoicingAmount
 * @property {?string} startDateTime
 * @property {?string} endDateTime
 * @property {?string} serviceProvider
 * @property {?string} comment
 */

/**
 * Service entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

const serviceEntityDTOMapping = {
    type: {
        dtoProperty: 'type',
        defaultValue: '',
    },
    requestedInvoicingAmount: {
        dtoProperty: 'requestedInvoicingAmount',
        defaultValue: 0,
    },
    startDateTime: 'startDateTime',
    endDateTime: 'endDateTime',
    serviceProvider: 'serviceProvider',
    comment: 'comment',
};

/**
 * Service entity
 *
 * @class
 * @extends Entity
 * @type {Service}
 */
export default class Service extends Entity {
    [immerable] = true;

    static entityDTOMapping = serviceEntityDTOMapping;

    static extendEntityDTOMapping = entityDTOMapping => {
        return {
            ...serviceEntityDTOMapping,
            ...entityDTOMapping,
        };
    };

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
