/*
* Modal configuration
*/
import modalIds from './modalIds';
import TestModal from './application/modals/TestModal';
import CancelInvoiceSubmissionModal from './invoice-submission/modals/CancelInvoiceSubmissionModal';
import AddInvoiceSubmissionServiceModal from './invoice-submission/modals/AddInvoiceSubmissionServiceModal';
import EditInvoiceSubmissionModal from './invoice-submission/modals/EditInvoiceSubmissionModal';
import DeleteInvoiceSubmissionServiceModal from './invoice-submission/modals/DeleteInvoiceSubmissionServiceModal';
import DiscardDraftModal from './invoice-submission/modals/DiscardDraftModal';


export default [
    {
        id: modalIds.TEST_MODAL,
        component: TestModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CANCEL_INVOICE_SUBMISSION_MODAL,
        component: CancelInvoiceSubmissionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.ADD_INVOICE_SUBMISSION_SERVICE_MODAL,
        component: AddInvoiceSubmissionServiceModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.EDIT_INVOICE_SUBMISSION_MODAL,
        component: EditInvoiceSubmissionModal,
        hasBackdrop: true,
    },

    {
        id: modalIds.DELETE_INVOICE_SUBMISSION_SERVICE_MODAL,
        component: DeleteInvoiceSubmissionServiceModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DISCARD_DRAFT_MODAL,
        component: DiscardDraftModal,
        hasBackdrop: true,
    },
];
