import qs from 'qs';

export const getContentTypeFromUrl = url => {
    if (!url) return '';
    return qs.parse(url, {ignoreQueryPrefix: true, comma: true}).rsct;
};

export const isAllowedMIMEType = (AllowedTypes, type) => {
    return Object.values(AllowedTypes).indexOf(type) !== -1;
};


export const isPdfFileExtension = url => {
    return isPdfMIMEType(getContentTypeFromUrl(url));
};

export const isPdfMIMEType = type => {
    return type === 'application/pdf';
};

export const lowercaseExtension = name => {
    if (!name) return null;

    const regex = /(JPG|JPEG|PNG|PDF|HEIF)$/i;
    return name.replace(regex, match => {
        return match.toLowerCase();
    });
};
