import {vehicleIcon, otherIcon, deerIcon, driveOnIcon} from '../../ui-components';


export const categories = {
    ROADSIDE_ASSISTANCE_AND_TOWING: 'ROADSIDE_ASSISTANCE_AND_TOWING',
    JOURNEY_CONTINUATION_AND_ACCOMMODATION: 'JOURNEY_CONTINUATION_AND_ACCOMMODATION',
    AID: 'AID',
    OTHER: 'OTHER',
};

export const categoryTexts = {
    [categories.ROADSIDE_ASSISTANCE_AND_TOWING]: '',
    [categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION]: '',
    [categories.AID]: 'Glasbruch, Marderbiss, Wildunfall',
    [categories.OTHER]: '',
};

export const categoryIcons = {
    [categories.ROADSIDE_ASSISTANCE_AND_TOWING]: vehicleIcon,
    [categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION]: driveOnIcon,
    [categories.AID]: deerIcon,
    [categories.OTHER]: otherIcon,
};
