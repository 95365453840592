import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {withFormContext, withFormContextPropTypes, withFormContextDefaultProps} from '../form/withFormContext';
import useStyles from '../useStyles';
import styles from './Input.module.scss';

const Input = React.forwardRef((props, ref) => {
    const {cx} = useStyles(props, styles);
    const {name, value, isDisabled, isCurrency, isCaretVisible, isValueFormatted} = props;
    const {placeholder, isAutoCompleteOff} = props;
    const {onChange, onClick, onBlur, onFocus, onKeyDown, type, maxLength} = props;
    const {isRequired, errors} = props;
    const overlappingElementPosition = useSelector(state => state.invoiceSubmissions.stickyBarPosition);
    const inputRef = useRef(null);
    const [isInputOverlapped, setIsInputOverlapped] = useState(false);
    const [isInFocus, setIsInFocus] = useState(false);

    const handleOnChange = event => {
        const {value} = event.target;
        if (typeof onChange === 'function' && !isCurrency) {
            onChange(value);
        }
        if (isCurrency && typeof onChange === 'function') {
            const char = value.charAt(value.length - 1);
            const regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
            if (name === 'thirdPartyCostsAmount' ? regexp.test(value) : ((char >= '0' && char <= '9') || (char === '.' || char === ',') || !value.length)) {
                onChange(value);
            }
        }
    };

    const handleOnBlur = event => {
        if (typeof onBlur === 'function') onBlur(event.target.value);
        if (isCurrency && !isValueFormatted && typeof onChange === 'function') {
            const {value} = event.target;
            onChange(Number(value).toFixed(2));
        }
        setIsInputOverlapped(false);
        setIsInFocus(false);
    };

    const handleOnFocus = () => {
        if (typeof onFocus === 'function') onFocus();
        setIsInFocus(true);
    };

    useEffect(() => {
        if (!isInFocus) return;

        const handleOnScroll = () => {
            if (!overlappingElementPosition || !inputRef.current) return;

            const inputBottomPosition = inputRef.current.getBoundingClientRect().bottom;
            const overlappingElementTopPosition = overlappingElementPosition.top;

            if (overlappingElementTopPosition < inputBottomPosition) {
                setIsInputOverlapped(true);
                return;
            }

            setIsInputOverlapped(false);
        };
        window.addEventListener('scroll', () => handleOnScroll(), true);
        return () => {
            window.removeEventListener('scroll', () => handleOnScroll(), true);
        };
    }, [isInFocus, inputRef, overlappingElementPosition]);

    return (
        <div ref={inputRef}>
            <input
                ref={ref}
                name={name}
                className={cx('ace-c-input', {
                    'ace-c-input--is-disabled': isDisabled,
                    'ace-c-input--has-error': errors.length,
                    'ace-c-input--is-required': isRequired && value === '',
                    'ace-c-input--full-width': name === 'requestedInvoicingAmount',
                    'ace-c-input--caret-hidden': !isCaretVisible || isInputOverlapped,
                })}
                value={value}
                maxLength={maxLength}
                placeholder={errors.length ? '' : placeholder}
                disabled={isDisabled}
                onChange={handleOnChange}
                onClick={onClick}
                onBlur={handleOnBlur}
                {...(isAutoCompleteOff && {autoComplete: 'off'})}
                type={type}
                onKeyDown={onKeyDown}
                onFocus={handleOnFocus}
            />
        </div>
    );
});

Input.displayName = 'Input';

Input.propTypes = {
    ...withFormContextPropTypes,
    placeholder: PropTypes.string,
    isAutoCompleteOff: PropTypes.bool,
    isCaretVisible: PropTypes.bool,
    type: PropTypes.string,
    isValueFormatted: PropTypes.bool,
};

Input.defaultProps = {
    ...withFormContextDefaultProps,
    placeholder: '',
    isAutoCompleteOff: false,
    isCaretVisible: true,
    type: 'text',
    isValueFormatted: false,
};

export default withFormContext(Input);
