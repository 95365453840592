/**
 * @typedef {string} priceType
 */

/**
 *  Price types
 *
 * @enum {priceType}
 */

export default {
    CONTRACT_PARTNER_FIXED_PRICE: 'CONTRACT_PARTNER_FIXED_PRICE',
    TIME_PRICE: 'TIME_PRICE',
    SURCHARGE_RATE: 'SURCHARGE_RATE',
    SERVICE_FIXED_PRICE: 'SERVICE_FIXED_PRICE',
    PICKUP_PRICE: 'PICKUP_PRICE',
    RECOVERY_PRICE: 'RECOVERY_PRICE',
    OPENING_MAX_PRICE: 'OPENING_MAX_PRICE',
    AID_SERVICE_MAX_PRICE: 'AID_SERVICE_MAX_PRICE',
};
