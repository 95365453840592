export const journeyContinuationServices = {
    FURTHER_JOURNEY: 'FURTHER_JOURNEY',
    SHORT_TRIP: 'SHORT_TRIP',
    RENTAL_CAR: 'RENTAL_CAR',
    ACCOMMODATION: 'ACCOMMODATION',
};

export const journeyContinuationServicesTexts = {
    [journeyContinuationServices.FURTHER_JOURNEY]: 'Rück- / Weiterreise (Bahn / Bus / Flug)',
    [journeyContinuationServices.SHORT_TRIP]: 'Kurzfahrt (Taxi / ÖPNV)',
    [journeyContinuationServices.ACCOMMODATION]: 'Übernachtung',
    [journeyContinuationServices.RENTAL_CAR]: 'Mietwagen',
};
