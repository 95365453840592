import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {useRouter} from '@computerrock/formation-router';
import {arrowLeftIcon, ButtonPrimary, Card, closeIcon, ContentBlock, Form, Icon, Modal, useStyles} from '../../ui-components';
import {isAidFormError, isBankValidationError, isInvoiceValidationError, isJourneyContinuationAndAccommodationError, isOtherFormError, isTowingAndRoadsideAssistanceValidationError} from '../utils/validation';
import AidForm from '../view-elements/AidForm';
import BankInfoForm from '../view-elements/BankInfoForm';
import InvoiceDocumentForm from '../view-elements/InvoiceDocumentForm';
import JourneyContinuationAndAccommodationForm from '../view-elements/JourneyContinuationAndAccommodationForm';
import OtherForm from '../view-elements/OtherForm';
import RoadsideAssistanceAndTowingForm from '../view-elements/RoadsideAssistanceAndTowingForm';
import {formNames} from '../constants/forms';
import {serviceTypes} from '../../mia-entities';
import {journeyContinuationServices} from '../constants/journeyContinuationServices';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {personTypes} from '../constants/person';
import modalIds from '../../modalIds';
import {Attachment} from '../../mia-entities/attachments';
import {services} from '../constants/services';
import {mapServiceToFormFields} from '../utils/mappers';

const EditInvoiceSubmissionModal = props => {
    const {cx} = useStyles();
    const {closeModal} = useRouter();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_invoice_submission_modal');
    const {files, invoiceSubmissionDraft, updateInvoiceSubmissionDraft, aidServicePrice} = props;
    const {location, hasBackdrop} = props;
    const formType = location?.query?.type;
    const serviceIndex = location?.query?.serviceIndex;

    if (!invoiceSubmissionDraft) return null;

    const isValidationError = formValues => {
        if (!formValues) return true;
        const service = serviceIndex ? invoiceSubmissionDraft.services[serviceIndex] : null;
        const mappedService = mapServiceToFormFields(service, invoiceSubmissionDraft.member);

        switch (formType) {
            case formNames.ROADSIDE_ASSISTANCE_AND_TOWING: {
                const {roadsideAssistanceAndTowing: serviceForm} = formValues;
                return isTowingAndRoadsideAssistanceValidationError(serviceForm && Object.keys(serviceForm).length > 0
                    ? serviceForm
                    : mappedService);
            }
            case formNames.JOURNEY_CONTINUATION_AND_ACCOMMODATION: {
                const {journeyContinuationAndAccommodation: serviceForm} = formValues;
                return isJourneyContinuationAndAccommodationError({
                    ...mappedService,
                    ...(serviceForm && Object.keys(serviceForm).length > 0 && serviceForm),
                });
            }
            case formNames.AID: {
                const {aid: serviceForm} = formValues;
                return isAidFormError(serviceForm && Object.keys(serviceForm).length > 0
                    ? serviceForm
                    : mappedService, aidServicePrice);
            }
            case formNames.OTHER: {
                return isOtherFormError(formValues['other'] && Object.keys(formValues['other']).length > 0
                    ? formValues['other']
                    : mappedService);
            }
            case formNames.ATTACHMENTS: {
                const {invoiceDocument = {}} = formValues;
                const uploadedFiles = {
                    ...(files && files),
                    ...(invoiceSubmissionDraft?.attachmentsDetails?.attachments
                        && invoiceSubmissionDraft.attachmentsDetails.attachments
                            .filter(attachment => !(files && files[attachment.id]))),
                };
                return isInvoiceValidationError(
                    Object.values(invoiceDocument) ? invoiceDocument : invoiceSubmissionDraft?.attachmentsDetails,
                    uploadedFiles,
                );
            }
            case formNames.BANK_INFO: {
                const {bankInfo} = formValues;
                return isBankValidationError(bankInfo || invoiceSubmissionDraft.paymentInfo);
            }
        }
    };

    const handleOnSubmit = formValues => {
        if (!formValues && !invoiceSubmissionDraft) return;
        let invoiceSubmissionDraftData;
        const invoiceSubmissionServicesDraftData = [...invoiceSubmissionDraft.services];
        const [memberFirstName = '', memberLastName = ''] = invoiceSubmissionDraft.member?.name
            ? invoiceSubmissionDraft.member.name.split(' ')
            : ['', ''];

        switch (formType) {
            case formNames.ROADSIDE_ASSISTANCE_AND_TOWING: {
                const {roadsideAssistanceAndTowing: form} = formValues;
                if (!(form && typeof form === 'object' && Object.values(form).length > 0)) break;

                invoiceSubmissionServicesDraftData[serviceIndex] = {
                    ...invoiceSubmissionDraft.services[serviceIndex],
                    ...form,
                    damage: {
                        date: form.damageDate ? moment(form.damageDate, ['DD.MM.YYYY', moment.ISO_8601]).toISOString() : '',
                        description: form.comment,
                        address: form.damageLocation,
                        location: form.damageLocation.location,
                        formattedAddress: form.damageLocation.formattedAddress,
                    },
                    ...(form.type === services.TOWING && {
                        towing: {
                            address: form.towingLocation,
                        },
                    }),
                    driver: {
                        type: form.driverType,
                        name: form.driverType === personTypes.MEMBER
                            ? memberFirstName
                            : form.driverFirstName,
                        surname: form.driverType === personTypes.MEMBER
                            ? memberLastName
                            : form.driverLastName,
                        address: form.driverType === personTypes.MEMBER
                        || (form.driverType !== personTypes.MEMBER && form.isDriverAddressAsMember)
                            ? invoiceSubmissionDraft.member?.address
                            : {
                                street: form.driverAddressStreet,
                                postCode: form.driverAddressPostCode,
                                city: form.driverAddressCity,
                                country: null,
                                countryCode: null,
                            },
                    },
                    owner: {
                        type: form.ownerType,
                        name: form.ownerType === personTypes.MEMBER
                            ? memberFirstName
                            : form.ownerFirstName,
                        surname: form.ownerType === personTypes.MEMBER
                            ? memberLastName
                            : form.ownerLastName,
                        address: form.ownerType === personTypes.MEMBER
                        || (form.ownerType !== personTypes.MEMBER && form.isOwnerAddressAsMember)
                            ? invoiceSubmissionDraft.member?.address
                            : {
                                street: form.ownerAddressStreet,
                                postCode: form.ownerAddressPostCode,
                                city: form.ownerAddressCity,
                                country: null,
                                countryCode: null,
                            },
                    },
                    ...((form.isAccidentCausedByThirdParty || form.isThirdPartyCost) && {
                        thirdParty: {
                            name: form.thirdPartyName,
                            licensePlate: form.thirdPartyLicensePlate,
                            isCoveringCosts: form.isInsuranceCoversCosts,
                            isCausedAccident: form.isAccidentCausedByThirdParty,
                            insuranceName: form.thirdPartyInsuranceName,
                            insuranceNumber: form.thirdPartyInsuranceNumber,
                            damageNumber: form.thirdPartyDamageNumber,
                            costsCoveredBy: form.thirdPartyCostsCoveredBy,
                            costsPurpose: form.thirdPartyCostsPurpose,
                            costsAmount: form.thirdPartyCostsAmount,
                            address: {
                                street: form.thirdPartyAddressStreet,
                                postCode: form.thirdPartyAddressPostCode,
                                city: form.thirdPartyAddressCity,
                                country: null,
                                countryCode: null,
                            },
                        },
                    }),
                    requestedInvoicingAmount: Number(form.requestedInvoicingAmount),
                    ...(form.endOfRecoveryDateTime && {
                        endOfRecoveryDateTime: moment(form.endOfRecoveryDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                    }),
                };
                invoiceSubmissionDraftData = {
                    services: invoiceSubmissionServicesDraftData,
                };
                break;
            }
            case formNames.JOURNEY_CONTINUATION_AND_ACCOMMODATION: {
                const {journeyContinuationAndAccommodation: form} = formValues;
                if (!form?.type) break;

                let serviceSubForm;
                if (form.type === journeyContinuationServices.FURTHER_JOURNEY
                    || form.type === journeyContinuationServices.SHORT_TRIP) {
                    const {returnJourneyAndShortTrip} = form;
                    serviceSubForm = {
                        type: serviceTypes.FURTHER_JOURNEY,
                        ...returnJourneyAndShortTrip,
                    };
                }
                if (form.type === journeyContinuationServices.RENTAL_CAR) {
                    const {rentalCar} = form;
                    serviceSubForm = {
                        type: serviceTypes.RENTAL_CAR,
                        ...rentalCar,
                    };
                }
                if (form.type === journeyContinuationServices.ACCOMMODATION) {
                    const {accommodation} = form;
                    serviceSubForm = {
                        type: serviceTypes.ACCOMMODATION,
                        ...accommodation,
                    };
                }
                invoiceSubmissionServicesDraftData[serviceIndex] = {
                    ...invoiceSubmissionDraft.services[serviceIndex],
                    ...form,
                    ...serviceSubForm,
                    category: formValues.category,
                    ...(serviceSubForm.startDateTime && {
                        startDateTime: moment(serviceSubForm.startDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                    }),
                    ...(serviceSubForm.endDateTime && {
                        endDateTime: moment(serviceSubForm.endDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                    }),
                    requestedInvoicingAmount: Number(form.requestedInvoicingAmount),
                };
                invoiceSubmissionDraftData = {
                    services: invoiceSubmissionServicesDraftData,
                };
                break;
            }
            case formNames.AID: {
                const {aid: form} = formValues;
                if (Object.values(form).length === 0) break;

                invoiceSubmissionServicesDraftData[serviceIndex] = {
                    ...invoiceSubmissionDraft.services[serviceIndex],
                    ...form,
                    driver: {
                        type: form.driverType,
                        name: form.driverType === personTypes.MEMBER
                            ? memberFirstName
                            : form.driverFirstName,
                        surname: form.driverType === personTypes.MEMBER
                            ? memberLastName
                            : form.driverLastName,
                        address: form.driverType === personTypes.MEMBER
                        || (form.driverType !== personTypes.MEMBER && form.isDriverAddressAsMember)
                            ? invoiceSubmissionDraft.member?.address
                            : {
                                street: form.driverAddressStreet,
                                postCode: form.driverAddressPostCode,
                                city: form.driverAddressCity,
                                country: null,
                                countryCode: null,
                            },
                    },
                    owner: {
                        type: form.ownerType,
                        name: form.ownerType === personTypes.MEMBER
                            ? memberFirstName
                            : form.ownerFirstName,
                        surname: form.ownerType === personTypes.MEMBER
                            ? memberLastName
                            : form.ownerLastName,
                        address: form.ownerType === personTypes.MEMBER
                        || (form.ownerType !== personTypes.MEMBER && form.isOwnerAddressAsMember)
                            ? invoiceSubmissionDraft.member.address
                            : {
                                street: form.ownerAddressStreet,
                                postCode: form.ownerAddressPostCode,
                                city: form.ownerAddressCity,
                                country: null,
                                countryCode: null,
                            },
                    },
                    damage: {
                        date: form.damageDate ? moment(form.damageDate, ['DD.MM.YYYY', moment.ISO_8601]).toISOString() : '',
                        description: form.comment,
                        address: form.damageLocation,
                        location: form.damageLocation.location,
                    },
                    requestedInvoicingAmount: Number(form.requestedInvoicingAmount),
                    ...(form.endOfRecoveryDateTime && {
                        endOfRecoveryDateTime: moment(form.endOfRecoveryDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                    }),
                };
                invoiceSubmissionDraftData = {
                    services: invoiceSubmissionServicesDraftData,
                };
                break;
            }
            case formNames.OTHER: {
                if (!(formValues['other'] && typeof formValues['other'] === 'object'
                    && Object.values(formValues['other']).length > 0)) break;

                invoiceSubmissionServicesDraftData[serviceIndex] = {
                    type: serviceTypes.OTHER,
                    ...invoiceSubmissionDraft.services[serviceIndex],
                    ...formValues['other'],
                    requestedInvoicingAmount: Number(formValues['other'].requestedInvoicingAmount),
                };
                invoiceSubmissionDraftData = {
                    services: invoiceSubmissionServicesDraftData,
                };
                break;
            }
            case formNames.ATTACHMENTS: {
                const {invoiceDocument} = formValues;
                if (!(invoiceDocument && Object.values(invoiceDocument)) && !(files && Object.values(files))) break;

                const attachments = Object.values({
                    ...(!!files && files),
                    ...(!!invoiceSubmissionDraft?.attachmentsDetails?.attachments
                        && invoiceSubmissionDraft?.attachmentsDetails?.attachments
                            .filter(attachment => !(files && files[attachment.id]))),
                }).map(file => new Attachment().fromDTO(file));

                if (!(invoiceDocument && Object.values(invoiceDocument))) {
                    invoiceSubmissionDraftData = {
                        attachmentsDetails: {
                            attachments,
                        },
                    };
                    break;
                }

                const {
                    invoiceUploaded,
                    repairInvoiceUploaded,
                    carInsuranceUploaded,
                    wildlifeDamageCertUploaded,
                    ...restFormValues
                } = invoiceDocument;

                invoiceSubmissionDraftData = {
                    attachmentsDetails: {
                        attachments,
                        invoiceUploaded: !!invoiceUploaded,
                        repairInvoiceUploaded: !!repairInvoiceUploaded,
                        carInsuranceUploaded: !!carInsuranceUploaded,
                        wildlifeDamageCertUploaded: !!wildlifeDamageCertUploaded,
                    },
                    ...restFormValues,
                };
                break;
            }
            case formNames.BANK_INFO: {
                const {bankInfo} = formValues;
                invoiceSubmissionDraftData = {
                    paymentInfo: typeof bankInfo === 'object' && Object.keys(bankInfo).length > 0
                        ? bankInfo
                        : invoiceSubmissionDraft.paymentInfo,
                };
                break;
            }
        }

        if (invoiceSubmissionDraftData) updateInvoiceSubmissionDraft({invoiceSubmissionDraftData});
        closeModal(modalIds.EDIT_INVOICE_SUBMISSION_MODAL, {
            type: formType,
            serviceIndex,
        });
    };

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            className={cx([
                'global!ace-c-modal--full-width',
                'global!ace-c-modal--full-scrollable',
                'global!ace-u-height--full',
                'global!ace-u-padding--top-0',
            ])}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-full-height',
                    'global!ace-u-flex',
                    'global!ace-u-flex--small-align-center',
                    'global!ace-u-flex--medium-align-flex-start',
                    'global!ace-u-flex--medium-justify-center',
                    'global!ace-u-flex--small-direction-column',
                    'global!ace-u-flex--medium-direction-row',
                    'global!ace-u-margin--medium-bottom-24',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-display--medium-none',
                        'global!ace-u-flex--small-align-self-flex-end',
                    ])}
                >
                    <Icon
                        icon={closeIcon}
                        className={cx([
                            'global!ace-u-display--small-block',
                        ])}
                        onClick={() => closeModal(modalIds.EDIT_INVOICE_SUBMISSION_MODAL, {
                            type: formType,
                            serviceIndex,
                        })} // reset state
                    />
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-display--medium-flex',
                        'global!ace-u-display--small-none',
                    ])}
                    onClick={() => closeModal(modalIds.EDIT_INVOICE_SUBMISSION_MODAL, {
                        type: formType,
                        serviceIndex,
                    })} // reset state
                >
                    <Icon
                        icon={arrowLeftIcon}
                        className={cx('ace-c-icon--color-primary', 'global!ace-u-cursor--pointer')}
                    />
                    <p className={cx('global!ace-u-typography--variant-text-link')}>
                        {translateModal('link_label.go_back')}
                    </p>
                </div>
                <Card className={cx(['global!ace-u-padding--medium-32', 'global!ace-u-full-width'])}>
                    <Form name="quickAccess" onSubmit={handleOnSubmit}>
                        {formValues => (
                            <Fragment>
                                {formType === formNames.ROADSIDE_ASSISTANCE_AND_TOWING && (
                                    <RoadsideAssistanceAndTowingForm
                                        service={invoiceSubmissionDraft.services[serviceIndex]}
                                    />
                                )}
                                {formType === formNames.AID && (
                                    <AidForm service={invoiceSubmissionDraft.services[serviceIndex]} />
                                )}
                                {formType === formNames.OTHER && (
                                    <OtherForm service={invoiceSubmissionDraft.services[serviceIndex]} />
                                )}
                                {formType === formNames.JOURNEY_CONTINUATION_AND_ACCOMMODATION && (
                                    <JourneyContinuationAndAccommodationForm
                                        service={invoiceSubmissionDraft.services[serviceIndex]}
                                    />
                                )}
                                {formType === formNames.ATTACHMENTS && (
                                    <InvoiceDocumentForm isOverlay={true} />
                                )}
                                {formType === formNames.BANK_INFO && (
                                    <BankInfoForm isOverlay={true} />
                                )}
                                <div
                                    className={cx([
                                        'global!ace-u-width--full',
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--small-align-center',
                                        'global!ace-u-flex--medium-align-flex-start',
                                        'global!ace-u-flex--small-justify-center',
                                        'global!ace-u-flex--medium-justify-flex-end',
                                        'global!ace-u-flex--small-direction-column',
                                        'global!ace-u-flex--medium-direction-row',
                                    ])}
                                >
                                    <ButtonPrimary
                                        name="formOverlayButton"
                                        type="submit"
                                        isDisabled={isValidationError(formValues)}
                                        className={cx([
                                            'global!ace-u-margin--small-bottom-16',
                                            'global!ace-u-flex--small-align-self-stretch',
                                            'global!ace-u-flex--medium-align-self-flex-end',
                                        ])}
                                    >
                                        {translateModal('button_label.save')}
                                    </ButtonPrimary>
                                </div>
                            </Fragment>
                        )}
                    </Form>
                </Card>

            </ContentBlock>
        </Modal>
    );
};

EditInvoiceSubmissionModal.propTypes = {
    files: PropTypes.object.isRequired,
    invoiceSubmissionDraft: PropTypes.object,
    updateInvoiceSubmissionDraft: PropTypes.func.isRequired,
    location: PropTypes.object,
    hasBackdrop: PropTypes.bool,
    aidServicePrice: PropTypes.number,
};

EditInvoiceSubmissionModal.defaultProps = {
    invoiceSubmissionDraft: null,
    location: null,
    hasBackdrop: false,
    aidServicePrice: 0,
};

const mapStateToProps = state => {
    return {
        invoiceSubmissionDraft: state.invoiceSubmissions.invoiceSubmissionDraft,
        files: state.invoiceSubmissionFilesReducer.files,
        aidServicePrice: state.invoiceSubmissions.aidServicePrice,
    };
};

const mapDispatchToProps = dispatch => ({
    updateInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInvoiceSubmissionModal);
