import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';
import Address from '../address/Address';

/**
 * Towing entity type definition
 *
 * @typedef {Object} Towing
 * @property {?Address} address
 * @property {?string} formattedAddress
 * @property {?string} description
 */

/**
 * Towing entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const towingEntityDTOMapping = {
    address: {
        dtoProperty: 'address',
        entity: Address,
    },
    description: {
        dtoProperty: 'description',
        defaultValue: '',
    },
};

/**
 * Towing entity
 *
 * @class
 * @extends Entity
 * @type {Towing}
 */
export default class Towing extends Entity {
    [immerable] = true;

    static entityDTOMapping = towingEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
