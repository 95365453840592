import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, ButtonSecondary, Card, checkIcon, Circle, ContentBlock, ContentItem, Icon, mailIcon, phoneIcon, useStyles, warningIcon} from '../ui-components';
import ContactCard from '../application/view-elements/ContactCard';
import ContactDetailRow from '../application/view-elements/ContactDetailRow';
import routePaths from '../routePaths';
import {createInvoiceSubmissionStatuses} from './constants/createInvoiceSubmissionStatuses';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';

const statusIcon = {
    [createInvoiceSubmissionStatuses.SUCCEEDED]: checkIcon,
    [createInvoiceSubmissionStatuses.FAILED]: warningIcon,
    [createInvoiceSubmissionStatuses.FAILED_AGAIN]: warningIcon,
};

const CreateStatusScreen = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('create_status_screen');
    const {status, reSubmitForm, changeCreateStatus, resubmitCount, resetResubmitCount} = props;
    const {storeInvoiceSubmissionDraft} = props;

    return (
        <Fragment>
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'ace-c-content-item--base-span-12',
                        'ace-c-content-item--small-span-8',
                        'ace-c-content-item--medium-span-6',
                        'ace-c-content-item--large-span-4',

                    ])}
                >
                    <Card
                        className={cx([
                            'global!ace-u-padding--small-16',
                            'global!ace-u-padding--large-32',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <Circle
                            className={cx('ace-c-circle--medium-responsive', 'global!ace-u-margin--small-bottom-32', {
                                'ace-c-circle--is-success': status === createInvoiceSubmissionStatuses.SUCCEEDED,
                                'ace-c-circle--is-warning': status === createInvoiceSubmissionStatuses.FAILED || status === createInvoiceSubmissionStatuses.FAILED_AGAIN,
                            })}
                        >
                            <Icon
                                icon={statusIcon[status]}
                                className={cx('ace-c-icon--48', {
                                    'ace-c-icon--color-success': status === createInvoiceSubmissionStatuses.SUCCEEDED,
                                    'ace-c-icon--color-primary': status === createInvoiceSubmissionStatuses.FAILED || status === createInvoiceSubmissionStatuses.FAILED_AGAIN,
                                })}
                            />
                        </Circle>
                        <h2
                            className={cx([
                                'global!ace-u-typography--variant-h2',
                                'global!ace-u-margin--small-bottom-24',
                                'global!ace-u-typography--align-center',
                            ])}
                        >
                            {status === createInvoiceSubmissionStatuses.FAILED_AGAIN ? `${translateScreen(`title.${status.toLowerCase()}`)} ${resubmitCount}` : translateScreen(`title.${status.toLowerCase()}`)}
                        </h2>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-typography--align-center',
                                'global!ace-u-margin--small-bottom-24',
                                'global!ace-u-margin--large-bottom-48',
                            ])}
                        >
                            {translateScreen(`text.${status.toLowerCase()}`)}
                        </p>
                        <div className={cx([
                            'global!ace-u-width--small-full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-center',
                            'global!ace-u-flex--small-direction-column',
                            'global!ace-u-flex--large-direction-row',
                        ])}
                        >
                            {(status === createInvoiceSubmissionStatuses.FAILED
                                    || status === createInvoiceSubmissionStatuses.FAILED_AGAIN)
                                && (
                                    <ButtonPrimary
                                        className={cx([
                                            'global!ace-u-width--small-full',
                                            'global!ace-u-width--medium-auto',
                                            'global!ace-u-margin--small-bottom-24',
                                            'global!ace-u-margin--large-right-24',
                                        ])}
                                        onClick={reSubmitForm}
                                    >
                                        {translateScreen('button_label.try_again')}
                                    </ButtonPrimary>
                                )}
                            <ButtonSecondary
                                className={cx(['global!ace-u-width--small-full', 'global!ace-u-width--medium-auto'])}
                                onClick={() => {
                                    changeCreateStatus({createInvoiceSubmissionState: createInvoiceSubmissionStatuses.IDLE}); // eslint-disable-line
                                    resetResubmitCount();
                                    storeInvoiceSubmissionDraft({invoiceSubmissionDraft: null});
                                    history.push(resolveRoute(routePaths.DASHBOARD));
                                }}
                            >
                                {translateScreen('button_label.to_home_page')}
                            </ButtonSecondary>
                        </div>
                    </Card>
                </ContentItem>
            </ContentBlock>
            <ContentBlock className={cx([
                'global!ace-u-position--small-absolute',
                'global!ace-u-position--small-bottom-0',
                'global!ace-u-position--small-left-8',
                'global!ace-u-flex',
                'global!ace-u-flex--justify-center',
                'global!ace-u-full-width',
                'global!ace-u-padding--small-left-16',
                'global!ace-u-padding--small-right-16',
                'global!ace-u-padding--large-left-128',
                'global!ace-u-padding--large-right-128',
            ])}
            >
                <ContentItem>
                    <ContactCard>
                        <h3 className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--small-bottom-8',
                            'global!ace-u-margin--large-bottom-0',
                            'global!ace-u-margin--large-right-48',
                        ])}
                        >
                            Kontakt zum Mitglied-Service
                        </h3>
                        <ContactDetailRow>
                            <Icon className={cx(['ace-c-icon--24', 'ace-c-icon--color-highlight', 'global!ace-u-margin--right-8'])} icon={mailIcon} />
                            <a href="mailto:info@ace.de" className={cx(['global!ace-u-typography--variant-body-medium'])}>info@ace.de</a>
                        </ContactDetailRow>
                        <div className={cx(['global!ace-u-margin--bottom-16', 'global!ace-u-margin--large-right-48'])} />
                        <ContactDetailRow>
                            <Icon className={cx(['ace-c-icon--24', 'ace-c-icon--color-highlight', 'global!ace-u-margin--right-8'])} icon={phoneIcon} />
                            <a href="tel:+49711530336677" className={cx(['global!ace-u-typography--variant-body-medium'])}>+49 711 530 33 66 77</a>
                        </ContactDetailRow>
                    </ContactCard>
                </ContentItem>
            </ContentBlock>
        </Fragment>

    );
};

CreateStatusScreen.propTypes = {
    status: PropTypes.string.isRequired,
    reSubmitForm: PropTypes.func.isRequired,
    changeCreateStatus: PropTypes.func.isRequired,
    resetResubmitCount: PropTypes.func.isRequired,
    resubmitCount: PropTypes.number.isRequired,
    storeInvoiceSubmissionDraft: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    storeInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
});


export default connect(null, mapDispatchToProps)(CreateStatusScreen);
