import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import useStyles from '../useStyles';
import styles from './Stepper.module.scss';
import StepperContext from './StepperContext';

const Stepper = props => {
    const {cx} = useStyles(props, styles);
    const {selectStep, currentStep} = useContext(StepperContext);
    const {steps} = props;
    const {translate} = useTranslate();

    return (
        <div className={cx('ace-c-stepper')}>
            <div className={cx('ace-c-stepper__track')} />
            <div
                className={cx('ace-c-stepper__track--highlight')}
                style={currentStep > 2 ? {width: (100 / (steps.length - 1)) * (currentStep - 2) + '%'} : {width: 0}}
            />
            {steps.map(step => {
                const {title, stepNumber} = step;
                return (
                    <div
                        key={stepNumber}
                        className={cx('ace-c-stepper__step')}
                        onClick={() => {
                            if (stepNumber < currentStep) selectStep(stepNumber);
                        }}
                    >
                        <div
                            className={cx('ace-c-stepper__step__number', {'ace-c-stepper__step__number--highlight': stepNumber <= currentStep})}
                        >{stepNumber}
                        </div>
                        <div
                            className={cx('ace-c-stepper__step__title', {'ace-c-stepper__step__title--bold': stepNumber === currentStep})}
                        >{translate(`case_creation.form_step_name.${title.toLowerCase()}`)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

Stepper.propTypes = {
    steps: PropTypes.array.isRequired,
};

export default Stepper;
