import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useParams, resolveRoute, useHistory} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Tab, TabBar} from '../ui-components/navigation';
import invoiceSubmissionTabs from './constants/invoiceSubmissionTabs';
import routePaths from '../routePaths';
import {createActiveInvoiceSubmissionsSelector, createInactiveInvoiceSubmissionsSelector} from './invoiceSubmissionSelectors';
import InvoiceSubmission from './view-elements/InvoiceSubmission';
import {ButtonSecondary, Card, Circle, ContentBlock, Divider, documentIcon, Icon, mailIcon, phoneIcon, StickyBar, useStyles} from '../ui-components';
import AccordionRow from '../ui-components/general/AccordionRow';
import ContactDetailRow from '../application/view-elements/ContactDetailRow';
import {contactInfo} from '../application/constants/contact';
import Loader from '../ui-components/overlays/Loader';

const InvoiceSubmissionsScreen = props => {
    const {cx} = useStyles();
    const params = useParams();
    const history = useHistory();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('invoice_submissions_screen');
    const {isLoading, invoiceSubmissions} = props;
    const [activeTab, setActiveTab] = useState(params.activeTab === invoiceSubmissionTabs.ACTIVE
        ? invoiceSubmissionTabs.ACTIVE : invoiceSubmissionTabs.INACTIVE);

    useEffect(() => {
        if (activeTab !== params.activeTab) {
            history.replace(resolveRoute(routePaths.INVOICE_SUBMISSIONS, {
                activeTab,
            }));
        }
    }, [activeTab, params.activeTab, history]);

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-flex--small-direction-column',
                'global!ace-u-flex--medium-direction-row',
            ])}
        >
            <div
                className={cx([
                    'global!ace-u-margin--small-bottom-16',
                    'global!ace-u-margin--medium-right-64',
                    'global!ace-u-flex--medium-grow-0',
                    'ace-c-content-item--small-base-span-12',
                    'ace-c-content-item--medium-base-span-5',
                ])}
            >
                <h1
                    className={cx([
                        'global!ace-u-typography--variant-h1',
                        'global!ace-u-margin--small-bottom-24',
                        'global!ace-u-margin--small-bottom-48',
                    ])}
                >
                    {translateScreen('title.requests_overview')}
                </h1>
                <div
                    className={cx([
                        'global!ace-u-display--medium-none',
                        'global!ace-u-margin--bottom-24',
                    ])}
                >
                    <AccordionRow
                        title={translateScreen('accordion_row_title.contact_member_service')}
                        isDefaultOpenState={false}
                    >
                        <ContactDetailRow className={cx('global!ace-u-margin--bottom-16')}>
                            <Icon
                                className={cx([
                                    'ace-c-icon--24',
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--right-8',
                                ])}
                                icon={mailIcon}
                            />
                            <a
                                href={contactInfo.mail.link}
                                className={cx('global!ace-u-typography--variant-body-medium')}
                            >
                                {contactInfo.mail.text}
                            </a>
                        </ContactDetailRow>
                        <ContactDetailRow>
                            <Icon
                                className={cx([
                                    'ace-c-icon--24',
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--right-8',
                                ])}
                                icon={phoneIcon}
                            />
                            <a
                                href={contactInfo.phone.link}
                                className={cx('global!ace-u-typography--variant-body-medium')}
                            >
                                {contactInfo.phone.text}
                            </a>
                        </ContactDetailRow>
                    </AccordionRow>
                </div>
                <Card
                    className={cx([
                        'global!ace-u-display--small-none',
                        'global!ace-u-display--medium-flex',
                        'global!ace-u-padding--16',
                    ])}
                >
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        {translateScreen('title.contact_member_services')}
                    </h3>
                    <ContactDetailRow className={cx('global!ace-u-margin--bottom-16')}>
                        <Icon
                            className={cx([
                                'ace-c-icon--24',
                                'ace-c-icon--color-highlight',
                                'global!ace-u-margin--right-8',
                            ])}
                            icon={mailIcon}
                        />
                        <a
                            href={contactInfo.mail.link}
                            className={cx('global!ace-u-typography--variant-body-medium')}
                        >
                            {contactInfo.mail.text}
                        </a>
                    </ContactDetailRow>
                    <ContactDetailRow>
                        <Icon
                            className={cx([
                                'ace-c-icon--24',
                                'ace-c-icon--color-highlight',
                                'global!ace-u-margin--right-8',
                            ])}
                            icon={phoneIcon}
                        />
                        <a
                            href={contactInfo.phone.link}
                            className={cx('global!ace-u-typography--variant-body-medium')}
                        >
                            {contactInfo.phone.text}
                        </a>
                    </ContactDetailRow>
                </Card>
            </div>
            <div
                className={cx([
                    'ace-c-content-item--small-base-span-12',
                    'ace-c-content-item--medium-base-span-6',
                    'global!ace-u-flex--medium-grow-1',
                ])}
            >
                <Card>
                    <TabBar name="invoice-submission-tabs" value={activeTab} onChange={setActiveTab}>
                        <Tab name={invoiceSubmissionTabs.ACTIVE} value={invoiceSubmissionTabs.ACTIVE}>
                            {translateScreen('tab_label.open')}
                        </Tab>
                        <Tab name={invoiceSubmissionTabs.INACTIVE} value={invoiceSubmissionTabs.INACTIVE}>
                            {translateScreen('tab_label.closed')}
                        </Tab>
                    </TabBar>
                    <Divider className={cx('global!ace-u-margin--small-bottom-16')} />
                    <div
                        className={cx([
                            'global!ace-u-padding--small-bottom-128',
                            'global!ace-u-padding--medium-bottom-0',
                        ])}
                    >
                        {invoiceSubmissions.length
                            ? isLoading ? <Loader /> : invoiceSubmissions.map(invoice => {
                                const {invoiceSubmissionId, paidAmount} = invoice;
                                const {status, statusDescription, createdAt} = invoice;
                                return (
                                    <div
                                        className={cx('global!ace-u-padding--medium-0-32')}
                                        key={invoiceSubmissionId}
                                    >
                                        <InvoiceSubmission
                                            invoiceSubmissionId={invoiceSubmissionId}
                                            paidAmount={paidAmount}
                                            status={status}
                                            statusDescription={statusDescription}
                                            creationDate={createdAt}
                                            invoiceSubmission={invoice}
                                            invoicingRemarks={invoice.invoicingRemarks}
                                            paymentInfo={invoice.paymentInfo}
                                            files={invoice.attachmentsDetails?.attachments || []}
                                        />
                                        <Divider
                                            className={cx([
                                                'global!ace-u-margin--small-bottom-16',
                                                'global!ace-u-margin--small-top-16',
                                            ])}
                                        />
                                    </div>
                                );
                            }) : (isLoading ? <Loader />
                                : (
                                    <div
                                        className={cx([
                                            'global!ace-u-padding--medium-0-32',
                                            'global!ace-u-margin--small-bottom-32',
                                        ])}
                                    >
                                        <Card
                                            className={cx([
                                                'global!ace-u-padding--24',
                                                'global!ace-u-flex--align-items-center',
                                                'global!ace-u-margin--bottom-16',
                                            ])}
                                        >
                                            <Circle
                                                className={cx([
                                                    'ace-c-circle--small',
                                                    'global!ace-u-margin--bottom-16',
                                                    'global!ace-u-flex--align-self-center',
                                                ])}
                                            >
                                                <Icon
                                                    icon={documentIcon}
                                                    className={cx([
                                                        'ace-c-icon--24',
                                                        'ace-c-icon--color-highlight',
                                                    ])}
                                                />
                                            </Circle>
                                            <div className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-margin--small-bottom-24',
                                                'global!ace-u-padding--medium-0-32',
                                                'global!ace-u-padding--large-0-64',
                                                'global!ace-u-flex--direction-column',
                                                'global!ace-u-flex--align-items-center',
                                                'global!ace-u-display--large-flex',
                                            ])}
                                            >
                                                <p
                                                    className={cx([
                                                        'global!ace-u-typography--align-center',
                                                        'global!ace-u-typography--variant-body-bold',
                                                    ])}
                                                >
                                                    {translateScreen('text.no_requests_available')}
                                                </p>
                                                <p className={cx('global!ace-u-typography--align-center')}>
                                                    {translateScreen('text.you_can_do_one_on_the_mia_homepage')}
                                                </p>
                                                <p className={cx('global!ace-u-typography--align-center')}>
                                                    {translateScreen('text.create_new_application')}
                                                </p>
                                            </div>
                                        </Card>
                                    </div>
                                )
                            )
                        }
                    </div>
                    <StickyBar
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-padding--medium-0-32',
                            'global!ace-u-margin--small-bottom-16',
                            'global!ace-u-flex--medium-direction-row-reverse',
                            'ace-c-sticky-bar--bottom',
                            'ace-c-sticky-bar--margin-small-16',
                        ])}
                    >
                        <ButtonSecondary
                            className={cx([
                                'global!ace-u-width--small-full',
                                'global!ace-u-width--medium-auto',
                                'global!ace-u-flex--small-grow-1',
                                'global!ace-u-flex--medium-grow-0',
                            ])}
                            onClick={() => {
                                history.push(resolveRoute(routePaths.DASHBOARD));
                            }}
                            name="returnButton"
                            type="button"
                        >
                            {translateScreen('button_label.to_homepage')}
                        </ButtonSecondary>
                    </StickyBar>
                </Card>
            </div>
        </ContentBlock>
    );
};

InvoiceSubmissionsScreen.propTypes = {
    invoiceSubmissions: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
    const {history} = props;
    const isActiveTab = !history.location.pathname.includes('inactive');
    const getInvoiceSubmissions = isActiveTab
        ? createActiveInvoiceSubmissionsSelector() : createInactiveInvoiceSubmissionsSelector();
    return {
        invoiceSubmissions: getInvoiceSubmissions(state),
        isLoading: state.serviceCases.isLoading,
    };
};

export default connect(mapStateToProps)(InvoiceSubmissionsScreen);
