import {fork, put, select, take} from 'redux-saga/effects';
import {replace, push, resolveRoute} from '@computerrock/formation-router';
import {fetchRequest} from '@computerrock/formation-core';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import routePaths from '../../routePaths';
import {InvoiceSubmission} from '../../mia-entities/invoice-submission';

const createInvoiceSubmissionDraft = function* createInvoiceSubmissionDraft() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_DRAFT);
        const {invoiceSubmissionDraftData, selectStep, currentStep} = payload;

        yield fork(fetchRequest,
            invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_REQUEST,
            miaService.createInvoiceSubmission, {
                data: InvoiceSubmission.objectToDTO(invoiceSubmissionDraftData),
            });

        const actionResult = yield take([
            invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_REQUEST_FAILED,
        ]);

        if (actionResult.error) continue;

        const {response} = actionResult.payload;
        yield put({
            type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
            payload: {invoiceSubmissionDraft: response},
        });

        if (!selectStep || !currentStep) {
            yield put(push(resolveRoute(routePaths.INVOICE_SUBMISSION_CREATION, {
                serviceCaseId: response.serviceCaseId,
                invoiceSubmissionId: response.invoiceSubmissionId,
            })));
            continue;
        }

        yield put(replace(resolveRoute(routePaths.NEW_INVOICE_SUBMISSION_CREATION, {
            serviceCaseId: response.serviceCaseId,
            invoiceSubmissionId: response.invoiceSubmissionId,
        })));
        selectStep(currentStep + 1);
    }
};

export default createInvoiceSubmissionDraft;
