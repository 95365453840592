import React from 'react';
import {useStyles} from '../../ui-components';


const ContactDetailRow = props => {
    const {cx} = useStyles(props);
    return (
        <div className={cx(['ace-c-content-block',
            'global!ace-u-flex',
            'global!ace-u-flex--direction-row'])}
        >
            {props.children}
        </div>
    );
};

export default ContactDetailRow;
