import React from 'react';
import StepperProvider from './StepperProvider';

const withStepperProvider = WrappedComponent => {
    const ComposedComponent = React.forwardRef((props, ref) => {
        return (
            <StepperProvider>
                <WrappedComponent {...props} ref={ref} />
            </StepperProvider>
        );
    });
    ComposedComponent.displayName = `withStepperProvider(${WrappedComponent.displayName || WrappedComponent.name})`;

    ComposedComponent.propTypes = {
        ...WrappedComponent.propTypes,
    };

    ComposedComponent.defaultProps = {
        ...WrappedComponent.default,
    };

    return ComposedComponent;
};

export default withStepperProvider;
