import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {push, resolveRoute} from '@computerrock/formation-router';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';

const deleteInvoiceSubmissionDraftFlow = function* deleteInvoiceSubmissionDraftFlow() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        yield take(invoiceSubmissionActionTypes.INITIATE_DELETE_INVOICE_SUBMISSION_DRAFT_FLOW);

        yield* openModal(modalIds.DISCARD_DRAFT_MODAL);

        const chosenModalOption = yield take([
            invoiceSubmissionActionTypes.CONFIRM_DELETE_INVOICE_SUBMISSION_DRAFT,
            invoiceSubmissionActionTypes.DECLINE_DELETE_INVOICE_SUBMISSION_DRAFT,
        ]);

        if (chosenModalOption.type === invoiceSubmissionActionTypes.DECLINE_DELETE_INVOICE_SUBMISSION_DRAFT) {
            yield* closeModal(modalIds.DISCARD_DRAFT_MODAL);
            continue;
        }

        const {invoiceSubmissionDraft} = yield select(state => state.invoiceSubmissions);

        if (!invoiceSubmissionDraft) {
            yield* closeModal(modalIds.DISCARD_DRAFT_MODAL);
            continue;
        }

        // TODO: re-check when BE implements it
        yield fork(fetchRequest,
            invoiceSubmissionActionTypes.DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST,
            miaService.deleteInvoiceSubmission, {
                invoiceSubmissionId: invoiceSubmissionDraft?.invoiceSubmissionId,
            });

        const deleteInSDraftResponseAction = yield take([
            invoiceSubmissionActionTypes.DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_FAILED,
        ]);

        if (deleteInSDraftResponseAction.error) {
            // TBD: handle errors (try-again, close the modal, etc.)
            yield* closeModal(modalIds.DISCARD_DRAFT_MODAL);
            continue;
        }

        // remove InS draft from the state
        yield put({
            type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
            payload: {invoiceSubmissionDraft: null},
        });

        // close the modal
        yield* closeModal(modalIds.DISCARD_DRAFT_MODAL);
        // redirect to the service cases screen
        yield put(push(resolveRoute(routePaths.SERVICE_CASES)));
    }
};

export default deleteInvoiceSubmissionDraftFlow;
