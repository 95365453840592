import applicationReducer from './application/applicationReducer';
import authReducer from './auth/authReducer';
import serviceCaseReducer from './service-case/serviceCaseReducer';
import memberReducer from './member/memberReducer';
import errorsReducer from './application/errorsReducer';
import invoiceSubmissionFiles from './invoice-submission/invoiceSubmissionFiles';
import invoiceSubmissionReducer from './invoice-submission/invoiceSubmissionReducer';


// application reducers
const reducers = {
    application: applicationReducer,
    auth: authReducer,
    serviceCases: serviceCaseReducer,
    member: memberReducer,
    errors: errorsReducer,
    invoiceSubmissions: invoiceSubmissionReducer,
    ...invoiceSubmissionFiles.reducer,
};

export default reducers;
