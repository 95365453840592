import qs from 'qs';
import handleResponse from './handleResponse';
import resolveResourcePath from './resolveResourcePath';
import resourceEndpoints from './resourceEndpoints';
import ServerResponseError from './ServerResponseError';

/**
 * MIA MS microservice client
 *
 * @constructor
 */
export default class MSClientMIAGateway {
    constructor(serviceParameters = {}) {
        this.serviceParameters = {
            ...serviceParameters,
            // ensure SERVICE_URL has trailing slash
            SERVICE_URL: typeof serviceParameters.SERVICE_URL === 'string'
                ? serviceParameters.SERVICE_URL.replace(/\/?$/, '/') : '',
        };

        // add SERVICE_URL to each resource endpoint
        this.resourceEndpoints = Object.keys(resourceEndpoints)
            .reduce((parsedResourceEndpoints, endpointKey) => {
                parsedResourceEndpoints[endpointKey] = this.serviceParameters.SERVICE_URL
                    + resourceEndpoints[endpointKey];
                return parsedResourceEndpoints;
            }, {});

        return {
            setAccessToken: this.setAccessToken,
            loginUser: this.loginUser,
            getServiceCases: this.getServiceCases,
            getServiceCase: this.getServiceCase,
            getMemberInfo: this.getMemberInfo,
            createInvoiceSubmission: this.createInvoiceSubmission,
            updateInvoiceSubmission: this.updateInvoiceSubmission,
            deleteInvoiceSubmission: this.deleteInvoiceSubmission,
            getInvoiceSubmission: this.getInvoiceSubmission,
            getInvoiceSubmissions: this.getInvoiceSubmissions,
            updateInvoiceSubmissionStatus: this.updateInvoiceSubmissionStatus,
            getAidMaxPrice: this.getAidMaxPrice,
        };
    }

    accessToken = null;

    loginUser = params => {
        const {membershipNo, lastName, birthDate} = params;
        const query = {membershipNo, lastName, birthDate};

        const queryString = qs.stringify(query, {
            arrayFormat: 'comma',
        });
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
        + resolveResourcePath(resourceEndpoints['MEMBERS']);

        return fetch(
            `${resourceEndpoint}?${queryString}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                method: 'HEAD',
            },
        )
            .then(handleHeadResponse);
    };

    getServiceCases = queryParams => {
        const queryString = qs.stringify(queryParams, {
            arrayFormat: 'comma',
        });
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
        + resolveResourcePath(resourceEndpoints['SERVICE_CASES']);
        return fetch(`${resourceEndpoint}?${queryString}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'GET',
        })
            .then(handleResponse);
    };

    getServiceCase = ({serviceCaseId}) => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
        + resolveResourcePath(resourceEndpoints['SERVICE_CASES'], {
            serviceCaseId,
        });
        return fetch(resourceEndpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'GET',
        })
            .then(handleResponse);
    };

    getMemberInfo = ({membershipNo}) => {
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
        + resolveResourcePath(resourceEndpoints['MEMBERS'], {
            membershipNo,
        });
        return fetch(
            resourceEndpoint,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${this.accessToken}`,
                },
                method: 'GET',
            },
        )
            .then(handleResponse);
    };

    createInvoiceSubmission = params => {
        const {data} = params;
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourceEndpoints['INVOICE_SUBMISSIONS']);
        return fetch(resourceEndpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    };

    updateInvoiceSubmission = ({invoiceSubmissionDraftData, invoiceSubmissionId, isDraft = false}) => {
        if (!invoiceSubmissionId || typeof invoiceSubmissionDraftData !== 'object') return;
        const queryParams = new URLSearchParams();
        queryParams.append('isDraft', isDraft.toString());

        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourceEndpoints['INVOICE_SUBMISSIONS'], {
                invoiceSubmissionId,
            }) + `?${queryParams.toString()}`;

        return fetch(resourceEndpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'PATCH',
            body: JSON.stringify(invoiceSubmissionDraftData),
        })
            .then(handleResponse);
    };

    deleteInvoiceSubmission = ({invoiceSubmissionId}) => {
        if (!invoiceSubmissionId) return;

        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourceEndpoints['INVOICE_SUBMISSIONS'], {
                invoiceSubmissionId,
            });
        return fetch(resourceEndpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'DELETE',
        })
            .then(handleResponse);
    };

    getInvoiceSubmission = ({invoiceSubmissionId}) => {
        if (!invoiceSubmissionId) return;

        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourceEndpoints['INVOICE_SUBMISSIONS'], {
                invoiceSubmissionId,
            });

        return fetch(resourceEndpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'GET',
        })
            .then(handleResponse);
    };

    getInvoiceSubmissions = params => {
        const {membershipNumber, statuses} = params;
        const queryParams = new URLSearchParams();
        queryParams.append('membershipNumber', membershipNumber);
        queryParams.append('statuses', statuses);
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourceEndpoints['INVOICE_SUBMISSIONS'])
            + `?${queryParams.toString()}`;
        return fetch(resourceEndpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'GET',
        })
            .then(handleResponse);
    };

    updateInvoiceSubmissionStatus = ({statusData, invoiceSubmissionId}) => {
        if (!invoiceSubmissionId || typeof statusData !== 'object') return;

        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourceEndpoints['INVOICE_SUBMISSIONS'], {
                invoiceSubmissionId,
            });

        return fetch(resourceEndpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'PUT',
            body: JSON.stringify(statusData),
        })
            .then(handleResponse);
    };

    getAidMaxPrice = ({validOn, type, aidServiceType}) => {
        if (!validOn || !type || !aidServiceType) return;
        const queryString = qs.stringify({validOn, type, aidServiceType}, {
            arrayFormat: 'brackets',
        });
        return fetch(`${this.resourceEndpoints['PRICES']}?${queryString}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${this.accessToken}`,
            },
            method: 'GET',
        })
            .then(handleResponse);
    };

    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };
}

const handleHeadResponse = response => {
    if (response.ok) {
        return Promise.resolve('');
    }

    return Promise.reject(new Error('error')).then(err => ServerResponseError(err, response));
};

