export const aidServices = {
    BROKEN_GLASS: 'BROKEN_GLASS',
    MARTEN_BITE: 'MARTEN_BITE',
    ROADKILL: 'ROADKILL',
};

export const aidServicesTexts = {
    [aidServices.BROKEN_GLASS]: 'Glasbruch',
    [aidServices.MARTEN_BITE]: 'Marderbiss',
    [aidServices.ROADKILL]: 'Wildunfall',
};
