import {immerable} from 'immer';
import Service from './Service';
import {Damage} from '../damage';
import {Person} from '../person';

/**
 * AidService entity type definition
 *
 * @typedef {Object} AidService
 * @property {?string} tripPurpose
 * @property {?Person} driver
 * @property {?Person} owner
 * @property {?Damage} damage
 * @property {?string} endOfRecoveryDateTime
 * @property {?number} numberOfPassengers
 */

/**
 * AidService entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

const aidServiceEntityDTOMapping = {
    damage: {
        dtoProperty: 'damage',
        defaultValue: null,
        entity: Damage,
    },
    driver: {
        dtoProperty: 'driver',
        defaultValue: null,
        entity: Person,
    },
    owner: {
        dtoProperty: 'owner',
        defaultValue: null,
        entity: Person,
    },
    tripPurpose: {
        dtoProperty: 'tripPurpose',
        defaultValue: '',
    },
    endOfRecoveryDateTime: {
        dtoProperty: 'endOfRecoveryDateTime',
        defaultValue: '',
    },
    numberOfPassengers: {
        dtoProperty: 'numberOfPassengers',
        defaultValue: 0,
    },
};

/**
 * AidService entity
 *
 * @class
 * @extends Service
 * @type {AidService}
 */
export default class AidService extends Service {
    [immerable] = true;

    static entityDTOMapping = this.extendEntityDTOMapping(aidServiceEntityDTOMapping);

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
