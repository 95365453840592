import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';

/**
 * Address entity type definition
 *
 * @typedef {Object} Address
 * @property {?string} street
 * @property {?string} postCode
 * @property {?string} city
 * @property {?string} country
 * @property {?string} countryCode
 */

/**
 * Address entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const addressEntityDTOMapping = {
    street: {
        dtoProperty: 'street',
        defaultValue: '',
    },
    postCode: {
        dtoProperty: 'postCode',
        defaultValue: '',
    },
    city: {
        dtoProperty: 'city',
        defaultValue: '',
    },
    country: {
        dtoProperty: 'country',
        defaultValue: '',
    },
    countryCode: {
        dtoProperty: 'countryCode',
        defaultValue: '',
    },
    formattedAddress: 'formattedAddress',
};

/**
 * Address entity
 *
 * @class
 * @extends Entity
 * @type {Address}
 */
export default class Address extends Entity {
    [immerable] = true;

    static entityDTOMapping = addressEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
