/**
 * Command action types
 */
export const CREATE_INVOICE_SUBMISSION = '[invoice-submissions] CREATE_INVOICE_SUBMISSION';
export const CREATE_INVOICE_SUBMISSION_DRAFT = '[invoice-submissions] CREATE_INVOICE_SUBMISSION_DRAFT';
export const UPDATE_INVOICE_SUBMISSION_DRAFT = '[invoice-submissions] UPDATE_INVOICE_SUBMISSION_DRAFT';
export const INITIATE_ADD_INVOICE_SUBMISSION_SERVICE_FLOW = '[invoice-submissions] INITIATE_ADD_INVOICE_SUBMISSION_SERVICE_FLOW';
export const CONFIRM_ADD_INVOICE_SUBMISSION_SERVICE = '[invoice-submissions] CONFIRM_ADD_INVOICE_SUBMISSION_SERVICE';
export const DECLINE_ADD_INVOICE_SUBMISSION_SERVICE = '[invoice-submissions] DECLINE_ADD_INVOICE_SUBMISSION_SERVICE';
export const FETCH_AID_SERVICE_MAX_PRICE = '[invoice-submissions] FETCH_AID_SERVICE_MAX_PRICE';
export const SUBMIT_INVOICE_SUBMISSION = '[invoice-submissions] SUBMIT_INVOICE_SUBMISSION';
export const INITIATE_INVOICE_SUBMISSION_CANCEL_FLOW = '[invoice-submissions] INITIATE_INVOICE_SUBMISSION_CANCEL_FLOW';
export const CONFIRM_CANCEL_INVOICE_SUBMISSION = '[invoice-submissions] CONFIRM_CANCEL_INVOICE_SUBMISSION';
export const DECLINE_CANCEL_INVOICE_SUBMISSION = '[invoice-submissions] DECLINE_CANCEL_INVOICE_SUBMISSION';
export const INITIATE_DELETE_INVOICE_SUBMISSION_SERVICE_FLOW = '[invoice-submissions] INITIATE_DELETE_INVOICE_SUBMISSION_SERVICE_FLOW';
export const CONFIRM_DELETE_INVOICE_SUBMISSION_SERVICE = '[invoice-submissions] CONFIRM_DELETE_INVOICE_SUBMISSION_SERVICE';
export const DECLINE_DELETE_INVOICE_SUBMISSION_SERVICE = '[invoice-submissions] DECLINE_DELETE_INVOICE_SUBMISSION_SERVICE';
export const INITIATE_DELETE_INVOICE_SUBMISSION_DRAFT_FLOW = '[invoice-submissions] INITIATE_DELETE_INVOICE_SUBMISSION_DRAFT_FLOW';
export const CONFIRM_DELETE_INVOICE_SUBMISSION_DRAFT = '[invoice-submissions] CONFIRM_DELETE_INVOICE_SUBMISSION_DRAFT';
export const DECLINE_DELETE_INVOICE_SUBMISSION_DRAFT = '[invoice-submissions] DECLINE_DELETE_INVOICE_SUBMISSION_DRAFT';

/**
 * Event action types
 */
export const CREATE_INVOICE_SUBMISSION_REQUEST = '[invoice-submissions] CREATE_INVOICE_SUBMISSION_REQUEST';
export const CREATE_INVOICE_SUBMISSION_REQUEST_SENT = '[invoice-submissions] CREATE_INVOICE_SUBMISSION_REQUEST_SENT';
export const CREATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = '[invoice-submissions] CREATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const CREATE_INVOICE_SUBMISSION_REQUEST_FAILED = '[invoice-submissions] CREATE_INVOICE_SUBMISSION_REQUEST_FAILED';

export const GET_INVOICE_SUBMISSIONS_REQUEST = '[invoice-submissions] GET_INVOICE_SUBMISSIONS_REQUEST';
export const GET_INVOICE_SUBMISSIONS_REQUEST_SENT = '[invoice-submissions] GET_INVOICE_SUBMISSIONS_REQUEST_SENT';
export const GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED = '[invoice-submissions] GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED';
export const GET_INVOICE_SUBMISSIONS_REQUEST_FAILED = '[invoice-submissions] GET_INVOICE_SUBMISSIONS_REQUEST_FAILED';

export const GET_INVOICE_SUBMISSION_REQUEST = '[invoice-submissions] GET_INVOICE_SUBMISSION_REQUEST';
export const GET_INVOICE_SUBMISSION_REQUEST_SENT = '[invoice-submissions] GET_INVOICE_SUBMISSION_REQUEST_SENT';
export const GET_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = '[invoice-submissions] GET_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const GET_INVOICE_SUBMISSION_REQUEST_FAILED = '[invoice-submissions] GET_INVOICE_SUBMISSION_REQUEST_FAILED';

export const GET_INVOICE_SUBMISSION_DRAFT_REQUEST = '[invoice-submissions] GET_INVOICE_SUBMISSION_DRAFT_REQUEST';
export const GET_INVOICE_SUBMISSION_DRAFT_REQUEST_SENT = '[invoice-submissions] GET_INVOICE_SUBMISSION_DRAFT_REQUEST_SENT';
export const GET_INVOICE_SUBMISSION_DRAFT_REQUEST_SUCCEEDED = '[invoice-submissions] GET_INVOICE_SUBMISSION_DRAFT_REQUEST_SUCCEEDED';
export const GET_INVOICE_SUBMISSION_DRAFT_REQUEST_FAILED = '[invoice-submissions] GET_INVOICE_SUBMISSION_DRAFT_REQUEST_FAILED';

export const UPDATE_INVOICE_SUBMISSION_REQUEST = '[invoice-submissions] UPDATE_INVOICE_SUBMISSION_REQUEST';
export const UPDATE_INVOICE_SUBMISSION_REQUEST_SENT = '[invoice-submissions] UPDATE_INVOICE_SUBMISSION_REQUEST_SENT';
export const UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = '[invoice-submissions] UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED = '[invoice-submissions] UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED';

export const SUBMIT_INVOICE_SUBMISSION_REQUEST = '[invoice-submissions] SUBMIT_INVOICE_SUBMISSION_REQUEST';
export const SUBMIT_INVOICE_SUBMISSION_REQUEST_SENT = '[invoice-submissions] SUBMIT_INVOICE_SUBMISSION_REQUEST_SENT';
export const SUBMIT_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = '[invoice-submissions] SUBMIT_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const SUBMIT_INVOICE_SUBMISSION_REQUEST_FAILED = '[invoice-submissions] SUBMIT_INVOICE_SUBMISSION_REQUEST_FAILED';

export const FETCH_AID_SERVICE_MAX_PRICE_REQUEST = '[invoice-submissions] FETCH_AID_SERVICE_MAX_PRICE_REQUEST';
export const FETCH_AID_SERVICE_MAX_PRICE_REQUEST_SENT = '[invoice-submissions] FETCH_AID_SERVICE_MAX_PRICE_REQUEST_SENT';
export const FETCH_AID_SERVICE_MAX_PRICE_REQUEST_SUCCEEDED = '[invoice-submissions] FETCH_AID_SERVICE_MAX_PRICE_REQUEST_SUCCEEDED';
export const FETCH_AID_SERVICE_MAX_PRICE_REQUEST_FAILED = '[invoice-submissions] FETCH_AID_SERVICE_MAX_PRICE_REQUEST_FAILED';

export const DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST = '[invoice-submissions] DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST';
export const DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_SENT = '[invoice-submissions] DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_SENT';
export const DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_SUCCEEDED = '[invoice-submissions] DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_SUCCEEDED';
export const DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_FAILED = '[invoice-submissions] DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_ACTIVE_INVOICE_SUBMISSIONS = '[invoice-submissions] STORE_ACTIVE_INVOICE_SUBMISSIONS';
export const STORE_INACTIVE_INVOICE_SUBMISSIONS = '[invoice-submissions] STORE_INACTIVE_INVOICE_SUBMISSIONS';
export const STORE_CREATE_STATUS = '[invoice-submissions] STORE_CREATE_STATUS';
export const STORE_RESUBMISSION_COUNT = '[invoice-submissions] STORE_RESUBMISSION_COUNT';
export const STORE_STICKY_BAR_POSITION = '[invoice-submissions] STORE_STICKY_BAR_POSITION';
export const STORE_INVOICE_SUBMISSION_DRAFT = '[invoice-submissions] STORE_INVOICE_SUBMISSION_DRAFT';
export const STORE_AID_MAX_PRICE = '[invoice-submissions] STORE_AID_MAX_PRICE';
