import {take, fork, put, select} from 'redux-saga/effects';
import moment from 'moment';
import {push, resolveRoute} from '@computerrock/formation-router';
import {fetchRequest} from '@computerrock/formation-core';
import * as authActionTypes from '../authActionTypes';
import routePaths from '../../routePaths';

const loginFlow = function* loginFlow() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(authActionTypes.LOGIN_USER);
        let {membershipNo, lastName, birthDate} = payload;

        birthDate = birthDate
            ? moment(birthDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).format('DD-MM-YYYY')
            : '';

        yield put({
            type: authActionTypes.SET_AUTH_ERROR,
            payload: {error: null},
        });

        yield fork(fetchRequest, authActionTypes.LOGIN_USER_REQUEST,
            miaService.loginUser, {membershipNo, lastName, birthDate});

        const actionResult = yield take([
            authActionTypes.LOGIN_USER_REQUEST_SUCCEEDED,
            authActionTypes.LOGIN_USER_REQUEST_FAILED,
        ]);

        if (actionResult.error) {
            yield put({
                type: authActionTypes.SET_AUTH_ERROR,
                payload: {error: 'Die Zugangsdaten sind falsch oder unvollständig'},
            });
            continue;
        }
        const base64Token = btoa(unescape(encodeURIComponent([membershipNo, birthDate, lastName].join(':'))));
        window.sessionStorage.setItem('token', base64Token);
        window.sessionStorage.setItem('userData', JSON.stringify({membershipNo, birthDate, lastName}));
        yield put({
            type: authActionTypes.AUTH_SESSION_STARTED,
            payload: {token: base64Token},
        });
        yield put(push(resolveRoute(routePaths.DASHBOARD)));
    }
};

export default loginFlow;
