import {produce} from 'immer';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import {ServiceCase} from '../mia-entities/service-case';

const initialState = {
    serviceCases: {},
    serviceCasesTotalCount: 0,
    isLoading: true,
    serviceCasesCurrentPage: 0,
};

const serviceCaseReducer = produce((draft, action) => {
    const {type, payload} = action;
    switch (type) {
        case serviceCaseActionTypes.STORE_SERVICE_CASES: {
            const {serviceCasesDTO, serviceCasesTotalCount, serviceCasesCurrentPage} = payload;
            if (!serviceCasesDTO?.length) {
                break;
            }
            draft.serviceCasesTotalCount = serviceCasesTotalCount;
            draft.serviceCasesCurrentPage = serviceCasesCurrentPage;
            draft.serviceCases = {};
            serviceCasesDTO.forEach(serviceCaseDTO => {
                const draftServiceCase = draft.serviceCases[serviceCaseDTO.id];
                if (!draftServiceCase) {
                    draft.serviceCases[serviceCaseDTO.id] = new ServiceCase().fromDTO(serviceCaseDTO);
                    return;
                }
                draft.serviceCases[serviceCaseDTO.id] = draftServiceCase.fromDTO(serviceCaseDTO);
            });
            break;
        }
        case serviceCaseActionTypes.STORE_REQUEST_STATE: {
            const {isLoading} = payload;
            draft.isLoading = isLoading;
            break;
        }
    }
}, initialState);

export default serviceCaseReducer;
