import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {ButtonPrimary, ButtonSecondary, Circle, ContentBlock, ContentItem, Icon, infoIcon, Modal, useStyles} from '../../ui-components';

const DeleteInvoiceSubmissionServiceModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('delete_service_modal');
    const {hasBackdrop, confirmDeleteService, declineDeleteService} = props;

    return (
        <Modal
            contentClassName={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--justify-center',
            ])}
            hasBackdrop={hasBackdrop}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <Circle
                        className={cx([
                            'ace-c-circle--small',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        <Icon
                            icon={infoIcon}
                            className={cx([
                                'ace-c-icon--24',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    </Circle>
                    <h2
                        className={cx([
                            'global!ace-u-margin--bottom-16',
                            'global!ace-u-typography--variant-h2',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateModal('modal_title.delete_service')}
                    </h2>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-typography--align-center',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {translateModal('modal_body.delete_service')}
                    </p>
                    <ButtonPrimary
                        name="deleteService"
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--small-bottom-16',
                        ])}
                        onClick={confirmDeleteService}
                    >
                        {translateModal('button_label.yes')}
                    </ButtonPrimary>
                    <ButtonSecondary
                        className={cx([
                            'global!ace-u-full-width',
                        ])}
                        onClick={declineDeleteService}
                    >
                        {translateModal('button_label.no')}
                    </ButtonSecondary>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

DeleteInvoiceSubmissionServiceModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmDeleteService: PropTypes.func.isRequired,
    declineDeleteService: PropTypes.func.isRequired,
};

DeleteInvoiceSubmissionServiceModal.defaultProps = {
    hasBackdrop: false,
};

const mapDispatchToProps = dispatch => ({
    confirmDeleteService: () => dispatch({
        type: invoiceSubmissionActionTypes.CONFIRM_DELETE_INVOICE_SUBMISSION_SERVICE,
    }),
    declineDeleteService: () => dispatch({
        type: invoiceSubmissionActionTypes.DECLINE_DELETE_INVOICE_SUBMISSION_SERVICE,
    }),
});

export default connect(null, mapDispatchToProps)(DeleteInvoiceSubmissionServiceModal);
