import {produce} from 'immer';
import * as memberActionTypes from './memberActionTypes';
import {Member} from '../mia-entities/member';

const initialState = {
    memberInfo: {},
};

const memberReducer = produce((draft, action) => {
    const {type, payload} = action;
    switch (type) {
        case memberActionTypes.STORE_MEMBER_INFO: {
            const {memberInfoDTO} = payload;
            draft.memberInfo = new Member().fromDTO(memberInfoDTO);
            break;
        }
    }
}, initialState);

export default memberReducer;
