import {immerable} from 'immer';
import moment from 'moment';
import {Entity} from '@computerrock/formation-core';


/**
 * Vehicle entity type definition
 *
 * @typedef {Object} Vehicle
 * @property {?string} licensePlate
 * @property {?string} manufacturer
 * @property {?string} model
 * @property {?string} registrationDateTime
 * @property {?string} hasTrailer
 */

/**
 * Vehicle entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const vehicleEntityDTOMapping = {
    licensePlate: {
        dtoProperty: 'licensePlate',
        defaultValue: '',
    },
    manufacturer: {
        dtoProperty: 'manufacturer',
        defaultValue: '',
    },
    model: {
        dtoProperty: 'model',
        defaultValue: '',
    },
    registrationDateTime: {
        dtoProperty: 'registrationDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
    hasTrailer: 'hasTrailer',
};

/**
 * Vehicle entity
 *
 * @class
 * @extends Entity
 * @type {Vehicle}
 */
export default class Vehicle extends Entity {
    [immerable] = true;

    static entityDTOMapping = vehicleEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
