import React, {useContext, useState, Fragment, useMemo} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Form, Info, StepperContext, TextAreaField, useStyles, Checkbox} from '../../ui-components';
import FileUpload from '../../ui-components/file-upload/FileUpload';
import invoiceSubmissionFiles from '../invoiceSubmissionFiles';
import FormButtons from './FormButtons';
import {formStepNames} from '../constants/forms';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {isInvoiceValidationError} from '../utils/validation';
import {invoiceSubmissionServiceTypes} from '../constants/invoiceSubmissionServices';
import {Attachment} from '../../mia-entities/attachments';

const allowedMimeTypes = {
    IMAGE_JPG: 'image/jpeg',
    IMAGE_PNG: 'image/png',
    APPLICATION_PDF: 'application/pdf',
    IMAGE_HEIF: 'image/heif',
};

const InvoiceDocumentForm = props => {
    const {cx} = useStyles();
    const {files, uploadFile, deleteFile, isLoading, invoiceSubmissionDraft, updateInvoiceSubmissionDraft} = props;
    const {isOverlay, cancelInvoiceSubmissionDraft} = props;
    const [uploadError, setUploadError] = useState('');
    const {createTranslateShorthand} = useTranslate();
    const translateForm = createTranslateShorthand('invoice_document_form');
    const {selectStep, currentStep} = useContext(StepperContext);

    const uploadedDocumentTypes = useMemo(() => {
        let hasRoadKillService = false;
        let hasBrokenGlassOrMartenBiteServices = false;
        invoiceSubmissionDraft?.services?.forEach(service => {
            if (service?.type === invoiceSubmissionServiceTypes.BROKEN_GLASS) {
                hasBrokenGlassOrMartenBiteServices = true;
                return;
            }
            if (service?.type === invoiceSubmissionServiceTypes.MARTEN_BITE) {
                hasBrokenGlassOrMartenBiteServices = true;
                return;
            }
            if (service?.type === invoiceSubmissionServiceTypes.ROADKILL) hasRoadKillService = true;
        });
        return {
            invoiceUploaded: {
                value: !hasRoadKillService && !hasBrokenGlassOrMartenBiteServices,
                label: translateForm('checkbox_label.receipt', {
                    isRequired: '*',
                }),
            },
            repairInvoiceUploaded: {
                value: true,
                label: translateForm('checkbox_label.repair_receipt', {
                    isRequired: hasRoadKillService || hasBrokenGlassOrMartenBiteServices ? '*' : '',
                }),
            },
            carInsuranceUploaded: {
                value: hasRoadKillService || hasBrokenGlassOrMartenBiteServices,
                label: translateForm('checkbox_label.premium_car_insurance', {
                    isRequired: '*',
                }),
            },
            wildlifeDamageCertUploaded: {
                value: hasRoadKillService,
                label: translateForm('checkbox_label.wildlife_damage', {
                    isRequired: '*',
                }),
            },
        };
    }, [invoiceSubmissionDraft?.services, translateForm]);

    const uploadedFiles = useMemo(() => {
        const attachments = invoiceSubmissionDraft?.attachmentsDetails?.attachments || [];
        return {
            ...(!!files && files),
            ...(!!attachments && attachments.filter(attachment => !(files && files[attachment.id]))),
        };
    }, [files, invoiceSubmissionDraft?.attachmentsDetails?.attachments]);

    if (!invoiceSubmissionDraft) return null;

    const handleOnSubmit = formValues => {
        if (!formValues) return;
        const {
            invoiceUploaded,
            repairInvoiceUploaded,
            carInsuranceUploaded,
            wildlifeDamageCertUploaded,
            ...restFormValues
        } = formValues;
        const attachments = Object.values({
            ...(!!files && files),
            ...(!!invoiceSubmissionDraft?.attachmentsDetails?.attachments
                    && invoiceSubmissionDraft?.attachmentsDetails?.attachments
                        .filter(attachment => !(files && files[attachment.id]))),
        }).map(file => new Attachment().fromDTO(file));
        updateInvoiceSubmissionDraft({
            invoiceSubmissionDraftData: {
                attachmentsDetails: {
                    attachments,
                    invoiceUploaded: !!invoiceUploaded,
                    repairInvoiceUploaded: !!repairInvoiceUploaded,
                    carInsuranceUploaded: !!carInsuranceUploaded,
                    wildlifeDamageCertUploaded: !!wildlifeDamageCertUploaded,
                },
                ...restFormValues,
            },
            selectStep,
            currentStep,
        });
    };

    const handleOnDeleteFile = ({id: fileId}) => {
        if (!fileId) return;
        const attachments = invoiceSubmissionDraft?.attachmentsDetails?.attachments || [];
        const isFileOnInvoiceSubmissionDraft = attachments.find(attachment => attachment.id === fileId);
        if (isFileOnInvoiceSubmissionDraft) {
            updateInvoiceSubmissionDraft({
                invoiceSubmissionDraftData: {
                    attachmentsDetails: {
                        ...invoiceSubmissionDraft.attachmentsDetails,
                        attachments: attachments.filter(attachment => attachment.id !== fileId),
                    },
                },
            });
        }

        deleteFile({id: fileId});
    };

    const handleOnClose = formValues => {
        let invoiceSubmissionDraftData;
        if (formValues) {
            const {
                invoiceUploaded,
                repairInvoiceUploaded,
                carInsuranceUploaded,
                wildlifeDamageCertUploaded,
                ...restFormValues
            } = formValues;
            const attachments = Object.values({
                ...(!!files && files),
                ...(!!invoiceSubmissionDraft?.attachmentsDetails?.attachments
                    && invoiceSubmissionDraft?.attachmentsDetails?.attachments
                        .filter(attachment => !(files && files[attachment.id]))),
            }).map(file => new Attachment().fromDTO(file));
            invoiceSubmissionDraftData = {
                attachmentsDetails: {
                    attachments,
                    invoiceUploaded: !!invoiceUploaded,
                    repairInvoiceUploaded: !!repairInvoiceUploaded,
                    carInsuranceUploaded: !!carInsuranceUploaded,
                    wildlifeDamageCertUploaded: !!wildlifeDamageCertUploaded,
                },
                ...restFormValues,
            };
        }

        cancelInvoiceSubmissionDraft({invoiceSubmissionDraftData, currentStep});
    };

    return (
        <Form name="invoiceDocument" onSubmit={handleOnSubmit}>
            {formValues => (
                <Fragment>
                    <h2
                        className={cx([
                            'global!ace-u-typography--variant-h2',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        {translateForm('title.add_invoice')}
                    </h2>
                    <p
                        className={cx([
                            'global!ace-u-margin--small-bottom-16',
                            'global!ace-u-margin--medium-bottom-32',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {translateForm('invoice.description')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--small-bottom-24',
                            'global!ace-u-margin--medium-bottom-32',
                            'global!ace-u-typography--variant-caption',
                        ])}
                    >
                        {translateForm('invoice.format')}
                    </p>
                    {uploadError && (
                        <Info
                            className={cx([
                                'global!ace-u-margin--small-bottom-16',
                                'global!ace-u-margin--medium-bottom-32',
                            ])}
                            isWarning
                        >
                            {uploadError}
                        </Info>
                    )}
                    <FileUpload
                        className={cx([
                            'global!ace-u-margin--small-bottom-24',
                            'global!ace-u-margin--medium-bottom-32',
                        ])}
                        uploadFile={uploadFile}
                        deleteFile={handleOnDeleteFile}
                        files={uploadedFiles}
                        allowedMimeTypes={allowedMimeTypes}
                        setError={error => setUploadError(error)}
                        isLoading={isLoading}
                    />
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--small-bottom-16',
                        ])}
                    >
                        {translateForm('title.remarks')}
                    </h3>
                    <TextAreaField
                        placeholder={translateForm('text_area_placeholder.invoicing_remarks')}
                        name="invoicingRemarks"
                        value={invoiceSubmissionDraft.invoicingRemarks || ''}
                        maxLength={250}
                        isResizable={false}
                        className={cx([
                            'ace-c-text-area--small',
                            'global!ace-u-margin--small-bottom-32',
                            'global!ace-u-full-width',
                        ])}
                        label={translateForm('text_area_label.invoicing_remarks')}
                    />
                    <div className={cx('global!ace-u-margin--small-bottom-16')}>
                        {Object.entries(uploadedDocumentTypes)
                            .filter(([fieldName, valueObj]) => !!valueObj.value)
                            .map(([fieldName, valueObj]) => (
                                <div
                                    key={`${fieldName}Checkbox`}
                                    className={cx(['global!ace-u-margin--small-bottom-16'])}
                                >
                                    <Checkbox
                                        name={fieldName}
                                        value={true}
                                        isSelected={invoiceSubmissionDraft?.attachmentsDetails
                                            ? !!invoiceSubmissionDraft.attachmentsDetails[fieldName] : false}
                                    >
                                        {valueObj.label}
                                    </Checkbox>
                                </div>
                            ))}
                    </div>
                    {!isOverlay && (
                        <FormButtons
                            formName={formStepNames.INVOICE_FORM}
                            isSubmitDisabled={isInvoiceValidationError(
                                formValues || invoiceSubmissionDraft?.attachmentsDetails,
                                uploadedFiles,
                            )}
                            handleOnClose={() => handleOnClose(formValues)}
                        />
                    )}
                </Fragment>
            )}
        </Form>
    );
};

InvoiceDocumentForm.propTypes = {
    uploadFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    updateInvoiceSubmissionDraft: PropTypes.func.isRequired,
    files: PropTypes.object,
    isLoading: PropTypes.bool,
    invoiceSubmissionDraft: PropTypes.object,
    isOverlay: PropTypes.bool,
    cancelInvoiceSubmissionDraft: PropTypes.func.isRequired,
};

InvoiceDocumentForm.defaultProps = {
    files: null,
    isLoading: false,
    invoiceSubmissionDraft: null,
    isOverlay: false,
};

const mapStateToProps = state => {
    return {
        invoiceSubmissionDraft: state.invoiceSubmissions.invoiceSubmissionDraft,
        files: state.invoiceSubmissionFilesReducer.files,
        isLoading: state.invoiceSubmissionFilesReducer.isLoading,
    };
};

const mapDispatchToProps = dispatch => ({
    uploadFile: payload => dispatch(invoiceSubmissionFiles.uploadFile(payload)),
    deleteFile: payload => dispatch(invoiceSubmissionFiles.deleteFile(payload)),
    updateInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
    cancelInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_INVOICE_SUBMISSION_CANCEL_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDocumentForm);
