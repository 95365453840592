import {put, select} from 'redux-saga/effects';
import {push, END_SIDE_EFFECTS_RUNNING} from '@computerrock/formation-router';
import {userState} from './constants/userState';
import * as authActionTypes from './authActionTypes';
import * as authSelectors from './authSelectors';


export const accessControl = function* ({payload}) {
    let isUserSignedIn = yield select(authSelectors.getUserSignInStatus);

    if (!isUserSignedIn) {
        const token = window.sessionStorage.getItem('token');
        if (token) {
            isUserSignedIn = true;
            yield put({
                type: authActionTypes.AUTH_SESSION_STARTED,
                payload: {token},
            });
        }
    }
    const currentUserState = isUserSignedIn ? userState.USER_SIGNED_IN : userState.USER_SIGNED_OUT;

    const {allowedState, redirectTo} = payload;
    if (!redirectTo) return;

    yield put({
        type: authActionTypes.ACCESS_CONTROL_INITIATED,
        payload: {currentUserState, allowedState, redirectTo},
    });

    if (currentUserState !== allowedState) {
        yield put(push(redirectTo));
        return END_SIDE_EFFECTS_RUNNING;
    }
};
