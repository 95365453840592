import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {push, resolveRoute} from '@computerrock/formation-router';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import {InvoiceSubmission} from '../../mia-entities/invoice-submission';

const cancelInvoiceSubmissionFlow = function* cancelInvoiceSubmissionFlow() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.INITIATE_INVOICE_SUBMISSION_CANCEL_FLOW);
        const {invoiceSubmissionDraftData = {}, currentStep} = payload;
        const {invoiceSubmissionDraft} = yield select(state => state.invoiceSubmissions);

        yield* openModal(modalIds.CANCEL_INVOICE_SUBMISSION_MODAL);

        const chosenModalOption = yield take([
            invoiceSubmissionActionTypes.CONFIRM_CANCEL_INVOICE_SUBMISSION,
            invoiceSubmissionActionTypes.DECLINE_CANCEL_INVOICE_SUBMISSION,
        ]);

        if (chosenModalOption.type === invoiceSubmissionActionTypes.DECLINE_CANCEL_INVOICE_SUBMISSION) {
            yield* closeModal(modalIds.CANCEL_INVOICE_SUBMISSION_MODAL);
            continue;
        }

        if (invoiceSubmissionDraft) {
            yield fork(fetchRequest,
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST,
                miaService.updateInvoiceSubmission, {
                    invoiceSubmissionDraftData: InvoiceSubmission.objectToPatchDTO({
                        ...invoiceSubmissionDraftData,
                        // patch draftCreationStep field with the currentStep value
                        draftCreationStep: currentStep,
                    }),
                    invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
                    isDraft: true,
                });

            const actionResult = yield take([
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED,
            ]);

            if (actionResult.error) {
                // TBD: handle errors (try-again, close the modal, etc.)
                yield* closeModal(modalIds.CANCEL_INVOICE_SUBMISSION_MODAL);
                continue;
            }

            const {response} = actionResult.payload;
            yield put({
                type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
                payload: {invoiceSubmissionDraft: response},
            });
        }

        yield* closeModal(modalIds.CANCEL_INVOICE_SUBMISSION_MODAL);
        yield put(push(resolveRoute(routePaths.DASHBOARD)));
    }
};

export default cancelInvoiceSubmissionFlow;
