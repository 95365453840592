export const GET_SERVICE_CASES_REQUEST = '[service-cases] GET_SERVICE_CASES_REQUEST';
export const GET_SERVICE_CASE_REQUEST = '[service-cases] GET_SERVICE_CASE_REQUEST';

export const GET_SERVICE_CASES_REQUEST_SENT = '[service-cases] GET_SERVICE_CASES_REQUEST_SENT';
export const GET_SERVICE_CASES_REQUEST_SUCCEEDED = '[service-cases] GET_SERVICE_CASES_REQUEST_SUCCEEDED';
export const GET_SERVICE_CASES_REQUEST_FAILED = '[service-cases] GET_SERVICE_CASES_REQUEST_FAILED';

export const GET_SERVICE_CASE_REQUEST_SENT = '[service-cases] GET_SERVICE_CASE_REQUEST_SENT';
export const GET_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] GET_SERVICE_CASE_REQUEST_SUCCEEDED';
export const GET_SERVICE_CASE_REQUEST_FAILED = '[service-cases] GET_SERVICE_CASE_REQUEST_FAILED';

export const STORE_SERVICE_CASES = '[service-cases] STORE_SERVICE_CASES';

export const STORE_REQUEST_STATE = '[service-cases] STORE_REQUEST_STATE';

export const STORE_UPLOAD_STATE = '[service-cases] STORE_UPLOAD_STATE';

export const GET_SERVICE_CASES_ON_PAGE_CHANGE = '[service-cases] GET_SERVICE_CASES_ON_PAGE_CHANGE';
