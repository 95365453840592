import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, ButtonSecondary, StickyBar, useStyles} from '../../ui-components';

const FormButtons = props => {
    const {cx} = useStyles();
    const {isSubmitDisabled, formName, handleOnClose, isAdditionalService, handleOnServiceDeletion} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateForm = createTranslateShorthand('case_creation');

    return (
        <StickyBar
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--small-direction-column',
                'global!ace-u-flex--large-direction-row-reverse',
                'global!ace-u-flex--small-align-center',
                'global!ace-u-flex--medium-align-flex-start',
                'ace-c-sticky-bar--bottom',
                'ace-c-sticky-bar--margin-small-16',
            ])}
        >
            <ButtonPrimary
                name="submitButton"
                isDisabled={isSubmitDisabled}
                type="submit"
                className={cx([
                    'global!ace-u-margin--small-bottom-16',
                    'global!ace-u-margin--large-left-24',
                    'global!ace-u-width--small-full',
                    'global!ace-u-width--medium-auto',
                ])}
            >
                {translateForm(`button_label.${formName.toLowerCase()}`)}
            </ButtonPrimary>
            {!!isAdditionalService && (
                <ButtonSecondary
                    name="deleteAdditionalServiceButton"
                    type="button"
                    onClick={handleOnServiceDeletion}
                    className={cx([
                        'global!ace-u-width--small-full',
                        'global!ace-u-width--medium-auto',
                        'global!ace-u-margin--large-left-24',
                    ])}
                >
                    {translateForm('button_label.delete_service')}
                </ButtonSecondary>
            )}
            <ButtonSecondary
                name="cancelButton"
                type="button"
                onClick={handleOnClose}
                className={cx([
                    'global!ace-u-width--small-full',
                    'global!ace-u-width--medium-auto',
                ])}
            >
                {translateForm('button_label.save_and_close')}
            </ButtonSecondary>
        </StickyBar>
    );
};

FormButtons.propTypes = {
    isSubmitDisabled: PropTypes.bool,
    isAdditionalService: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    handleOnClose: PropTypes.func.isRequired,
    handleOnServiceDeletion: PropTypes.func,
};

FormButtons.defaultProps = {
    isSubmitDisabled: false,
    isAdditionalService: false,
    handleOnServiceDeletion: null,
};

export default FormButtons;
