import {immerable} from 'immer';
import Service from './Service';
import {Address} from '../address';

/**
 * FurtherJourneyService entity type definition
 *
 * @typedef {Object} FurtherJourneyService
 * @property {?Address} startingAddress
 * @property {?Address} destinationAddress
 */

/**
 * AccommodationService entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

const furtherJourneyServiceEntityDTOMapping = {
    startingAddress: {
        dtoProperty: 'startingAddress',
        entity: Address,
    },
    destinationAddress: {
        dtoProperty: 'destinationAddress',
        entity: Address,
    },
};

/**
 * FurtherJourneyService entity
 *
 * @class
 * @extends Service
 * @type {FurtherJourneyService}
 */
export default class FurtherJourneyService extends Service {
    [immerable] = true;

    static entityDTOMapping = this.extendEntityDTOMapping(furtherJourneyServiceEntityDTOMapping);

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
