/**
 * Modal ids
 */

export default {
    TEST_MODAL: 'test-modal',
    CANCEL_INVOICE_SUBMISSION_MODAL: 'cancel-invoice-submission-modal',
    ADD_INVOICE_SUBMISSION_SERVICE_MODAL: 'add-invoice-submission-service-modal',
    DELETE_INVOICE_SUBMISSION_SERVICE_MODAL: 'delete-invoice-submission-service-modal',
    EDIT_INVOICE_SUBMISSION_MODAL: 'edit-invoice-submission-modal',
    DISCARD_DRAFT_MODAL: 'discard-draft-modal',
};
