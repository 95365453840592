import React, {useContext, Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Stepper, StepperContext, useStyles, withStepperProvider} from '../ui-components';
import {ContentBlock, ContentItem} from '../ui-components/app-layout';
import Card from '../ui-components/app-layout/Card';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import CreateStatusScreen from './CreateStatusScreen';
import {createInvoiceSubmissionStatuses} from './constants/createInvoiceSubmissionStatuses';
import {formStepNames} from './constants/forms';
import {createServiceCaseSelector} from '../service-case/serviceCaseSelectors';
import config from '../config';
import InvoiceDocumentForm from './view-elements/InvoiceDocumentForm';
import BankInfoForm from './view-elements/BankInfoForm';
import SummaryScreen from './view-elements/SummaryScreen';
import MemberAndVehicleForm from './view-elements/MemberAndVehicleForm';
import ServiceCategoryDetailsForm from './view-elements/ServiceCategoryDetailsForm';
import {mafInvoiceSubmissionStatuses} from '../mia-entities/invoice-submission';

const invoiceCreationSteps = (isFromExistingCase, servicesLength) => {
    return [
        ...(!isFromExistingCase
            ? [{stepNumber: 1, title: 'MEMBER_AND_VEHICLE_DATA', step: formStepNames.MEMBER_AND_VEHICLE_DATA}]
            : []),
        ...([...Array(servicesLength).keys()].map(serviceNum => (
            {stepNumber: serviceNum + (isFromExistingCase ? 1 : 2),
                title: 'SERVICE_CATEGORY_DETAILS',
                step: formStepNames.SERVICE_CATEGORY_DETAILS}
        ))),
        {
            stepNumber: servicesLength + (isFromExistingCase ? 1 : 2),
            title: 'INVOICE_FORM',
            step: formStepNames.INVOICE_FORM,
        },
        {
            stepNumber: servicesLength + (isFromExistingCase ? 2 : 3),
            title: 'BANK_FORM',
            step: formStepNames.BANK_FORM,
        },
        {
            stepNumber: servicesLength + (isFromExistingCase ? 3 : 4),
            title: 'SUMMARY',
            step: formStepNames.SUMMARY,
        },
    ];
};

const InvoiceSubmissionCreationView = props => {
    const {cx} = useStyles(props);
    const params = useParams();
    const {translate} = useTranslate();
    const isFromExistingCase = !!params.serviceCaseId;
    const {memberInfo, submitInvoiceSubmission, isLoadingState, resubmitCount, invoiceSubmissionDraft} = props;
    const {createInvoiceSubmissionState, changeCreateStatus, serviceCase, resetResubmitCount} = props;
    const caseCreationSteps = invoiceCreationSteps(isFromExistingCase, invoiceSubmissionDraft?.services?.length || 1);
    const {currentStep, selectStep} = useContext(StepperContext);
    const [isStepPreselected, setIsStepPreselected] = useState(false);

    useEffect(() => {
        if (isStepPreselected || !invoiceSubmissionDraft?.draftCreationStep) return;
        selectStep(invoiceSubmissionDraft.draftCreationStep);
        setIsStepPreselected(true);
    }, [invoiceSubmissionDraft?.draftCreationStep, selectStep, isStepPreselected]);

    const reSubmitForm = () => {
        if (!invoiceSubmissionDraft) return;
        submitInvoiceSubmission({
            invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
            statusData: {status: mafInvoiceSubmissionStatuses.CREDITING_REQUESTED},
        });
    };

    return (
        !isLoadingState ? (
            <Fragment>
                {createInvoiceSubmissionState === createInvoiceSubmissionStatuses.LOADING
                    || createInvoiceSubmissionState === createInvoiceSubmissionStatuses.IDLE ? (
                        <ContentBlock
                            className={cx([
                                'global!ace-u-full-height',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-flex-start',
                                'global!ace-u-flex--justify-center',
                            ])}
                        >
                            <ContentItem
                                className={cx([
                                    'ace-c-content-item--base-span-12',
                                ])}
                            >
                                <ContentBlock
                                    className={cx([
                                        'global!ace-u-full-height',
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--small-align-center',
                                        'global!ace-u-flex--medium-align-flex-start',
                                        'global!ace-u-flex--justify-center',
                                        'global!ace-u-flex--small-direction-column',
                                        'global!ace-u-flex--medium-direction-row',
                                    ])}
                                >
                                    <Stepper
                                        className={(cx(['global!ace-u-full-width', 'global!ace-u-margin--small-bottom-24']))}
                                        steps={caseCreationSteps}
                                    />
                                    <Card
                                        className={cx(['global!ace-u-padding--medium-32', 'global!ace-u-flex--medium-grow-1', 'global!ace-u-full-width'])}
                                    >
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-body-medium',
                                                'global!ace-u-margin--small-bottom-16',
                                            ])}
                                        >
                                            {isFromExistingCase
                                                ? `${translate('global.case')} ${serviceCase.prefix}${serviceCase.caseId} ${moment(serviceCase.emergencyCallDateTime).format('DD.MM.YYYY')}`
                                                : translate('global.new_case')}
                                        </p>
                                        <div
                                            className={cx([
                                                'global!ace-u-padding--small-bottom-200',
                                                'global!ace-u-padding--medium-bottom-0',
                                            ])}
                                        >
                                            {caseCreationSteps[currentStep - 1]?.step === formStepNames.MEMBER_AND_VEHICLE_DATA && ( // eslint-disable-line max-len
                                            <MemberAndVehicleForm memberInfo={memberInfo} />
                                            )}
                                            {caseCreationSteps[currentStep - 1]?.step === formStepNames.SERVICE_CATEGORY_DETAILS && ( // eslint-disable-line max-len
                                            <ServiceCategoryDetailsForm />
                                            )}
                                            {caseCreationSteps[currentStep - 1]?.step === formStepNames.INVOICE_FORM && ( // eslint-disable-line max-len
                                            <InvoiceDocumentForm />
                                            )}
                                            {caseCreationSteps[currentStep - 1]?.step === formStepNames.BANK_FORM && (
                                            <BankInfoForm />
                                            )}
                                            {caseCreationSteps[currentStep - 1]?.step === formStepNames.SUMMARY && (
                                            <SummaryScreen />
                                            )}
                                        </div>
                                    </Card>
                                </ContentBlock>
                            </ContentItem>
                        </ContentBlock>
                    ) : (
                        <CreateStatusScreen
                            status={createInvoiceSubmissionState}
                            reSubmitForm={reSubmitForm}
                            changeCreateStatus={changeCreateStatus}
                            resetResubmitCount={resetResubmitCount}
                            resubmitCount={resubmitCount}
                        />
                    )}
            </Fragment>
        )
            : (
                <h1
                    className={cx([
                        'global!ace-u-typography--variant-h1',
                        'global!ace-u-typography--align-center',
                    ])}
                >
                    {translate('global.loading')}
                </h1>
            )
    );
};

InvoiceSubmissionCreationView.propTypes = {
    memberInfo: PropTypes.object,
    serviceCase: PropTypes.object,
    isLoadingState: PropTypes.bool.isRequired,
    submitInvoiceSubmission: PropTypes.func.isRequired,
    createInvoiceSubmissionState: PropTypes.string.isRequired,
    changeCreateStatus: PropTypes.func.isRequired,
    resetResubmitCount: PropTypes.func.isRequired,
    resubmitCount: PropTypes.number.isRequired,
    invoiceSubmissionDraft: PropTypes.object,
};

InvoiceSubmissionCreationView.defaultProps = {
    memberInfo: {},
    serviceCase: {},
    invoiceSubmissionDraft: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = createServiceCaseSelector();
    return {
        memberInfo: state.member.memberInfo,
        serviceCase: getServiceCase(state, props),
        isLoadingState: state.serviceCases.isLoading,
        createInvoiceSubmissionState: state.invoiceSubmissions.createInvoiceSubmissionState,
        resubmitCount: state.invoiceSubmissions.resubmitCount,
        invoiceSubmissionDraft: state.invoiceSubmissions.invoiceSubmissionDraft,
    };
};

const mapDispatchToProps = dispatch => ({
    submitInvoiceSubmission: payload => dispatch({
        type: invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION,
        payload,
    }),
    changeCreateStatus: payload => dispatch({
        type: invoiceSubmissionActionTypes.STORE_CREATE_STATUS,
        payload,
    }),
    resetResubmitCount: () => dispatch({
        type: invoiceSubmissionActionTypes.STORE_RESUBMISSION_COUNT,
        payload: config.initialResubmitCount,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStepperProvider(InvoiceSubmissionCreationView));
