import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';
import Service from '../services/Service';
import {Payment} from '../payment';
import {AttachmentsDetails} from '../attachments';
import {Member} from '../member';
import {Vehicle} from '../vehicle';
import getServiceTypeClass from '../services/getServiceTypeClass';

/**
 * InvoiceSubmission entity type definition
 *
 * @typedef {Object} InvoiceSubmission
 * @property {?string} invoiceSubmissionId
 * @property {?string} invoiceSubmissionNumber
 * @property {?string} serviceCaseId
 * @property {?number} requestedInvoicingAmount
 * @property {?number} paidAmount
 * @property {?string} createdAt
 * @property {?string} createdBy
 * @property {?string} updatedAt
 * @property {?string} updatedBy
 * @property {?string} status
 * @property {?string} statusDescription
 * @property {?string} invoicingRemarks
 * @property {?Payment} paymentInfo
 * @property {?AttachmentsDetails} attachmentsDetails
 * @property {?Member} member
 * @property {?Vehicle} vehicle
 * @property {?Array<?Service>} services
 * @property {?Number} draftCreationStep
 */

/**
 * InvoiceSubmission entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

const invoiceSubmissionEntityDTOMapping = {
    invoiceSubmissionId: {
        dtoProperty: 'invoiceSubmissionId',
        defaultValue: '',
    },
    invoiceSubmissionNumber: 'invoiceSubmissionNumber',
    serviceCaseId: 'serviceCaseId',
    paidAmount: {
        dtoProperty: 'paidAmount',
        defaultValue: '',
    },
    createdAt: {
        dtoProperty: 'createdAt',
        defaultValue: '',
    },
    createdBy: 'createdBy',
    updatedAt: 'updatedAt',
    updatedBy: 'updatedBy',
    status: {
        dtoProperty: 'status',
        defaultValue: '',
    },
    statusDescription: {
        dtoProperty: 'statusDescription',
        defaultValue: '',
    },
    invoicingRemarks: {
        dtoProperty: 'invoicingRemarks',
        defaultValue: '',
    },
    paymentInfo: {
        dtoProperty: 'paymentInfo',
        defaultValue: new Payment(),
        entity: Payment,
    },
    attachmentsDetails: {
        dtoProperty: 'attachmentsDetails',
        defaultValue: new AttachmentsDetails(),
        entity: AttachmentsDetails,
        useProperPatch: true,
    },
    member: {
        dtoProperty: 'member',
        defaultValue: new Member(),
        entity: Member,
    },
    vehicle: {
        dtoProperty: 'vehicle',
        defaultValue: new Vehicle(),
        entity: Vehicle,
    },
    services: {
        dtoProperty: 'services',
        entity: Service,
        isEntityArray: true,
        defaultValue: null,
        fromDTO: services => {
            if (Array.isArray(services)) {
                return services.map(service => {
                    const ServiceClass = getServiceTypeClass(service.type);
                    return new ServiceClass().fromDTO(service);
                });
            }
            return null;
        },
        toPatchDTO: services => {
            if (Array.isArray(services)) {
                return services.map(service => {
                    const ServiceClass = getServiceTypeClass(service.type);
                    const serviceWithNoNullValues = Object.keys(service)
                        .filter(key => !!service[key])
                        .reduce((accumulator, currentValue) => ({
                            ...accumulator,
                            [currentValue]: service[currentValue],
                        }), {});
                    return ServiceClass.objectToDTO(serviceWithNoNullValues);
                });
            }
            return null;
        },
    },
    draftCreationStep: 'draftCreationStep',
};

/**
 * InvoiceSubmission entity
 *
 * @class
 * @extends Entity
 * @type {InvoiceSubmission}
 */
export default class InvoiceSubmission extends Entity {
    [immerable] = true;

    static entityDTOMapping = invoiceSubmissionEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
