import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {renderRoutes} from '@computerrock/formation-router';
import '../ui-components/index.module.scss';
import {AppLayout, AppLayoutMain} from '../ui-components/app-layout';
import config from '../config';
import {useStyles} from '../ui-components';
import AppLayoutFooter from '../ui-components/app-layout/AppLayoutFooter';

const ApplicationScreen = ({route}) => {
    const {cx} = useStyles();
    return (
        <AppLayout>
            <AppLayoutMain>
                {renderRoutes(route.routes)}
            </AppLayoutMain>
            <AppLayoutFooter>
                <div
                    className={cx([
                        'global!ace-u-margin--top-8',
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-typography--color-disabled',
                        'global!ace-u-pointer-events--auto',
                    ])}
                >
                    Version: {config.VERSION} Build: {config.BUILD}
                </div>
            </AppLayoutFooter>
        </AppLayout>
    );
};

ApplicationScreen.propTypes = {
    route: PropTypes.object.isRequired,
    // someStateValue: PropTypes.object,
};

ApplicationScreen.defaultProps = {
    // no-op
    // someStateValue: null,
};

const mapStateToProps = state => ({
    // no-op
    // someStateValue: state.domain.someStateValue
});

const mapDispatchToProps = dispatch => ({
    // no-op
    // callAction: payload => dispatch({
    //     type: someActionTypes.CALL_ACTION,
    //     payload,
    // }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationScreen);
