import React, {Fragment} from 'react';

const NotFoundScreen = () => {
    return (
        <Fragment>
            Page not found.
        </Fragment>
    );
};

export default NotFoundScreen;
