import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import useStyles from '../useStyles';
import styles from './StickyBar.module.scss';
import * as invoiceSubmissionActionTypes from '../../invoice-submission/invoiceSubmissionActionTypes';

const StickyBar = props => {
    const {cx} = useStyles(props, styles);
    const {children, className, setStickyBarPosition} = props;

    const stickyBarRef = useRef(null);

    useEffect(() => {
        if (!stickyBarRef.current) return;
        setStickyBarPosition({
            top: stickyBarRef.current.getBoundingClientRect().top,
            bottom: stickyBarRef.current.getBoundingClientRect().bottom,
        });
    }, [setStickyBarPosition]);

    return (
        <div
            ref={stickyBarRef}
            className={cx(`ace-c-sticky-bar ${className}`)}
        >
            {children}
        </div>
    );
};

StickyBar.propTypes = {
    className: PropTypes.string,
    setStickyBarPosition: PropTypes.func.isRequired,
};

StickyBar.defaultProps = {
    className: '',
};

const mapDispatchToProps = dispatch => ({
    setStickyBarPosition: payload => dispatch({
        type: invoiceSubmissionActionTypes.STORE_STICKY_BAR_POSITION,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(StickyBar);
