import {immerable} from 'immer';
import Service from './Service';
import {Address} from '../address';

/**
 * RentalCarService entity type definition
 *
 * @typedef {Object} RentalCarService
 * @property {?Address} startingAddress
 * @property {?Address} destinationAddress
 * @property {?boolean} isDelivered
 */

/**
 * RentalCarService entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

const rentalCarAssignmentEntityDTOMapping = {
    startingAddress: {
        dtoProperty: 'startingAddress',
        entity: Address,
    },
    destinationAddress: {
        dtoProperty: 'destinationAddress',
        entity: Address,
    },
    isDelivered: {
        dtoProperty: 'isDelivered',
        defaultValue: false,
    },
};

/**
 * RentalCarService entity
 *
 * @class
 * @extends Service
 * @type {RentalCarService}
 */
export default class RentalCarService extends Service {
    [immerable] = true;

    static entityDTOMapping = this.extendEntityDTOMapping(rentalCarAssignmentEntityDTOMapping);

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
