import {mafCases} from '../../mia-entities';
import {homeIcon, passengerCarIcon, personIcon} from '../../ui-components';

export const mafCasesTexts = {
    [mafCases.VEHICLE]: 'Fahrzeugbezogener Fall',
    [mafCases.PERSON]: 'Personenbezogener Fall',
    [mafCases.HOUSEHOLD]: 'Haushaltbezogener Fall',
};

export const mafCasesIcons = {
    [mafCases.VEHICLE]: passengerCarIcon,
    [mafCases.PERSON]: personIcon,
    [mafCases.HOUSEHOLD]: homeIcon,
};
