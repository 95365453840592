import {put, take, fork} from 'redux-saga/effects';
import fetchRequest from './fetchRequest';
import * as serviceCaseActionTypes from '../../service-case/serviceCaseActionTypes';

const uploadFileFlow = function* uploadFileFlow({payload}) {
    const {actionTypes, getFileParameters, fileParamsUrl, uploadFile, errorHandlingActionType} = this;
    // const {payload} = yield take(actionTypes.START_UPLOAD_FLOW);
    const {fileName, file, fileUrl, contentType, recognitionId} = payload;

    yield fork(fetchRequest, actionTypes.GET_UPLOAD_PARAMETERS_REQUEST,
        getFileParameters, {fileName, apiUrl: fileParamsUrl}, {recognitionId});
    while (true) {
        const getUploadParamsResult = yield take([
            actionTypes.GET_UPLOAD_PARAMETERS_REQUEST_SUCCEEDED,
            actionTypes.GET_UPLOAD_PARAMETERS_REQUEST_FAILED,
        ]);
        if (getUploadParamsResult.meta.recognitionId === recognitionId) {
            if (getUploadParamsResult.error) {
                yield put({
                    type: errorHandlingActionType,
                    payload: {
                        error: getUploadParamsResult.payload.message,
                    },
                });
                break;
            }
            const {id, url} = getUploadParamsResult.payload.response;
            yield fork(fetchRequest, actionTypes.UPLOAD_FILE_REQUEST,
                uploadFile, {id, url, file, contentType}, {recognitionId});

            yield put({
                type: serviceCaseActionTypes.STORE_UPLOAD_STATE,
                payload: {isLoading: true},
            });

            yield put({
                type: actionTypes.STORE_UPLOAD_QUEUE,
                payload: {fileId: id},
            });

            while (true) {
                const uploadFileResult = yield take([
                    actionTypes.UPLOAD_FILE_REQUEST_SUCCEEDED,
                    actionTypes.UPLOAD_FILE_REQUEST_FAILED,
                ]);
                if (uploadFileResult.meta.recognitionId === recognitionId) {
                    if (uploadFileResult.error) {
                        yield put({
                            type: errorHandlingActionType,
                            payload: {
                                error: uploadFileResult.payload.message,
                            },
                        });
                        continue;
                    }
                    yield put({
                        type: actionTypes.STORE_FILE,
                        payload: {
                            file: {
                                id,
                                fileName,
                                url: fileUrl,
                                contentType,
                                isNewlyAdded: true,
                                isSignature: false,
                            },
                        },
                    });

                    yield put({
                        type: serviceCaseActionTypes.STORE_UPLOAD_STATE,
                        payload: {isLoading: false},
                    });
                }
            }
        }
    }
};

export default uploadFileFlow;
