export const invoiceSubmissionServiceTypes = {
    TOWING: 'TOWING',
    RECOVERY: 'RECOVERY',
    ROADSIDE_ASSISTANCE: 'ROADSIDE_ASSISTANCE',
    VEHICLE_OPENING: 'VEHICLE_OPENING',
    NO_SERVICE: 'NO_SERVICE',
    ACCOMMODATION: 'ACCOMMODATION',
    FURTHER_JOURNEY: 'FURTHER_JOURNEY',
    SHORT_TRIP: 'SHORT_TRIP',
    RENTAL_CAR: 'RENTAL_CAR',
    BROKEN_GLASS: 'BROKEN_GLASS',
    MARTEN_BITE: 'MARTEN_BITE',
    ROADKILL: 'ROADKILL',
    OTHER: 'OTHER',
};

export const invoiceSubmissionsServiceTypesTexts = {
    [invoiceSubmissionServiceTypes.TOWING]: 'Abschleppen',
    [invoiceSubmissionServiceTypes.RECOVERY]: 'Bergung',
    [invoiceSubmissionServiceTypes.ROADSIDE_ASSISTANCE]: 'Pannenhilfe',
    [invoiceSubmissionServiceTypes.VEHICLE_OPENING]: 'Fahrzeugöffnung',
    [invoiceSubmissionServiceTypes.NO_SERVICE]: 'Keine Leistung',
    [invoiceSubmissionServiceTypes.ACCOMMODATION]: 'Übernachtung',
    [invoiceSubmissionServiceTypes.FURTHER_JOURNEY]: 'Rück-/Weiterreise',
    [invoiceSubmissionServiceTypes.SHORT_TRIP]: 'Kurzfahrt',
    [invoiceSubmissionServiceTypes.RENTAL_CAR]: 'Mietwagen',
    [invoiceSubmissionServiceTypes.BROKEN_GLASS]: 'Glasbruch',
    [invoiceSubmissionServiceTypes.MARTEN_BITE]: 'Marderbiss',
    [invoiceSubmissionServiceTypes.ROADKILL]: 'Wildunfall',
    [invoiceSubmissionServiceTypes.OTHER]: 'Sonstiges',
};
