import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../useStyles';
import SVGSpriteSymbol from './SVGSpriteSymbol';
import styles from './Icon.module.scss';

const Icon = props => {
    const {cx} = useStyles(props, styles);
    const {icon, onClick, isDisabled} = props;

    const handleOnClick = event => {
        if (typeof onClick === 'function' && !isDisabled) {
            onClick(event);
        }
    };

    return (
        <i
            className={cx('ace-c-icon', {
                'ace-c-icon--has-on-click': typeof onClick === 'function',
                'ace-c-icon--color-disabled': isDisabled,
            })}
            onClick={handleOnClick}
        >
            <SVGSpriteSymbol
                className={cx('ace-c-icon__symbol')}
                spriteSymbol={icon}
            />
        </i>
    );
};

Icon.propTypes = {
    icon: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};

Icon.defaultProps = {
    onClick: null,
    isDisabled: false,
};

export default Icon;
