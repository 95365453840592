import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';

/**
 * Price entity type definition
 *
 * @typedef {Object} Price
 * @property {?number} id
 * @property {?number} value
 * @property {?string} type
 * @property {?string} aidServiceType
 */

/**
 * Price entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

const priceEntityDTOMapping = {
    id: 'id',
    value: 'value',
    type: 'type',
    aidServiceType: 'aidServiceType',
};

/**
 * Price entity
 *
 * @class
 * @extends Entity
 * @type {Price}
 */
export default class Price extends Entity {
    [immerable] = true;

    static entityDTOMapping = priceEntityDTOMapping;

    static extendEntityDTOMapping = entityDTOMapping => {
        return {
            ...priceEntityDTOMapping,
            ...entityDTOMapping,
        };
    };

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
