import {immerable} from 'immer';
import moment from 'moment';
import {Entity} from '@computerrock/formation-core';
import mafServices from './mafServices';
import {Member} from '../member';
import {Vehicle} from '../vehicle';

/**
 * ServiceCase entity type definition
 *
 * @typedef {Object} ServiceCase
 * @property {?string} caseId
 * @property {?string} prefix
 * @property {?string} emergencyCallDateTime
 * @property {?string} service
 * @property {?string} case
 * @property {?Member} member
 * @property {?Vehicle} vehicle
 * @property {?string} updatedAt
 */

/**
 * ServiceCase entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const serviceCaseEntityDTOMapping = {
    caseId: 'id',
    emergencyCallDateTime: {
        dtoProperty: 'emergencyCallDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
    prefix: {
        dtoProperty: 'prefix',
        defaultValue: '',
    },
    service: {
        dtoProperty: 'serviceType',
        defaultValue: mafServices.ROADSIDE_ASSISTANCE,
    },
    case: {
        dtoProperty: 'caseType',
        defaultValue: mafServices.ROADSIDE_ASSISTANCE,
    },
    member: {
        dtoProperty: 'member',
        defaultValue: new Member(),
        entity: Member,
    },
    vehicle: {
        dtoProperty: 'vehicle',
        defaultValue: new Vehicle(),
        entity: Vehicle,
    },
    updatedAt: {
        dtoProperty: 'updatedAt',
        fromDTO: date => (date ? moment(date) : null),
    },
};

/**
 * ServiceCase entity
 *
 * @class
 * @extends Entity
 * @type {ServiceCase}
 */
export default class ServiceCase extends Entity {
    [immerable] = true;

    static entityDTOMapping = serviceCaseEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }

    get serviceCaseId() {
        return `${this.prefix}${this.caseId}`;
    }
}
