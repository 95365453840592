import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import modalIds from '../../modalIds';
import {InvoiceSubmission} from '../../mia-entities/invoice-submission';

const deleteInvoiceSubmissionServiceFlow = function* deleteInvoiceSubmissionServiceFlow() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.INITIATE_DELETE_INVOICE_SUBMISSION_SERVICE_FLOW);
        const {serviceIndex, selectStep, step} = payload;
        const {invoiceSubmissionDraft} = yield select(state => state.invoiceSubmissions);
        const isInServiceArray = !!invoiceSubmissionDraft.services[serviceIndex];

        yield* openModal(modalIds.DELETE_INVOICE_SUBMISSION_SERVICE_MODAL);

        const chosenModalOption = yield take([
            invoiceSubmissionActionTypes.CONFIRM_DELETE_INVOICE_SUBMISSION_SERVICE,
            invoiceSubmissionActionTypes.DECLINE_DELETE_INVOICE_SUBMISSION_SERVICE,
        ]);

        if (isInServiceArray
            && chosenModalOption.type === invoiceSubmissionActionTypes.CONFIRM_DELETE_INVOICE_SUBMISSION_SERVICE) {
            const services = invoiceSubmissionDraft.services?.map(service => service);
            services.splice(serviceIndex, 1);
            yield fork(fetchRequest,
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST,
                miaService.updateInvoiceSubmission, {
                    invoiceSubmissionDraftData: InvoiceSubmission.objectToPatchDTO({
                        services,
                    }),
                    invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
                });

            const actionResult = yield take([
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED,
            ]);

            if (actionResult.error) continue;

            const {response} = actionResult.payload;
            yield put({
                type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
                payload: {invoiceSubmissionDraft: response},
            });

            // when the user confirms, go back to the 'previous' step
            yield* closeModal(modalIds.DELETE_INVOICE_SUBMISSION_SERVICE_MODAL);
            selectStep(step - 1);
            continue;
        }

        // when the user declines, do NOT go back to the previous step
        yield* closeModal(modalIds.DELETE_INVOICE_SUBMISSION_SERVICE_MODAL);
        selectStep(step);
    }
};

export default deleteInvoiceSubmissionServiceFlow;
