import {fork, put, select, take} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import {fetchRequest} from '@computerrock/formation-core';
import * as memberActionTypes from './memberActionTypes';
import modalIds from '../modalIds';
import routePaths from '../routePaths';

export const getMemberInfo = function* getMemberInfo() {
    const memberData = JSON.parse(window.sessionStorage.getItem('userData'));
    const {membershipNo} = memberData;

    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    // do not load member data if the user is on the InS creation screen
    // and the CancelInvoiceSubmissionModal is open/closed
    const {location} = yield select(state => state.router);
    const invoiceSubmissionCreationScreenMatch = matchPath(location.pathname, {
        path: routePaths.INVOICE_SUBMISSION_CREATION,
        exact: true,
    });
    if (invoiceSubmissionCreationScreenMatch
        && (location.state?.modalId === modalIds.CANCEL_INVOICE_SUBMISSION_MODAL
        || location.state?.modalId === modalIds.ADD_INVOICE_SUBMISSION_SERVICE_MODAL)
    ) {
        return;
    }

    yield fork(fetchRequest, memberActionTypes.GET_MEMBER_INFO_REQUEST,
        miaService.getMemberInfo, {membershipNo});


    const actionResult = yield take([
        memberActionTypes.GET_MEMBER_INFO_REQUEST_SUCCEEDED,
        memberActionTypes.GET_MEMBER_INFO_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        const memberInfoDTO = actionResult.payload.response;
        yield put({
            type: memberActionTypes.STORE_MEMBER_INFO,
            payload: {memberInfoDTO},
        });
    }
};
