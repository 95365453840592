export const contactInfo = {
    mail: {
        link: 'mailto:info@ace.de',
        text: 'info@ace.de',
    },
    phone: {
        link: 'tel:+49711530336677',
        text: '+49 711 530 33 66 77',
    },
};
