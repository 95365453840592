import {all, fork} from 'redux-saga/effects';
import updateInvoiceSubmissionDraft from './sagas/updateInvoiceSubmissionDraft';
import createInvoiceSubmissionDraft from './sagas/createInvoiceSubmissionDraft';
import submitInvoiceSubmission from './sagas/submitInvoiceSubmission';
import addInvoiceSubmissionServiceFlow from './sagas/addInvoiceSubmissionServiceFlow';
import fetchAidServiceMaxPrices from './sagas/fetchAidServiceMaxPrices';
import cancelInvoiceSubmissionFlow from './sagas/cancelInvoiceSubmissionFlow';
import deleteInvoiceSubmissionServiceFlow from './sagas/deleteInvoiceSubmissionServiceFlow';
import deleteInvoiceSubmissionDraftFlow from './sagas/deleteInvoiceSubmissionDraftFlow';


const invoiceSubmissionWatcher = function* invoiceSubmissionWatcher() {
    yield all([
        fork(updateInvoiceSubmissionDraft),
        fork(createInvoiceSubmissionDraft),
        fork(submitInvoiceSubmission),
        fork(addInvoiceSubmissionServiceFlow),
        fork(fetchAidServiceMaxPrices),
        fork(cancelInvoiceSubmissionFlow),
        fork(deleteInvoiceSubmissionServiceFlow),
        fork(deleteInvoiceSubmissionDraftFlow),
    ]);
};

export default invoiceSubmissionWatcher;
