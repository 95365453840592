export const personTypes = {
    MEMBER: 'MEMBER',
    PARENT: 'PARENT',
    CHILD: 'CHILD',
    PARTNER: 'PARTNER',
    SIBLING: 'SIBLING',
    OTHER: 'OTHER',
};

export const personTypesTexts = {
    [personTypes.MEMBER]: 'Mitglied',
    [personTypes.PARENT]: 'Elternteil',
    [personTypes.PARTNER]: 'Ehe-/Lebenspartner',
    [personTypes.SIBLING]: 'Geschwister',
    [personTypes.CHILD]: 'Kind',
    [personTypes.OTHER]: 'Sonstige',
};
