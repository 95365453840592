import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';

/**
 * Payment entity type definition
 *
 * @typedef {Object} Payment
 * @property {?string} bankAccountOwner
 * @property {?string} iban
 * @property {?string} swift
 */

/**
 * Payment entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const paymentEntityDTOMapping = {
    bankAccountOwner: {
        dtoProperty: 'bankAccountOwner',
        defaultValue: '',
    },
    iban: {
        dtoProperty: 'iban',
        defaultValue: '',
    },
    swift: {
        dtoProperty: 'swift',
        defaultValue: '',
    },
};

/**
 * Payment entity
 *
 * @class
 * @extends Entity
 * @type {Payment}
 */
export default class Payment extends Entity {
    [immerable] = true;

    static entityDTOMapping = paymentEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
