import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';
import Address from '../address/Address';


/**
 * Person entity type definition
 *
 * @typedef {Object} Person
 * @property {?Address} address
 * @property {?string} type
 * @property {?string} name
 * @property {?string} surname
 * @property {?string} phoneNumber
 */

/**
 * Person entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const personEntityDTOMapping = {
    type: {
        dtoProperty: 'type',
        defaultValue: '',
    },
    name: {
        dtoProperty: 'name',
        defaultValue: '',
    },
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
    },
    surname: {
        dtoProperty: 'surname',
        defaultValue: '',
    },
    phoneNumber: 'phoneNumber',
};

/**
 * Person entity
 *
 * @class
 * @extends Entity
 * @type {Person}
 */
export default class Person extends Entity {
    [immerable] = true;

    static entityDTOMapping = personEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
