import React, {useState} from 'react';
import StepperContext from './StepperContext';

const StepperProvider = props => {
    const {children} = props;
    const [currentStep, setCurrentStep] = useState(1);

    const selectStep = step => {
        if (step >= 1) {
            setCurrentStep(step);
        }
    };

    return (
        <StepperContext.Provider
            value={{
                currentStep,
                selectStep,
            }}
        >
            {children}
        </StepperContext.Provider>
    );
};

export default StepperProvider;
