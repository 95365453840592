import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const LoadScript = ({url, children}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const existingScript = document.getElementById('googleMaps');
        if (existingScript) {
            handleLoaded();
            return;
        }
        const script = document.createElement('script');
        script.src = url;
        script.id = 'googleMaps';
        script.addEventListener('load', handleLoaded);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);

    const handleLoaded = () => {
        setIsLoaded(true);
    };
    return (
        <>
            {isLoaded && children}
        </>
    );
};

LoadScript.propTypes = {
    url: PropTypes.string.isRequired,
};

export default LoadScript;
