import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';
import Address from '../address/Address';

/**
 * ThirdParty entity type definition
 *
 * @typedef {Object} ThirdParty
 * @property {?Address} address
 * @property {?boolean} isCoveringCosts
 * @property {?boolean} isCausedAccident
 * @property {?string} name
 * @property {?string} licensePlate
 * @property {?string} insuranceName
 * @property {?string} insuranceNumber
 * @property {?string} damageNumber
 * @property {?string} costsCoveredBy
 * @property {?string} costsPurpose
 * @property {?number} costsAmount
 *
 */

/**
 * ThirdParty entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const thirdPartyEntityDTOMapping = {
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
    },
    isCoveringCosts: {
        dtoProperty: 'isCoveringCosts',
        defaultValue: false,
    },
    isCausedAccident: {
        dtoProperty: 'hasCausedAccident',
        defaultValue: false,
    },
    name: {
        dtoProperty: 'name',
        defaultValue: '',
    },
    licensePlate: {
        dtoProperty: 'licensePlate',
        defaultValue: '',
    },
    insuranceName: {
        dtoProperty: 'insuranceName',
        defaultValue: '',
    },
    insuranceNumber: {
        dtoProperty: 'insuranceNumber',
        defaultValue: '',
    },
    damageNumber: {
        dtoProperty: 'damageNumber',
        defaultValue: '',
    },
    costsCoveredBy: {
        dtoProperty: 'costsCoveredBy',
        defaultValue: '',
    },
    costsPurpose: {
        dtoProperty: 'costsPurpose',
        defaultValue: '',
    },
    costsAmount: {
        dtoProperty: 'costsAmount',
        defaultValue: 0,
    },
};

/**
 * ThirdParty entity
 *
 * @class
 * @extends Entity
 * @type {ThirdParty}
 */
export default class ThirdParty extends Entity {
    [immerable] = true;

    static entityDTOMapping = thirdPartyEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
