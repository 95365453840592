import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {ContentBlock, ContentItem} from '../ui-components/app-layout';
import {DateField, Icon, InputField, loginIcon, useStyles, calendarIcon, ButtonPrimary} from '../ui-components';
import Card from '../ui-components/app-layout/Card';
import {Circle, ErrorMessage} from '../ui-components/general';
import Form from '../ui-components/form-elements/Form';
import * as authActionTypes from './authActionTypes';

const LoginScreen = ({loginUser, error, history}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('login_screen');

    const searchQuery = typeof history.location.search === 'string' ? history.location.search : '?';
    const queryParams = new URLSearchParams(searchQuery);
    const searchQueryParams = {};

    queryParams.forEach((value, key) => {
        if (typeof searchQueryParams[key] !== 'undefined') {
            searchQueryParams[key] = [
                ...(Array.isArray(searchQueryParams[key])
                    ? searchQueryParams[key] : [searchQueryParams[key]]),
                value,
            ];
        }

        searchQueryParams[key] = value.trim();
    });

    window.history.replaceState(null, '', '/login');

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-full-height',
                'global!ace-u-flex',
                'global!ace-u-flex--align-center',
                'global!ace-u-flex--justify-center',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--base-span-12',
                    'ace-c-content-item--small-span-8',
                    'ace-c-content-item--medium-span-6',
                    'ace-c-content-item--large-span-4',

                ])}
            >
                <Card
                    className={cx([
                        'global!ace-u-padding--small-16',
                        'global!ace-u-padding--large-32',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-center',
                        'ace-c-card--variant-white',
                    ])}
                >
                    <Circle className={cx('global!ace-u-margin--small-bottom-16')}>
                        <Icon
                            icon={loginIcon}
                            className={cx([
                                'ace-c-icon--48',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    </Circle>
                    <h2
                        className={cx([
                            'global!ace-u-typography--variant-h2',
                            'global!ace-u-margin--small-bottom-0',
                            'global!ace-u-margin--large-bottom-8',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateScreen('header.title')}
                    </h2>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-margin--large-bottom-32',
                            'global!ace-u-margin--small-bottom-24',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateScreen('header.text')}
                    </p>
                    <ContentItem
                        className={cx([
                            'ace-c-content-item--small-span-12',
                        ])}
                    >
                        <Form name="loginForm" onSubmit={loginUser}>
                            {formValues => {
                                const isDisabled = !(formValues['membershipNo'] && formValues['lastName'] && formValues['birthDate']);
                                return (
                                    <Fragment>
                                        <InputField
                                            name="membershipNo"
                                            label={translateScreen('input_label.membership_number')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--large-bottom-32',
                                                'global!ace-u-margin--small-bottom-24',
                                            ])}
                                            value={searchQueryParams && searchQueryParams.membershipNo ? searchQueryParams.membershipNo : ''}
                                        />
                                        <InputField
                                            name="lastName"
                                            label={translateScreen('input_label.last_name')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--large-bottom-32',
                                                'global!ace-u-margin--small-bottom-24',
                                            ])}
                                            value={searchQueryParams && searchQueryParams.lastName ? searchQueryParams.lastName : ''}
                                        />
                                        <DateField
                                            name="birthDate"
                                            label={translateScreen('date_label.birth_date')}
                                            icon={calendarIcon}
                                            format="DD.MM.YYYY"
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--large-bottom-32',
                                                'global!ace-u-margin--small-bottom-24',
                                            ])}
                                            value={searchQueryParams && searchQueryParams.birthDate ? searchQueryParams.birthDate : ''}
                                            direction="top"
                                            placeholder="DD.MM.YYYY"
                                            maxDate={moment().format()}
                                        />
                                        <ContentBlock
                                            className={cx([
                                                'global!ace-u-full-height',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-flex--justify-flex-end',
                                            ])}
                                        >
                                            <ContentItem
                                                className={cx([
                                                    'ace-c-content-item--small-span-12',
                                                    'ace-c-content-item--large-span-6',
                                                ])}
                                            >
                                                <ButtonPrimary
                                                    name="submitButton"
                                                    isDisabled={isDisabled}
                                                    type="submit"
                                                    className={cx([
                                                        'global!ace-u-full-width',
                                                    ])}
                                                >
                                                    {translateScreen('button_label.login')}
                                                </ButtonPrimary>
                                                {error && (
                                                    <ErrorMessage
                                                        className={cx('global!global!ace-u-margin--small-32-0')}
                                                    >
                                                        {error}
                                                    </ErrorMessage>
                                                )}
                                            </ContentItem>
                                        </ContentBlock>
                                    </Fragment>
                                );
                            }}
                        </Form>
                    </ContentItem>
                </Card>
            </ContentItem>
        </ContentBlock>
    );
};

LoginScreen.propTypes = {
    loginUser: PropTypes.func.isRequired,
    error: PropTypes.string,
    history: PropTypes.object,
};

LoginScreen.defaultProps = {
    error: '',
    history: {},
};

const mapStateToProps = state => {
    return {
        error: state.auth.error,
    };
};

const mapDispatchToProps = dispatch => ({
    loginUser: payload => dispatch({
        type: authActionTypes.LOGIN_USER,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
