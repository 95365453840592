/**
 * Route paths
 */
export default {
    DASHBOARD: '/',
    LOGIN: '/login',
    NEW_INVOICE_SUBMISSION_CREATION: '/new/create-invoice-submission/:invoiceSubmissionId?',
    SERVICE_CASES: '/service-cases',
    INVOICE_SUBMISSION_CREATION: '/create-invoice-submission/:serviceCaseId/:invoiceSubmissionId',
    CREATE_CASE_STATUS: '/create-status',
    INVOICE_SUBMISSIONS: '/invoice-submissions/:activeTab',
};
