import * as actionTypes from './errorsActionTypes';

const initialState = {
    error: null,
};

const errorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_ERROR: {
            const {error} = action.payload;
            state = {
                ...state,
                error,
            };
            break;
        }
        case actionTypes.DELETE_ERROR: {
            state = {
                ...initialState,
            };
            break;
        }
        default:
        // no-op
    }
    return state;
};

export default errorsReducer;
