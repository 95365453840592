import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';

/**
 * Attachment entity type definition
 *
 * @typedef {Object} Attachment
 * @property {?String} id
 * @property {?String} fileName
 * @property {?String} url
 */

/**
 * Attachment entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const attachmentEntityDTOMapping = {
    id: {
        dtoProperty: 'id',
        defaultValue: '',
    },
    fileName: {
        dtoProperty: 'fileName',
        defaultValue: '',
    },
    url: {
        dtoProperty: 'url',
        defaultValue: '',
    },
};

/**
 * Attachment entity
 *
 * @class
 * @extends Entity
 * @type {Attachment}
 */
export default class Attachment extends Entity {
    [immerable] = true;

    static entityDTOMapping = attachmentEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
