import React, {Fragment, useContext, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {isValidIBAN, electronicFormatIBAN} from 'ibantools';
import bic from 'bic';
import {Card, Circle, Form, Icon, InputField, Option, paymentIcon, SelectField, StepperContext, useStyles} from '../../ui-components';
import {bankInformation} from '../constants/bankInformation';
import FormButtons from './FormButtons';
import {formStepNames} from '../constants/forms';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {isBankValidationError} from '../utils/validation';

const BankInfoForm = props => {
    const {cx} = useStyles(props);
    const {invoiceSubmissionDraft, memberInfo, updateInvoiceSubmissionDraft, isOverlay} = props;
    const {cancelInvoiceSubmissionDraft} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('bank_info_form');
    const {selectStep, currentStep} = useContext(StepperContext);
    const [isValidIban, setIsValidIban] = useState(true);
    const [isValidBic, setIsValidBic] = useState(true);
    const [emptyAccountOwner, setEmptyAccountOwner] = useState(false);
    const chosenBankInformation = invoiceSubmissionDraft.paymentInfo?.iban
        ? invoiceSubmissionDraft.paymentInfo.iban === memberInfo.bank?.iban
            ? bankInformation.EXISTING
            : bankInformation.NEW
        : memberInfo.bank?.iban && !invoiceSubmissionDraft.paymentInfo?.bankAccountOwner
            ? bankInformation.EXISTING
            : bankInformation.NEW;
    const bankAccountOwner = invoiceSubmissionDraft?.member?.name || '';
    const [iban, setIban] = useState(chosenBankInformation === bankInformation.EXISTING
        ? [...Array(memberInfo.bank?.iban.length - 4).keys()].map(characterIndex => {
            characterIndex = 'X';
            return characterIndex;
        }).join('').concat(memberInfo.bank?.iban.slice(-4))
        : invoiceSubmissionDraft?.paymentInfo?.iban || '');
    const [swift, setSwift] = useState(chosenBankInformation === bankInformation.EXISTING
    && !!memberInfo.bank?.swift.length
        ? [...Array(memberInfo.bank?.swift.length - 2).keys()].map(characterIndex => {
            characterIndex = 'X';
            return characterIndex;
        }).join('').concat(memberInfo.bank?.swift.slice(-2))
        : invoiceSubmissionDraft?.paymentInfo?.swift || '');

    if (!invoiceSubmissionDraft) return null;

    const handleOnBankInfoTypeSwitch = value => {
        setEmptyAccountOwner(false);
        setIsValidIban(true);
        setIsValidBic(true);
        if (value === bankInformation.EXISTING) {
            setIban([...Array(memberInfo.bank?.iban.length - 4).keys()].map(characterIndex => {
                characterIndex = 'X';
                return characterIndex;
            }).join('').concat(memberInfo.bank?.iban.slice(-4)));
            setSwift([...Array(memberInfo.bank?.swift.length - 2).keys()].map(characterIndex => {
                characterIndex = 'X';
                return characterIndex;
            }).join('').concat(memberInfo.bank?.swift.slice(-2)));
            return;
        }
        setIban('');
        setSwift('');
    };

    const handleOnSubmit = formValues => {
        if (!formValues) return;
        updateInvoiceSubmissionDraft({
            invoiceSubmissionDraftData: {
                paymentInfo: formValues,
            },
            selectStep,
            currentStep,
        });
    };

    const handleOnClose = formValues => {
        let invoiceSubmissionDraftData;
        if (formValues) {
            invoiceSubmissionDraftData = {
                paymentInfo: formValues,
            };
        }
        cancelInvoiceSubmissionDraft({invoiceSubmissionDraftData, currentStep});
    };

    return (
        <Form name="bankInfo" onSubmit={handleOnSubmit}>
            {formValues => {
                const isGermanyIBAN = !!formValues['iban'] && formValues['iban'].startsWith('DE');
                if (isGermanyIBAN) {
                    // reset 'swift' field when it's a Germany IBAN
                    formValues['swift'] = '';
                }
                return (
                    <Fragment>
                        <h2
                            className={cx([
                                'global!ace-u-typography--variant-h2',
                                'global!ace-u-margin--small-bottom-24',
                            ])}
                        >
                            {translateForm('title.your_bank_details')}
                        </h2>
                        <SelectField
                            name="bankInformationSource"
                            value={chosenBankInformation}
                            className={cx([
                                'global!ace-u-margin--bottom-16',
                                'global!ace-u-width--small-full',
                            ])}
                            onChange={value => handleOnBankInfoTypeSwitch(value)}
                        >
                            {Object.keys(bankInformation).filter(bankInfo => (
                                (bankInfo === bankInformation.EXISTING
                                    && memberInfo.bank?.iban)
                                || bankInfo === bankInformation.NEW
                            )).map(bankInfo => (
                                <Option
                                    name={`${bankInfo}Option`}
                                    key={bankInfo}
                                    value={bankInfo}
                                >
                                    {translateForm(`select_input_option.${bankInfo.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                        <Card
                            className={cx([
                                'global!ace-u-padding--small-bottom-24',
                                'global!ace-u-padding--small-top-24',
                                'global!ace-u-padding--small-right-16',
                                'global!ace-u-padding--small-left-16',
                                'global!ace-u-padding--large-32',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-margin--small-bottom-24',
                            ])}
                        >
                            {(chosenBankInformation !== bankInformation.EXISTING
                                    || formValues['bankInformationSource'] !== bankInformation.EXISTING)
                                && (
                                    <Fragment>
                                        <Circle
                                            className={cx([
                                                'ace-c-circle--small',
                                                'global!ace-u-margin--bottom-16',
                                            ])}
                                        >
                                            <Icon
                                                icon={paymentIcon}
                                                className={cx([
                                                    'ace-c-icon--24',
                                                    'ace-c-icon--color-highlight',
                                                ])}
                                            />
                                        </Circle>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-margin--small-bottom-24',
                                                'global!ace-u-margin--medium-bottom-32',
                                                'global!ace-u-padding--medium-0-128',
                                                'global!ace-u-flex--direction-column',
                                                'global!ace-u-flex--align-items-center',
                                                'global!ace-u-display--small-none',
                                                'global!ace-u-display--large-flex',
                                            ])}
                                        >
                                            <p
                                                className={cx([
                                                    'global!ace-u-typography--align-center',
                                                    'global!ace-u-typography--variant-body-bold',
                                                ])}
                                            >
                                                {translateForm('text.no_bank_details_given')}
                                            </p>
                                            <p className={cx('global!ace-u-typography--align-center')}>
                                                {translateForm('text.please_enter_bank_details')}
                                            </p>
                                            <p className={cx('global!ace-u-typography--align-center')}>
                                                {translateForm('text.send_refund')}
                                            </p>
                                        </div>
                                    </Fragment>
                                )}
                            <InputField
                                name="bankAccountOwner"
                                label={translateForm('input_label.account_owner')}
                                placeholder={translateForm('input_placeholder.account_owner')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                onBlur={() => setEmptyAccountOwner(formValues['bankAccountOwner'] === '')}
                                errors={emptyAccountOwner ? [translate('global.error.empty_mandatory_field')] : []}
                                value={formValues['bankInformationSource'] === bankInformation.EXISTING && memberInfo.bank?.bankAccountOwner
                                    ? memberInfo.bank.bankAccountOwner
                                    : invoiceSubmissionDraft.paymentInfo?.bankAccountOwner || bankAccountOwner}
                                isDisabled={formValues['bankInformationSource'] === bankInformation.EXISTING && !!memberInfo.bank?.bankAccountOwner}
                            />
                            <InputField
                                maxLength="32"
                                name={formValues['bankInformationSource'] === bankInformation.EXISTING && memberInfo.bank?.iban
                                    ? 'ibanVisible' : 'iban'}
                                placeholder={translateForm('input_placeholder.iban')}
                                label={translateForm('input_label.iban')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={iban}
                                onBlur={() => {
                                    setIsValidIban(isValidIBAN(electronicFormatIBAN(formValues['iban'].toUpperCase())));
                                }}
                                errors={!isValidIban ? [translate('global.error.invalid_entry')] : []}
                                isDisabled={formValues['bankInformationSource'] === bankInformation.EXISTING}
                            />
                            {formValues['bankInformationSource'] === bankInformation.EXISTING && memberInfo.bank?.iban
                                && (
                                    <InputField
                                        name="iban"
                                        value={memberInfo.bank.iban}
                                        type="hidden"
                                    />
                                )
                            }
                            <InputField
                                maxLength="11"
                                name={formValues['bankInformationSource'] === bankInformation.EXISTING && memberInfo.bank?.swift
                                    ? 'accountBicVisible' : 'swift'}
                                label={translateForm('input_label.bic_swift')}
                                placeholder={translateForm('input_placeholder.bic_swift')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-32',
                                ])}
                                value={swift}
                                onBlur={() => {
                                    formValues['swift']
                                        ? setIsValidBic(bic.isValid(formValues['swift']))
                                        : setIsValidBic(true);
                                }}
                                errors={isGermanyIBAN || isValidBic
                                    ? []
                                    : [translate('global.error.invalid_entry')]}
                                isDisabled={formValues['bankInformationSource'] === bankInformation.EXISTING
                                    || isGermanyIBAN}
                            />
                            {formValues['bankInformationSource'] === bankInformation.EXISTING && memberInfo.bank?.swift
                                && (
                                    <InputField
                                        name="swift"
                                        value={memberInfo.bank.swift}
                                        type="hidden"
                                    />
                                )
                            }
                        </Card>
                        {!isOverlay && (
                            <FormButtons
                                formName={formStepNames.BANK_FORM}
                                isSubmitDisabled={isBankValidationError(formValues || invoiceSubmissionDraft)}
                                handleOnClose={() => handleOnClose(formValues)}
                            />
                        )}
                    </Fragment>
                );
            }}
        </Form>
    );
};

BankInfoForm.propTypes = {
    invoiceSubmissionDraft: PropTypes.object,
    memberInfo: PropTypes.object.isRequired,
    updateInvoiceSubmissionDraft: PropTypes.func.isRequired,
    isOverlay: PropTypes.bool,
    cancelInvoiceSubmissionDraft: PropTypes.func.isRequired,
};

BankInfoForm.defaultProps = {
    invoiceSubmissionDraft: null,
    isOverlay: false,
};

const mapStateToProps = state => {
    return {
        memberInfo: state.member.memberInfo,
        invoiceSubmissionDraft: state.invoiceSubmissions.invoiceSubmissionDraft,
    };
};

const mapDispatchToProps = dispatch => ({
    updateInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
    cancelInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_INVOICE_SUBMISSION_CANCEL_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankInfoForm);
