import {produce} from 'immer';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import {createInvoiceSubmissionStatuses} from './constants/createInvoiceSubmissionStatuses';
import {InvoiceSubmission} from '../mia-entities/invoice-submission';
import config from '../config';
import * as serviceCaseActionTypes from '../service-case/serviceCaseActionTypes';

const initialState = {
    invoiceSubmission: {},
    invoiceSubmissions: {},
    activeInvoiceSubmissionIds: [],
    inactiveInvoiceSubmissionIds: [],
    createInvoiceSubmissionState: createInvoiceSubmissionStatuses.IDLE,
    resubmitCount: config.initialResubmitCount,
    isLoading: true,
    stickyBarPosition: {},
    invoiceSubmissionDraft: null,
    aidServicePrice: 0,
};

const invoiceSubmissionReducer = produce((draft, action) => {
    const {type, payload} = action;
    switch (type) {
        case invoiceSubmissionActionTypes.STORE_CREATE_STATUS: {
            const {createInvoiceSubmissionState} = payload;
            draft.createInvoiceSubmissionState = createInvoiceSubmissionState;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_ACTIVE_INVOICE_SUBMISSIONS: {
            const {invoiceSubmissionsDTO} = payload;
            if (!invoiceSubmissionsDTO) {
                draft.activeInvoiceSubmissionIds = [];
                break;
            }
            invoiceSubmissionsDTO.forEach(invoiceSubmissionDTO => {
                const caseId = invoiceSubmissionDTO.invoiceSubmissionId;
                // put an ID only if it doesn't already exist
                if (!draft.activeInvoiceSubmissionIds.find(id => id === caseId)) {
                    draft.activeInvoiceSubmissionIds.push(caseId);
                }
                draft.invoiceSubmissions[caseId] = new InvoiceSubmission().fromDTO(invoiceSubmissionDTO);
            });
            break;
        }
        case invoiceSubmissionActionTypes.STORE_INACTIVE_INVOICE_SUBMISSIONS: {
            const {invoiceSubmissionsDTO} = payload;
            if (!invoiceSubmissionsDTO) {
                draft.inactiveInvoiceSubmissionIds = [];
                break;
            }
            invoiceSubmissionsDTO.forEach(invoiceSubmissionDTO => {
                const caseId = invoiceSubmissionDTO.invoiceSubmissionId;
                // put an ID only if it doesn't already exist
                if (!draft.inactiveInvoiceSubmissionIds.find(id => id === caseId)) {
                    draft.inactiveInvoiceSubmissionIds.push(caseId);
                }
                draft.invoiceSubmissions[caseId] = new InvoiceSubmission().fromDTO(invoiceSubmissionDTO);
            });
            break;
        }
        case serviceCaseActionTypes.STORE_REQUEST_STATE: {
            const {isLoading} = payload;
            draft.isLoading = isLoading;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_RESUBMISSION_COUNT: {
            draft.resubmitCount = payload;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_STICKY_BAR_POSITION: {
            draft.stickyBarPosition = payload;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT: {
            const {invoiceSubmissionDraft} = payload;
            draft.invoiceSubmissionDraft = invoiceSubmissionDraft
                ? new InvoiceSubmission().fromDTO(invoiceSubmissionDraft)
                : null;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_AID_MAX_PRICE: {
            const {aidMaxPriceDTO} = action.payload;
            draft.aidServicePrice = aidMaxPriceDTO.value;
            break;
        }
    }
}, initialState);

export default invoiceSubmissionReducer;
