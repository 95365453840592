import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {DateField, InputCurrencyField, InputField, TextAreaField} from '../../ui-components/text-inputs';
import {SelectField, ToggleSwitch, Checkbox, Option} from '../../ui-components/selector-inputs';
import {calendarIcon, Form, useStyles} from '../../ui-components';
import SearchAutocomplete from '../../google/ui-components/SearchAutocomplete';
import {services} from '../constants/services';
import {personTypes, personTypesTexts} from '../constants/person';
import {purposes} from '../constants/purpose';
import InputCounter from '../../ui-components/text-inputs/InputCounter';
import {getGeolocationFromString} from '../../google/googleMapFunctions';

const RoadsideAssistanceAndTowingForm = props => {
    const {service, member} = props;
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('roadside_assistance_and_towing_form');
    const translateInvoiceForm = createTranslateShorthand('invoice_submission_global');
    const [errors, setErrors] = useState({
        type: false,
        requestedInvoicingAmount: false,
        driverType: false,
        driverFirstName: false,
        driverLastName: false,
        driverAddressStreet: false,
        driverAddressPostCode: false,
        driverAddressCity: false,
        damageLocation: false,
        towingLocation: false,
        ownerType: false,
        ownerFirstName: false,
        ownerLastName: false,
        ownerAddressPostCode: false,
        ownerAddressCity: false,
        ownerAddressStreet: false,
        tripPurpose: false,
        serviceProvider: false,
        thirdPartyName: false,
        thirdPartyAddressStreet: false,
        thirdPartyAddressPostCode: false,
        thirdPartyAddressCity: false,
        thirdPartyInsuranceName: false,
        alcoholTestResult: false,
        insuranceName: false,
        insuranceNumber: false,
        thirdPartyCostsCoveredBy: false,
        thirdPartyCostsPurpose: false,
        thirdPartyCostsAmount: false,
    });
    const [locations, setLocations] = useState({
        damageLocation: service?.damage?.address,
        towingLocation: service?.towing?.address,
    });

    const parseAddress = (field, value) => {
        setErrors(prevState => ({
            ...prevState,
            [field]: !value,
        }));
        getGeolocationFromString(value).then(result => {
            let address = null;
            if (result && result.address) {
                address = {
                    street: result.address.street || '',
                    postCode: result.address.postCode,
                    city: result.address.city,
                    country: result.address.country,
                    formattedAddress: result.address.formattedAddress,
                    location: {
                        longitude: result.location?.lng,
                        latitude: result.location?.lat,
                    },
                };
                setLocations(prevState => ({
                    ...prevState,
                    [field]: address,
                }));
                return;
            }
            setLocations(prevState => ({
                ...prevState,
                [field]: address,
            }));
        });
    };

    const handleErrors = (field, value) => {
        if (!field) return;
        setErrors(prevState => ({
            ...prevState,
            [field]: field === 'damageLocation' || field === 'towingLocation'
                ? locations
                    ? !locations[field]?.formattedAddress
                    : true
                : !value,
        }));
    };

    const isDriverAddressAsMember = service?.driver?.address && Object.keys(service?.driver.address).length > 0
        && (service.driver.address.street === member?.address?.street
        && service.driver.address.city === member?.address?.city
        && service.driver.address.postCode === member?.address?.postCode);
    const isOwnerAddressAsMember = service?.owner?.address && Object.keys(service?.owner.address).length > 0
        && (service.owner.address.street === member?.address?.street
            && service.owner.address.city === member?.address?.city
            && service.owner.address.postCode === member?.address?.postCode);
    const isAccidentCausedByThirdParty = service?.thirdParty && Object.keys(service.thirdParty).length > 0
        && (!!service?.thirdParty?.name
            || !!service?.thirdParty?.address?.street
            || !!service?.thirdParty?.address?.postCode
            || !!service?.thirdParty?.address?.city
            || !!service?.thirdParty?.licensePlate
            || !!service?.thirdParty?.insuranceName
            || !!service?.thirdParty?.insuranceNumber
            || !!service?.thirdParty?.damageNumber);

    return (
        <Form name="roadsideAssistanceAndTowing">
            {formValues => (
                <Fragment>
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        {translateForm('title.select_additional_service')}
                    </h3>
                    <SelectField
                        name="type"
                        label={translateForm('input_label.service_type')}
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--small-bottom-32',
                        ])}
                        value={service?.type || ''}
                        placeholder={translateForm('input_placeholder.service_type')}
                        onBlur={value => handleErrors('type', value)}
                        errors={errors.type ? [translate('global.error.empty_mandatory_field')] : []}
                    >
                        {Object.keys(services).map(service => {
                            return (
                                <Option
                                    name={`${service}Option`}
                                    key={service}
                                    value={service}
                                >{translate(`global.service_type.${service.toLowerCase()}`)}
                                </Option>
                            );
                        })}
                    </SelectField>
                    <div className={cx('global!ace-u-margin--small-bottom-8')} />
                    {formValues?.type && (
                        <Fragment>
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateInvoiceForm('title.total_amount')}
                            </h3>
                            <InputCurrencyField
                                placeholder={translateInvoiceForm('input_placeholder.requested_invoicing_amount')}
                                name="requestedInvoicingAmount"
                                label={translateInvoiceForm('input_label.requested_invoicing_amount')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={service?.requestedInvoicingAmount || ''}
                                type="number"
                                onBlur={value => handleErrors('requestedInvoicingAmount', value)}
                                errors={errors.requestedInvoicingAmount ? [translate('global.error.invalid_entry')] : []}
                            />
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateForm('title.about_the_driver')}
                            </h3>
                            <SelectField
                                name="driverType"
                                label={translateForm('input_label.driver_type')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-32',
                                ])}
                                value={service?.driver?.type || ''}
                                placeholder={translateForm('input_placeholder.driver_type')}
                                onBlur={value => handleErrors('driverType', value)}
                                errors={errors.driverType
                                    ? [translate('global.error.empty_mandatory_field')]
                                    : []
                                }
                            >
                                {Object.keys(personTypes).map(personType => {
                                    return (
                                        <Option
                                            name={`${personType}Option`}
                                            key={personType}
                                            value={personType}
                                        >{personTypesTexts[personType]}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            {!!formValues?.driverType && formValues.driverType !== personTypes.MEMBER && (
                                <Fragment>
                                    <InputField
                                        placeholder={translateForm('input_placeholder.driver_first_name')}
                                        name="driverFirstName"
                                        label={translateForm('input_label.driver_first_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service?.driver?.name || ''}
                                        onBlur={value => handleErrors('driverFirstName', value)}
                                        errors={errors.driverFirstName
                                            ? [translate('global.error.empty_mandatory_field')]
                                            : []
                                        }
                                    />
                                    <InputField
                                        placeholder={translateForm('input_placeholder.driver_last_name')}
                                        name="driverLastName"
                                        label={translateForm('input_label.driver_last_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        onBlur={value => handleErrors('driverLastName', value)}
                                        errors={errors.driverLastName
                                            ? [translate('global.error.empty_mandatory_field')]
                                            : []
                                        }
                                        value={service?.driver?.surname || ''}
                                    />
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                    >
                                        <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                            {translateForm('toggle_switch_label.driver_address_as_member')}
                                        </p>
                                        <ToggleSwitch
                                            name="isDriverAddressAsMember"
                                            value={true}
                                            isSelected={isDriverAddressAsMember}
                                        />
                                    </div>
                                    {!formValues?.isDriverAddressAsMember && (
                                        <Fragment>
                                            <InputField
                                                placeholder={translateForm('input_placeholder.driver_address_street')}
                                                name="driverAddressStreet"
                                                label={translateForm('input_label.driver_address_street')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.driver?.address?.street || ''}
                                                onBlur={value => handleErrors('driverAddressStreet', value)}
                                                errors={errors?.driverAddressStreet ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.driver_address_post_code')}
                                                name="driverAddressPostCode"
                                                label={translateForm('input_label.driver_address_post_code')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.driver?.address?.postCode || ''}
                                                onBlur={value => handleErrors('driverAddressPostCode', value)}
                                                errors={errors?.driverAddressPostCode ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.driver_address_city')}
                                                name="driverAddressCity"
                                                label={translateForm('input_label.driver_address_city')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.driver?.address?.city || ''}
                                                onBlur={value => handleErrors('driverAddressCity', value)}
                                                errors={errors?.driverAddressCity ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateForm('title.about_the_owner')}
                            </h3>
                            <SelectField
                                name="ownerType"
                                label={translateForm('select_label.owner_type')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-32',
                                ])}
                                value={service?.owner?.type || ''}
                                placeholder={translateForm('select_placeholder.owner_type')}
                                onBlur={value => handleErrors('ownerType', value)}
                                errors={errors.ownerType ? [translate('global.error.empty_mandatory_field')] : []}
                            >
                                {Object.keys(personTypes).map(personType => {
                                    return (
                                        <Option
                                            key={personType}
                                            name={`${personType}Option`}
                                            value={personType}
                                        >{translate(`global.person_type.${personType.toLowerCase()}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            {!!formValues?.ownerType && formValues.ownerType !== personTypes.MEMBER && (
                                <Fragment>
                                    <InputField
                                        placeholder={translateForm('input_placeholder.owner_first_name')}
                                        name="ownerFirstName"
                                        label={translateForm('input_label.owner_first_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service?.owner?.name || ''}
                                        onBlur={value => handleErrors('ownerFirstName', value)}
                                        errors={errors.ownerFirstName ? [translate('global.error.empty_mandatory_field')] : []}
                                    />

                                    <InputField
                                        placeholder={translateForm('input_placeholder.owner_last_name')}
                                        name="ownerLastName"
                                        label={translateForm('input_label.owner_last_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service?.owner?.surname || ''}
                                        onBlur={value => handleErrors('ownerLastName', value)}
                                        errors={errors.ownerLastName ? [translate('global.error.empty_mandatory_field')] : []}
                                    />

                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                    >
                                        <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                            {translateForm('toggle_switch_label.owner_address_as_member')}
                                        </p>
                                        <ToggleSwitch
                                            name="isOwnerAddressAsMember"
                                            value={true}
                                            isSelected={isOwnerAddressAsMember}
                                        />
                                    </div>
                                    {!formValues?.isOwnerAddressAsMember && (
                                        <Fragment>
                                            <InputField
                                                placeholder={translateForm('input_placeholder.owner_address_street')}
                                                name="ownerAddressStreet"
                                                label={translateForm('input_label.owner_address_street')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.owner?.address?.street}
                                                onBlur={value => handleErrors('ownerAddressStreet', value)}
                                                errors={errors.ownerAddressStreet ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.owner_address_post_code')}
                                                name="ownerAddressPostCode"
                                                label={translateForm('input_label.owner_address_post_code')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.owner?.address?.postCode || ''}
                                                onBlur={value => handleErrors('ownerAddressPostCode', value)}
                                                errors={errors.ownerAddressPostCode ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.owner_address_city')}
                                                name="ownerAddressCity"
                                                label={translateForm('input_label.owner_address_city')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.owner?.address?.city || ''}
                                                onBlur={value => handleErrors('ownerAddressCity', value)}
                                                errors={errors.ownerAddressCity ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                        </Fragment>
                                    )
                                    }

                                </Fragment>
                            )}
                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateForm('title.case_data')}
                            </h3>
                            <SelectField
                                name="tripPurpose"
                                label={translateForm('select_label.trip_purpose')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-32',
                                ])}
                                value={service?.tripPurpose || ''}
                                placeholder={translateForm('select_placeholder.trip_purpose')}
                                onBlur={value => handleErrors('tripPurpose', value)}
                                errors={errors.tripPurpose ? [translate('global.error.empty_mandatory_field')] : []}
                            >
                                {Object.keys(purposes).map(purpose => {
                                    return (
                                        <Option
                                            key={purpose}
                                            name={`${purpose}Option`}
                                            value={purpose}
                                        >{translate(`global.purpose.${purpose.toLowerCase()}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                            {/* autocomplete */}
                            <SearchAutocomplete
                                placeholder={translateForm('input_placeholder.damage_location')}
                                name="formattedDamageLocation"
                                label={translateForm('input_label.damage_location')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={formValues?.formattedDamageLocation || service?.damage?.address?.formattedAddress
                                    || (service?.damage?.address
                                        ? Object.keys(service?.damage?.address)
                                            .filter(key => key !== 'country' && key !== 'location' && key !== 'persistenceState')
                                            .map(key => (service?.damage?.address[key]))
                                            .filter(value => !!value)
                                            .join(' ')
                                        : '')}
                                onBlur={value => handleErrors('damageLocation', value)}
                                onChange={() => setLocations(prevState => ({
                                    ...prevState,
                                    damageLocation: null,
                                }))}
                                onOptionSelect={value => parseAddress('damageLocation', value)}
                                errors={errors.damageLocation ? [translate('global.error.empty_mandatory_field')] : []}
                            />
                            <InputField
                                name="damageLocation"
                                type="hidden"
                                value={locations?.damageLocation || ''}
                            />
                            <DateField
                                name="damageDate"
                                label={translateForm('date_field_label.damage_date')}
                                icon={calendarIcon}
                                format="DD.MM.YYYY"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={service?.damage?.date ? moment(service.damage.date).format('DD.MM.YYYY') : ''}
                                maxDate={moment().format('DD.MM.YYYY')}
                            />
                            {/* autocomplete */}
                            {formValues.type === services.TOWING && (
                            <Fragment>
                                <SearchAutocomplete
                                    placeholder={translateForm('input_placeholder.towing_location')}
                                    name="formattedTowingLocation"
                                    label={translateForm('input_label.towing_location')}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--large-bottom-32',
                                        'global!ace-u-margin--small-bottom-24',
                                    ])}
                                    value={formValues.hasOwnProperty('formattedTowingLocation')
                                        ? formValues?.formattedTowingLocation
                                        : service?.towing?.address?.formattedAddress || ''}
                                    onBlur={value => handleErrors('towingLocation', value)}
                                    onChange={() => setLocations(prevState => ({
                                        ...prevState,
                                        towingLocation: null,
                                    }))}
                                    onOptionSelect={value => parseAddress('towingLocation', value)}
                                    errors={errors?.towingLocation ? [translate('global.error.empty_mandatory_field')] : []}
                                />

                                <InputField
                                    name="towingLocation"
                                    type="hidden"
                                    value={locations?.towingLocation || ''}
                                />
                            </Fragment>
                            )
                            }
                            <InputField
                                placeholder={translateForm('input_placeholder.service_provider')}
                                name="serviceProvider"
                                label={translateForm('input_label.service_provider')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={service?.serviceProvider || ''}
                                onBlur={value => handleErrors('serviceProvider', value)}
                                errors={errors.serviceProvider ? [translate('global.error.empty_mandatory_field')] : []}
                            />
                            <DateField
                                name="endOfRecoveryDateTime"
                                label={translateForm('date_field_label.end_of_recovery_date')}
                                icon={calendarIcon}
                                format="DD.MM.YYYY"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={service?.endOfRecoveryDateTime ? moment(service.endOfRecoveryDateTime).format('DD.MM.YYYY') : ''}
                                isFieldMandatory={false}
                                minDate={formValues?.damageDate
                                    ? moment(formValues.damageDate, ['DD.MM.YYYY', moment.ISO_8601]).format('DD.MM.YYYY')
                                    : ''}
                                errors={formValues?.damageDate && formValues?.endOfRecoveryDateTime
                                && moment(formValues.damageDate, ['YYYY-MM-DD', 'DD.MM.YYYY'])
                                    .isAfter(moment(formValues.endOfRecoveryDateTime, ['YYYY-MM-DD', 'DD.MM.YYYY']))
                                    ? [translate('global.error.invalid_date')]
                                    : []
                                }
                            />
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateForm('title.number_of_passengers')}
                            </p>
                            <InputCounter
                                className={cx([
                                    'global!ace-u-margin--small-bottom-32',
                                ])}
                                name="numberOfPassengers"
                                value={service?.numberOfPassengers || ''}
                            />
                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                            >
                                {translateForm('title.damage_description')}
                            </h3>
                            <TextAreaField
                                placeholder={translateForm('text_area_placeholder.comment')}
                                name="comment"
                                value={service?.comment || ''}
                                maxLength={250}
                                isResizable={false}
                                className={cx(['ace-c-text-area--small', 'global!ace-u-margin--small-bottom-24', 'global!ace-u-full-width'])}
                                label={translateForm('text_area_label.comment')}
                            />
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                            >
                                {translateForm('title.police_accident')}
                            </h3>

                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                            >
                                <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                    {translateForm('toggle_switch_label.accident')}
                                </p>
                                <ToggleSwitch
                                    name="isAccident"
                                    value={true}
                                    isSelected={!!service?.isAccident}
                                />
                            </div>
                            {formValues?.isAccident && (
                                <Fragment>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                    >
                                        <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                            {translateForm('toggle_switch_label.recorded_by_police')}
                                        </p>
                                        <ToggleSwitch
                                            name="isRecordedByPolice"
                                            value={true}
                                            isSelected={!!service?.isRecordedByPolice}
                                        />
                                    </div>
                                    {formValues?.isRecordedByPolice && (
                                        <Fragment>
                                            <InputField
                                                placeholder={translateForm('input_placeholder.police_department')}
                                                name="policeDepartment"
                                                label={translateForm('input_label.police_department')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.policeDepartment || ''}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.police_record_number')}
                                                name="policeRecordNumber"
                                                label={translateForm('input_label.police_record_number')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-32',
                                                ])}
                                                value={service?.policeRecordNumber || ''}
                                            />
                                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                                        </Fragment>
                                    )}
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                    >
                                        <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                            {translateForm('toggle_switch_label.accident_caused_by_third_party')}
                                        </p>
                                        <ToggleSwitch
                                            name="isAccidentCausedByThirdParty"
                                            value={true}
                                            isSelected={isAccidentCausedByThirdParty}
                                        />
                                    </div>
                                    {formValues?.isAccidentCausedByThirdParty && (
                                        <Fragment>
                                            <p className={cx(['global!ace-u-margin--small-bottom-24', 'global!ace-u-typography--variant-body'])}>
                                                {translateForm('section_label.responsible_person')}
                                            </p>
                                            <InputField
                                                placeholder={translateForm('input_placeholder.third_party_name')}
                                                name="thirdPartyName"
                                                label={translateForm('input_label.third_party_name')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.thirdParty?.name || ''}
                                                onBlur={value => handleErrors('thirdPartyName', value)}
                                                errors={errors.thirdPartyName ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.third_party_address_street')}
                                                name="thirdPartyAddressStreet"
                                                label={translateForm('input_label.third_party_address_street')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-32',
                                                ])}
                                                value={service?.thirdParty?.address?.street || ''}
                                                onBlur={value => handleErrors('thirdPartyAddressStreet', value)}
                                                errors={errors.thirdPartyAddressStreet ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.third_party_address_post_code')}
                                                name="thirdPartyAddressPostCode"
                                                label={translateForm('input_label.third_party_address_post_code')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.thirdParty?.address?.postCode || ''}
                                                onBlur={value => handleErrors('thirdPartyAddressPostCode', value)}
                                                errors={errors.thirdPartyAddressPostCode ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.third_party_address_city')}
                                                name="thirdPartyAddressCity"
                                                label={translateForm('input_label.third_party_address_city')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.thirdParty?.address?.city || ''}
                                                onBlur={value => handleErrors('thirdPartyAddressCity', value)}
                                                errors={errors.thirdPartyAddressCity ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.third_party_license_plate')}
                                                name="thirdPartyLicensePlate"
                                                label={translateForm('input_label.third_party_license_plate')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.thirdParty?.licensePlate || ''}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.third_party_insurance_name')}
                                                name="thirdPartyInsuranceName"
                                                label={translateForm('input_label.third_party_insurance_name')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.thirdParty?.insuranceName || ''}
                                                onBlur={value => handleErrors('thirdPartyInsuranceName', value)}
                                                errors={errors.thirdPartyInsuranceName ? [translate('global.error.empty_mandatory_field')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.third_party_insurance_number')}
                                                name="thirdPartyInsuranceNumber"
                                                label={translateForm('input_label.third_party_insurance_number')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.thirdParty?.insuranceNumber || ''}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.third_party_damage_number')}
                                                name="thirdPartyDamageNumber"
                                                label={translateForm('input_label.third_party_damage_number')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--small-bottom-32',
                                                ])}
                                                value={service?.thirdParty?.damageNumber || ''}
                                            />
                                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                            >
                                <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                    {translateForm('toggle_switch_label.alcohol_test_done')}
                                </p>
                                <ToggleSwitch
                                    name="isAlcoholTestDone"
                                    value={true}
                                    isSelected={!!service?.isAlcoholTestDone}
                                />
                            </div>
                            {formValues?.isAlcoholTestDone && (
                                <Fragment>
                                    <InputField
                                        placeholder={translateForm('input_placeholder.third_party_damage_number')}
                                        name="alcoholTestResult"
                                        label={translateForm('input_label.alcohol_test_result')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--small-bottom-32',
                                        ])}
                                        value={service?.alcoholTestResult || ''}
                                        onBlur={value => handleErrors('alcoholTestResult', value)}
                                        errors={errors.alcoholTestResult ? [translate('global.error.empty_mandatory_field')] : ''}
                                    />
                                    <div className={cx('global!ace-u-margin--small-bottom-8')} />
                                </Fragment>
                            )}
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                            >
                                {translateForm('title.insurance')}
                            </h3>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--small-bottom-8',
                                ])}
                            >
                                <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                    {translateForm('toggle_switch_label.insurance_covers_costs')}
                                </p>
                                <ToggleSwitch
                                    name="isInsuranceCoversCosts"
                                    value={true}
                                    isSelected={!!service?.isInsuranceCoversCosts}
                                />
                            </div>
                            <p className={cx(['global!ace-u-margin--small-bottom-24', 'global!ace-u-typography--variant-caption'])}>
                                {translateForm('text.insurance_covers_costs')}
                            </p>
                            {formValues?.isInsuranceCoversCosts && (
                                <Fragment>
                                    <InputField
                                        placeholder={translateForm('input_placeholder.insurance_name')}
                                        name="insuranceName"
                                        label={translateForm('input_label.insurance_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service?.insuranceName || ''}
                                        onBlur={value => handleErrors('insuranceName', value)}
                                        errors={errors.insuranceName ? [translate('global.error.empty_mandatory_field')] : []}
                                    />
                                    <InputField
                                        placeholder={translateForm('input_placeholder.insurance_number')}
                                        name="insuranceNumber"
                                        label={translateForm('input_label.insurance_number')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--small-bottom-32',
                                        ])}
                                        value={service?.insuranceNumber || ''}
                                        onBlur={value => handleErrors('insuranceNumber', value)}
                                        errors={errors.insuranceNumber ? [translate('global.error.empty_mandatory_field')] : []}
                                    />
                                    <div className={cx('global!ace-u-margin--small-bottom-8')} />
                                </Fragment>
                            )}
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                            >
                                <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                    {translateForm('toggle_switch_label.third_party_cost')}
                                </p>
                                <ToggleSwitch
                                    name="isThirdPartyCost"
                                    value={true}
                                    isSelected={!!service?.thirdParty?.costsCoveredBy
                                        || !!service?.thirdParty?.costsPurpose
                                        || !!service?.thirdParty?.costsAmount}
                                />
                            </div>
                            {formValues?.isThirdPartyCost && (
                                <Fragment>
                                    <InputField
                                        placeholder={translateForm('input_placeholder.third_party_costs_covered_by')}
                                        name="thirdPartyCostsCoveredBy"
                                        label={translateForm('input_label.third_party_costs_covered_by')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service?.thirdParty?.costsCoveredBy || ''}
                                        onBlur={value => handleErrors('thirdPartyCostsCoveredBy', value)}
                                        errors={errors.thirdPartyCostsCoveredBy ? [translate('global.error.empty_mandatory_field')] : []}
                                    />
                                    <InputField
                                        placeholder={translateForm('input_placeholder.third_party_costs_purpose')}
                                        name="thirdPartyCostsPurpose"
                                        label={translateForm('input_label.third_party_costs_purpose')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service?.thirdParty?.costsPurpose || ''}
                                        onBlur={value => handleErrors('thirdPartyCostsPurpose', value)}
                                        errors={errors.thirdPartyCostsPurpose ? [translate('global.error.empty_mandatory_field')] : []}
                                    />
                                    <InputCurrencyField
                                        placeholder={translateForm('input_placeholder.third_party_costs_amount')}
                                        name="thirdPartyCostsAmount"
                                        label={translateForm('input_label.third_party_costs_amount')}
                                        className={cx([
                                            'global!ace-u-margin--small-bottom-32',
                                        ])}
                                        value={service?.thirdParty?.costsAmount || ''}
                                        onBlur={value => handleErrors('thirdPartyCostsAmount', value)}
                                        errors={errors.thirdPartyCostsAmount ? [translate('global.error.empty_mandatory_field')] : []}
                                        isCurrency={true}
                                        type="number"
                                    />
                                    <div className={cx('global!ace-u-margin--small-bottom-8')} />
                                </Fragment>
                            )}

                            <Checkbox
                                name="isDriversLicenseValid"
                                value={true}
                                className={cx(['global!ace-u-margin--small-bottom-24'])}
                                isSelected={!!service?.isDriversLicenseValid}
                            >
                                {translateForm('checkbox_label.drivers_license_valid')}
                            </Checkbox>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Form>
    );
};

RoadsideAssistanceAndTowingForm.propTypes = {
    service: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
};

export default RoadsideAssistanceAndTowingForm;
