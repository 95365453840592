import {all, fork} from 'redux-saga/effects';
import provideTokenToServicesFlow from './sagas/provideTokenToServices';

const applicationWatcher = function* applicationWatcher() {
    yield all([
        fork(provideTokenToServicesFlow),
    ]);
};

export default applicationWatcher;
