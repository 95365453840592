import {produce} from 'immer';
import * as serviceCaseActionTypes from '../service-case/serviceCaseActionTypes';

const initialState = {
    files: {},
    isLoading: false,
    fileIdsInUploadQueue: [],
};


const createFilesReducer = context => {
    const {actionTypes, File} = context;

    return produce((draft, action) => {
        switch (action.type) {
            case actionTypes.STORE_FILES: {
                const filesDTOs = action.payload.files || [];
                const files = {};

                filesDTOs.forEach(fileDTO => {
                    const file = new File().fromDTO(fileDTO);
                    if (file.id) {
                        files[file.id] = file;
                    }
                });
                draft.files = files;
                break;
            }
            case actionTypes.STORE_FILE: {
                const fileDTO = action.payload.file;
                const file = new File().fromDTO(fileDTO);
                draft.files[file.id] = file;
                draft.fileIdsInUploadQueue = draft.fileIdsInUploadQueue.filter(id => file.id !== id);
                break;
            }
            case actionTypes.DELETE_FILE: {
                const {id} = action.payload;
                delete draft.files[id];
                break;
            }
            case serviceCaseActionTypes.STORE_UPLOAD_STATE: {
                const {isLoading} = action.payload;
                draft.isLoading = isLoading || draft.fileIdsInUploadQueue.length !== 0;
                break;
            }

            case actionTypes.STORE_UPLOAD_QUEUE: {
                const {fileId} = action.payload;
                if (!draft.fileIdsInUploadQueue.find(id => fileId === id)) {
                    draft.fileIdsInUploadQueue.push(fileId);
                }
                break;
            }
        }
    }, initialState);
};

export default createFilesReducer;
