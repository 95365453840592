import moment from 'moment';
import {categories} from '../constants/categories';
import {services} from '../constants/services';
import {serviceTypes} from '../../mia-entities';
import {personTypes} from '../constants/person';

export const mapServiceToFormFields = (service, member) => {
    if (!service) return null;
    const isDriverAddressAsMember = service?.driver?.address && Object.keys(service?.driver.address).length > 0
        && (service.driver.address.street === member?.address?.street
            && service.driver.address.city === member?.address?.city
            && service.driver.address.postCode === member?.address?.postCode);
    const isOwnerAddressAsMember = service?.owner?.address && Object.keys(service?.owner.address).length > 0
        && (service.owner.address.street === member?.address?.street
            && service.owner.address.city === member?.address?.city
            && service.owner.address.postCode === member?.address?.postCode);
    const isAccidentCausedByThirdParty = service?.thirdParty && Object.keys(service.thirdParty).length > 0
        && (!!service?.thirdParty?.name
            || !!service?.thirdParty?.address?.street
            || !!service?.thirdParty?.address?.postCode
            || !!service?.thirdParty?.address?.city
            || !!service?.thirdParty?.licensePlate
            || !!service?.thirdParty?.insuranceName
            || !!service?.thirdParty?.insuranceNumber
            || !!service?.thirdParty?.damageNumber);

    switch (service.type) {
        case serviceTypes.ROADSIDE_ASSISTANCE:
        case serviceTypes.TOWING:
        case serviceTypes.VEHICLE_OPENING:
        case serviceTypes.BROKEN_GLASS:
        case serviceTypes.MARTEN_BITE:
        case serviceTypes.ROADKILL: {
            return {
                ...service,
                driverType: service?.driver?.type,
                driverFirstName: service?.driver?.name,
                driverLastName: service?.driver?.surname,
                isDriverAddressAsMember,
                ...(!isDriverAddressAsMember && {
                    driverAddressPostCode: service?.driver?.address?.postCode,
                    driverAddressCity: service?.driver?.address?.city,
                    driverAddressStreet: service?.driver?.address?.street,
                }),
                ownerType: service?.owner?.type,
                ownerFirstName: service?.owner?.name,
                ownerLastName: service?.owner?.surname,
                isOwnerAddressAsMember,
                ...(!isOwnerAddressAsMember && {
                    ownerAddressPostCode: service?.owner?.address?.postCode,
                    ownerAddressCity: service?.owner?.address?.city,
                    ownerAddressStreet: service?.owner?.address?.street,
                }),
                damageLocation: service?.damage?.address,
                damageDate: service?.damage?.date,
                ...(service?.type === services.TOWING && {towingLocation: service?.towing?.address}),
                isAccidentCausedByThirdParty,
                ...(isAccidentCausedByThirdParty && {
                    thirdPartyName: service.thirdParty.name,
                    thirdPartyAddressCity: service.thirdParty.address?.city,
                    thirdPartyAddressStreet: service.thirdParty.address?.street,
                    thirdPartyAddressPostCode: service.thirdParty.address?.postCode,
                    thirdPartyInsuranceName: service.thirdParty.insuranceName,
                }),
                ...(service?.thirdParty && {
                    isThirdPartyCost: !!service.thirdParty.costsCoveredBy || !!service.thirdParty.costsPurpose
                        || !!service.thirdParty.costsAmount,
                    thirdPartyCostsPurpose: service.thirdParty.costsPurpose,
                    thirdPartyCostsAmount: service.thirdParty.costsAmount,
                    thirdPartyCostsCoveredBy: service.thirdParty.costsCoveredBy,
                }),
            };
        }
        case serviceTypes.ACCOMMODATION:
        case serviceTypes.FURTHER_JOURNEY:
        case serviceTypes.RENTAL_CAR:
        case serviceTypes.SHORT_TRIP: {
            return {
                ...service,
                ...(service.type === serviceTypes.RENTAL_CAR && {
                    isDestinationAddressDifferent: !!service?.destinationAddress
                        && Object.keys(service.destinationAddress).length > 0,
                }),
            };
        }
        case categories.OTHER: {
            return service;
        }
        default:
        // no-op
    }
};

export const mapFormValuesToServiceFields = (formValues, member) => {
    if (!formValues) return null;
    let serviceForm;
    const [memberFirstName = '', memberLastName = ''] = member?.name
        ? member.name.split(' ')
        : ['', ''];

    switch (formValues.category) {
        case categories.ROADSIDE_ASSISTANCE_AND_TOWING: {
            const {roadsideAssistanceAndTowing: form} = formValues;
            if (!(form && typeof form === 'object' && Object.values(form).length > 0)) break;
            serviceForm = {
                ...form,
                damage: {
                    date: form.damageDate ? moment(form.damageDate, ['DD.MM.YYYY', moment.ISO_8601]).toISOString() : '',
                    description: form.comment,
                    address: form.damageLocation,
                    location: form.damageLocation.location,
                    formattedAddress: form.damageLocation.formattedAddress,
                },
                ...(form.type === services.TOWING && {
                    towing: {
                        address: form.towingLocation,
                    },
                }),
                driver: form.driverType ? {
                    type: form.driverType,
                    name: form.driverType === personTypes.MEMBER
                        ? memberFirstName
                        : form.driverFirstName,
                    surname: form.driverType === personTypes.MEMBER
                        ? memberLastName
                        : form.driverLastName,
                    address: form.driverType === personTypes.MEMBER
                    || (form.driverType !== personTypes.MEMBER && form.isDriverAddressAsMember)
                        ? member?.address
                        : {
                            street: form.driverAddressStreet,
                            postCode: form.driverAddressPostCode,
                            city: form.driverAddressCity,
                            country: null,
                            countryCode: null,
                        },
                } : undefined,
                owner: form.ownerType ? {
                    type: form.ownerType,
                    name: form.ownerType === personTypes.MEMBER
                        ? memberFirstName
                        : form.ownerFirstName,
                    surname: form.ownerType === personTypes.MEMBER
                        ? memberLastName
                        : form.ownerLastName,
                    address: form.ownerType === personTypes.MEMBER
                    || (form.ownerType !== personTypes.MEMBER && form.isOwnerAddressAsMember)
                        ? member?.address
                        : {
                            street: form.ownerAddressStreet,
                            postCode: form.ownerAddressPostCode,
                            city: form.ownerAddressCity,
                            country: null,
                            countryCode: null,
                        },
                } : undefined,
                ...((form.isAccidentCausedByThirdParty || form.isThirdPartyCost) && {
                    thirdParty: {
                        name: form.thirdPartyName,
                        licensePlate: form.thirdPartyLicensePlate,
                        isCoveringCosts: form.isInsuranceCoversCosts,
                        isCausedAccident: form.isAccidentCausedByThirdParty,
                        insuranceName: form.thirdPartyInsuranceName,
                        insuranceNumber: form.thirdPartyInsuranceNumber,
                        damageNumber: form.thirdPartyDamageNumber,
                        costsCoveredBy: form.thirdPartyCostsCoveredBy,
                        costsPurpose: form.thirdPartyCostsPurpose,
                        costsAmount: form.thirdPartyCostsAmount,
                        address: {
                            street: form.thirdPartyAddressStreet,
                            postCode: form.thirdPartyAddressPostCode,
                            city: form.thirdPartyAddressCity,
                            country: null,
                            countryCode: null,
                        },
                    },
                }),
                requestedInvoicingAmount: Number(form.requestedInvoicingAmount),
                ...(form.endOfRecoveryDateTime && {
                    endOfRecoveryDateTime: moment(form.endOfRecoveryDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                }),
            };
            break;
        }
        case categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION: {
            const {journeyContinuationAndAccommodation: form} = formValues;
            if (!form?.type) break;

            let serviceSubForm;
            if (form.type === serviceTypes.FURTHER_JOURNEY
                || form.type === serviceTypes.SHORT_TRIP) {
                const {returnJourneyAndShortTrip} = form;
                serviceSubForm = {
                    ...returnJourneyAndShortTrip,
                };
            }
            if (form.type === serviceTypes.RENTAL_CAR) {
                const {rentalCar} = form;
                serviceSubForm = {
                    ...rentalCar,
                };
            }
            if (form.type === serviceTypes.ACCOMMODATION) {
                const {accommodation} = form;
                serviceSubForm = {
                    ...accommodation,
                };
            }
            serviceForm = {
                ...form,
                ...serviceSubForm,
                requestedInvoicingAmount: Number(form.requestedInvoicingAmount),
            };
            break;
        }
        case categories.AID: {
            const {aid: form} = formValues;
            if (!(form && typeof form === 'object' && Object.values(form).length > 0)) break;

            serviceForm = {
                ...form,
                driver: form.driverType ? {
                    type: form.driverType,
                    name: form.driverType === personTypes.MEMBER
                        ? memberFirstName
                        : form.driverFirstName,
                    surname: form.driverType === personTypes.MEMBER
                        ? memberLastName
                        : form.driverLastName,
                    address: form.driverType === personTypes.MEMBER
                    || (form.driverType !== personTypes.MEMBER && form.isDriverAddressAsMember)
                        ? member?.address
                        : {
                            street: form.driverAddressStreet,
                            postCode: form.driverAddressPostCode,
                            city: form.driverAddressCity,
                            country: null,
                            countryCode: null,
                        },
                } : undefined,
                owner: form.ownerType ? {
                    type: form.ownerType,
                    name: form.ownerType === personTypes.MEMBER
                        ? memberFirstName
                        : form.ownerFirstName,
                    surname: form.ownerType === personTypes.MEMBER
                        ? memberLastName
                        : form.ownerLastName,
                    address: form.ownerType === personTypes.MEMBER
                    || (form.ownerType !== personTypes.MEMBER && form.isOwnerAddressAsMember)
                        ? member?.address
                        : {
                            street: form.ownerAddressStreet,
                            postCode: form.ownerAddressPostCode,
                            city: form.ownerAddressCity,
                            country: null,
                            countryCode: null,
                        },
                } : undefined,
                damage: {
                    date: form.damageDate ? moment(form.damageDate, ['DD.MM.YYYY', moment.ISO_8601]).toISOString() : '',
                    description: form.comment,
                    address: form.damageLocation,
                    location: form.damageLocation.location,
                },
                requestedInvoicingAmount: Number(form.requestedInvoicingAmount),
                ...(form.endOfRecoveryDateTime && {
                    endOfRecoveryDateTime: moment(form.endOfRecoveryDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                }),
            };
            break;
        }
        case categories.OTHER: {
            if (!(formValues['other'] && typeof formValues['other'] === 'object'
                && Object.values(formValues['other']).length > 0)) break;

            serviceForm = {
                type: serviceTypes.OTHER,
                ...formValues['other'],
                requestedInvoicingAmount: Number(formValues['other'].requestedInvoicingAmount),
            };
            break;
        }
    }
    return serviceForm;
};
