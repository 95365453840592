import {call, fork} from 'redux-saga/effects';
import {connectedRouterSaga} from '@computerrock/formation-router';
import getRoutes from './routes';
import authWatcher from './auth/authWatcher';
import applicationWatcher from './application/applicationWatcher';
import invoiceSubmissionFiles from './invoice-submission/invoiceSubmissionFiles';
import invoiceSubmissionWatcher from './invoice-submission/invoiceSubmissionWatcher';
import serviceCasesWatcher from './service-case/serviceCasesWatcher';

// application sagas
export default [
    fork(authWatcher),
    fork(applicationWatcher),
    fork(invoiceSubmissionWatcher),
    fork(invoiceSubmissionFiles.watcher),
    fork(serviceCasesWatcher),
    // connected router saga should be last in sequence!
    call(connectedRouterSaga, getRoutes()),
];
