import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';
import Attachment from './Attachment';

/**
 * AttachmentsDetails entity type definition
 *
 * @typedef {Object} AttachmentsDetails
 * @property {?Boolean} invoiceUploaded
 * @property {?Boolean} repairInvoiceUploaded
 * @property {?Boolean} carInsuranceUploaded
 * @property {?Boolean} wildlifeDamageCertUploaded
 * @property {?Array<Attachment>} attachments
 */

/**
 * AttachmentsDetails entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const attachmentsDetailsEntityDTOMapping = {
    invoiceUploaded: 'invoiceUploaded',
    repairInvoiceUploaded: 'repairInvoiceUploaded',
    carInsuranceUploaded: 'carInsuranceUploaded',
    wildlifeDamageCertUploaded: 'wildlifeDamageCertUploaded',
    attachments: {
        dtoProperty: 'attachments',
        entity: Attachment,
        isEntityArray: true,
        defaultValue: [],
        toPatchDTO: attachments => {
            if (Array.isArray(attachments)) {
                return {
                    value: attachments.map(attachment => Attachment.objectToDTO({
                        id: attachment.id,
                        fileName: attachment.fileName,
                    })),
                };
            }
            return null;
        },
    },
};

/**
 * AttachmentsDetails entity
 *
 * @class
 * @extends Entity
 * @type {AttachmentsDetails}
 */
export default class AttachmentsDetails extends Entity {
    [immerable] = true;

    static entityDTOMapping = attachmentsDetailsEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
