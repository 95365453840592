import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {InvoiceSubmission} from '../../mia-entities/invoice-submission';

const updateInvoiceSubmissionDraft = function* updateInvoiceSubmissionDraft() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_DRAFT);
        const {invoiceSubmissionDraftData = {}, selectStep, currentStep} = payload;
        const {invoiceSubmissionDraft} = yield select(state => state.invoiceSubmissions);

        if (!(invoiceSubmissionDraft
            && typeof invoiceSubmissionDraft === 'object'
            && Object.keys(invoiceSubmissionDraft).length > 0)
        ) continue;

        yield fork(fetchRequest,
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST,
            miaService.updateInvoiceSubmission, {
                invoiceSubmissionDraftData: InvoiceSubmission.objectToPatchDTO(invoiceSubmissionDraftData),
                invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
            });

        const actionResult = yield take([
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED,
        ]);

        if (actionResult.error) continue;

        const {response} = actionResult.payload;
        yield put({
            type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
            payload: {invoiceSubmissionDraft: response},
        });

        if (!selectStep || !currentStep) continue;

        selectStep(currentStep + 1);
    }
};

export default updateInvoiceSubmissionDraft;
