import {isValidIBAN, electronicFormatIBAN} from 'ibantools';
import bic from 'bic';
import moment from 'moment';
import {journeyContinuationServices} from '../constants/journeyContinuationServices';
import {personTypes} from '../constants/person';
import {services} from '../constants/services';

const isValidDate = date => {
    if (!date) return false;
    const formattedDate = moment(date, ['DD.MM.YYYY', moment.ISO_8601]).format('DD.MM.YYYY');
    return moment(formattedDate, 'DD.MM.YYYY', true).isValid();
};

const driverValidationError = formValues => {
    if (!formValues) return true;
    return (!!formValues.driverType
        && (formValues.driverType !== personTypes.MEMBER
            && ((!formValues.driverFirstName || !formValues.driverLastName)
                || (!formValues.isDriverAddressAsMember
                    && (!formValues.driverAddressPostCode
                        || !formValues.driverAddressCity
                        || !formValues.driverAddressStreet))
            )));
};

const ownerValidationError = formValues => {
    if (!formValues) return true;
    return (!!formValues.ownerType
        && (formValues.ownerType !== personTypes.MEMBER
            && ((!formValues.ownerFirstName || !formValues.ownerLastName) || (
                !formValues.isOwnerAddressAsMember
                    && (!formValues.ownerAddressPostCode
                        || !formValues.ownerAddressCity
                        || !formValues.ownerAddressStreet))
            )));
};

const dataValidationError = formValues => {
    if (!formValues) return true;
    return (!formValues.type || !formValues.driverType || !formValues.ownerType || !formValues.tripPurpose
        || (!formValues?.damageLocation || !Object.keys(formValues?.damageLocation).find(key => (
            key === 'formattedAddress' || key === 'city' || key === 'street'))) || !isValidDate(formValues.damageDate)
        || (!!formValues.endOfRecoveryDateTime
            && (!isValidDate(formValues.endOfRecoveryDateTime)
                || (formValues.damageDate
                    && !moment(formValues.damageDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).isSameOrBefore(moment(formValues.endOfRecoveryDateTime, ['YYYY-MM-DD', 'DD.MM.YYYY'])))))
        || (formValues.type === services.TOWING
            && (!formValues?.towingLocation || !Object.keys(formValues?.towingLocation).find(key => (
                key === 'formattedAddress' || key === 'city' || key === 'street'))))
        || !formValues.serviceProvider);
};

const thirdPartyValidationError = formValues => {
    return (!!formValues.isAccidentCausedByThirdParty
        && (!formValues.thirdPartyName || !formValues.thirdPartyAddressCity
            || !formValues.thirdPartyAddressStreet || !formValues.thirdPartyAddressPostCode
            || !formValues.thirdPartyInsuranceName));
};

const towingAndRoadsideAssistenceTogglesValidationError = formValues => {
    return ((!!formValues.isAlcoholTestDone && !formValues.alcoholTestResult)
        || (!!formValues.isInsuranceCoversCosts
            && (!formValues.insuranceName || !formValues.insuranceNumber))
        || (!!formValues.isThirdPartyCost
            && (!formValues.thirdPartyCostsPurpose
                || !formValues.thirdPartyCostsAmount || !formValues.thirdPartyCostsCoveredBy)));
};

export const isTowingAndRoadsideAssistanceValidationError = formValues => {
    return !formValues || !formValues.requestedInvoicingAmount
        || ownerValidationError(formValues)
        || driverValidationError(formValues) || dataValidationError(formValues)
        || towingAndRoadsideAssistenceTogglesValidationError(formValues) || thirdPartyValidationError(formValues);
};

export const isOtherFormError = formValues => {
    return !formValues?.requestedInvoicingAmount || !formValues?.comment;
};

export const isAidFormError = (formValues, aidServicePrice) => {
    return !formValues || !formValues.requestedInvoicingAmount
        || (formValues.requestedInvoicingAmount && formValues.requestedInvoicingAmount > aidServicePrice)
        || ownerValidationError(formValues)
        || driverValidationError(formValues)
        || dataValidationError(formValues);
};

export const isJourneyContinuationAndAccommodationError = formValues => {
    if (!formValues?.type || !formValues.requestedInvoicingAmount) return true;
    if (formValues.type === journeyContinuationServices.FURTHER_JOURNEY
        || formValues.type === journeyContinuationServices.SHORT_TRIP) {
        const {returnJourneyAndShortTrip: serviceSubFormValues} = formValues;
        // when service is already submitted we're validating existing service values
        const serviceValues = serviceSubFormValues && Object.keys(serviceSubFormValues).length > 0
            ? serviceSubFormValues
            : formValues;

        return (!serviceValues?.startingAddress || !Object.keys(serviceValues?.startingAddress).find(key => (
            key === 'formattedAddress' || key === 'city' || key === 'street')))
            || (!serviceValues?.destinationAddress || !Object.keys(serviceValues?.destinationAddress).find(key => (
                key === 'formattedAddress' || key === 'city' || key === 'street')))
            || !isValidDate(serviceValues?.startDateTime || null);
    }
    if (formValues.type === journeyContinuationServices.RENTAL_CAR) {
        const {rentalCar: serviceSubFormValues} = formValues;
        // when service is already submitted we're validating existing service values
        const serviceValues = serviceSubFormValues && Object.keys(serviceSubFormValues).length > 0
            ? serviceSubFormValues
            : formValues;

        return !isValidDate(serviceValues?.startDateTime || null)
            || !isValidDate(serviceValues?.endDateTime || null)
            || (!serviceValues?.startingAddress || !Object.keys(serviceValues?.startingAddress).find(key => (
                key === 'formattedAddress' || key === 'city' || key === 'street')))
            || (!!serviceValues?.isDestinationAddressDifferent
                && (!serviceValues?.destinationAddress || !Object.keys(serviceValues?.destinationAddress).find(key => (
                    key === 'formattedAddress' || key === 'city' || key === 'street'))));
    }
    if (formValues.type === journeyContinuationServices.ACCOMMODATION) {
        const {accommodation: serviceSubFormValues} = formValues;
        // when service is already submitted we're validating existing service values
        const serviceValues = serviceSubFormValues && Object.keys(serviceSubFormValues).length > 0
            ? serviceSubFormValues
            : formValues;

        return !isValidDate(serviceValues?.startDateTime || null)
            || !isValidDate(serviceValues?.endDateTime || null)
            || !serviceValues?.name
            || !serviceValues?.numberOfPeople || !(Number(serviceValues?.numberOfPeople) > 0);
    }
};

export const isInvoiceValidationError = (formValues, files) => {
    /* eslint-disable-next-line max-len */
    const areMandatoryCheckboxesChecked = (formValues?.hasOwnProperty('invoiceUploaded') ? !!formValues['invoiceUploaded'] : true)
        && (!formValues?.hasOwnProperty('invoiceUploaded') && formValues?.hasOwnProperty('repairInvoiceUploaded')
            ? !!formValues['repairInvoiceUploaded'] : true)
        && (formValues?.hasOwnProperty('carInsuranceUploaded') ? !!formValues['carInsuranceUploaded'] : true)
        && (formValues?.hasOwnProperty('wildlifeDamageCertUploaded') ? !!formValues['wildlifeDamageCertUploaded'] : true);
    return !files || Object.values(files).length === 0 || !areMandatoryCheckboxesChecked;
};

export const isBankValidationError = formValues => {
    if (!formValues) return true;
    return !formValues['bankAccountOwner'] || !formValues['iban']
        || (formValues['swift'] && !bic.isValid(formValues['swift']))
        || !isValidIBAN(electronicFormatIBAN(formValues['iban'].toUpperCase()));
};

export const emailValidation = email => {
    if (!email) return true;
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const phoneNumberValidation = phoneNumber => {
    if (!phoneNumber) return true;
    return /^\+?\d+?\/?\d+$/.test(phoneNumber);
};
