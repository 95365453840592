import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {calendarIcon, Checkbox, ContentBlock, ContentItem, DateField, Form, Input, TextAreaField, ToggleSwitch, useStyles} from '../../ui-components';
import SearchAutocomplete from '../../google/ui-components/SearchAutocomplete';
import {getGeolocationFromString} from '../../google/googleMapFunctions';

const RentalCarSubForm = props => {
    const {cx} = useStyles();
    const {service} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('rental_car_sub_form');
    const [emptyPickUpAddress, setEmptyPickUpAddress] = useState(false);
    const [emptyReturnAddress, setEmptyReturnAddress] = useState(false);
    const [locations, setLocations] = useState({
        startingAddress: service?.startingAddress,
        destinationAddress: service?.destinationAddress,
    });

    const parseAddress = (field, value) => {
        field === 'startingAddress' ? setEmptyPickUpAddress(!value) : setEmptyReturnAddress(!value);

        getGeolocationFromString(value).then(result => {
            let address = null;
            if (result && result.address) {
                address = {
                    street: result.address.street || '',
                    postCode: result.address.postCode,
                    city: result.address.city,
                    country: result.address.country,
                    formattedAddress: result.address.formattedAddress,
                    location: {
                        longitude: result.location?.lng,
                        latitude: result.location?.lat,
                    },
                };
                setLocations(prevState => ({
                    ...prevState,
                    [field]: address,
                }));
                return;
            }
            setLocations(prevState => ({
                ...prevState,
                [field]: address,
            }));
        });
    };

    return (
        <Form name="rentalCar">
            {formValues => (
                <Fragment>
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        {translateForm('title.add_details')}
                    </h3>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--small-bottom-16',
                        ])}
                    >
                        {translateForm('title.period_of_rental')}
                    </p>
                    <ContentBlock className={cx('global!ace-u-margin--small-bottom-24')}>
                        <ContentItem className={cx('ace-c-content-item--base-span-6')}>
                            <DateField
                                name="startDateTime"
                                label={translateForm('date_field_label.rental_starts')}
                                icon={calendarIcon}
                                format="DD.MM.YYYY"
                                className={cx('global!ace-u-full-width')}
                                value={service?.startDateTime
                                    ? moment(service?.startDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                    : ''}
                            />
                        </ContentItem>
                        <ContentItem className={cx('ace-c-content-item--base-span-6')}>
                            <DateField
                                name="endDateTime"
                                label={translateForm('date_field_label.rental_ends')}
                                icon={calendarIcon}
                                format="DD.MM.YYYY"
                                className={cx('global!ace-u-full-width')}
                                value={service?.endDateTime
                                    ? moment(service.endDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                    : ''}
                            />
                        </ContentItem>
                    </ContentBlock>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--small-bottom-16',
                        ])}
                    >
                        {translateForm('text.place_of_rental')}
                    </p>
                    <SearchAutocomplete
                        placeholder={translateForm('input_placeholder.pick_up_address')}
                        name="formattedStartingAddress"
                        label={translateForm('input_label.pick_up_address')}
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--large-bottom-32',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                        onBlur={() => setEmptyPickUpAddress(!locations.startingAddress)}
                        onOptionSelect={value => parseAddress('startingAddress', value)}
                        value={formValues?.hasOwnProperty('startingAddress')
                            ? formValues.formattedStartingAddress
                            : service?.startingAddress
                                ? service.startingAddress.formattedAddress
                                || Object.keys(service?.startingAddress)
                                    .filter(key => key !== 'country' && key !== 'location' && key !== 'persistenceState')
                                    .map(key => (service?.startingAddress[key]))
                                    .filter(value => !!value)
                                    .join(' ')
                                : ''}
                        onChange={() => setLocations(prevState => ({
                            ...prevState,
                            startingAddress: false,
                        }))}
                        errors={emptyPickUpAddress ? [translate('global.error.empty_location')] : []}
                    />
                    <Input
                        name="startingAddress"
                        type="hidden"
                        value={locations?.startingAddress || ''}
                    />
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                            {translateForm('toggle_switch_label.return_rental_address_different')}
                        </p>
                        <ToggleSwitch
                            name="isDestinationAddressDifferent"
                            value={true}
                            isSelected={!!service?.destinationAddress
                                && Object.keys(service.destinationAddress).length > 0}
                        />
                    </div>
                    {!!formValues?.isDestinationAddressDifferent
                        && (
                            <Fragment>
                                <SearchAutocomplete
                                    placeholder={translateForm('input_placeholder.return_address')}
                                    name="formattedDestinationAddress"
                                    label={translateForm('input_label.return_address')}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--large-bottom-32',
                                        'global!ace-u-margin--small-bottom-24',
                                    ])}
                                    onBlur={() => setEmptyReturnAddress(!locations.destinationAddress)}
                                    onOptionSelect={value => parseAddress('destinationAddress', value)}
                                    value={formValues?.hasOwnProperty('formattedDestinationAddress')
                                        ? formValues.formattedDestinationAddress
                                        : service?.destinationAddress
                                            ? service.destinationAddress.formattedAddress
                                            || Object.keys(service?.destinationAddress)
                                                .filter(key => key !== 'country' && key !== 'location' && key !== 'persistenceState')
                                                .map(key => (service?.destinationAddress[key]))
                                                .filter(value => !!value)
                                                .join(' ')
                                            : ''}
                                    onChange={() => setLocations(prevState => ({
                                        ...prevState,
                                        destinationAddress: false,
                                    }))}
                                    errors={emptyReturnAddress ? [translate('global.error.empty_location')] : []}
                                />
                                <Input
                                    name="destinationAddress"
                                    type="hidden"
                                    value={locations?.destinationAddress || ''}
                                />
                            </Fragment>
                        )
                    }
                    <Checkbox
                        className={cx(['global!ace-u-margin--small-bottom-24'])}
                        name="isDelivered"
                        value={true}
                        isSelected={!!service?.isDelivered}
                    >
                        {translateForm('checkbox_label.vehicle_delivered')}
                    </Checkbox>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--small-bottom-16',
                        ])}
                    >
                        {translateForm('title.remarks')}
                    </p>
                    <TextAreaField
                        placeholder={translateForm('text_area_placeholder.rental_car_additional_comment')}
                        name="comment"
                        value={service?.comment}
                        maxLength={250}
                        isResizable={false}
                        className={cx(['ace-c-text-area--small', 'global!ace-u-margin--small-bottom-24', 'global!ace-u-full-width'])}
                        label={translateForm('text_area_label.rental_car_additional_comment')}
                    />
                </Fragment>
            )}
        </Form>
    );
};

RentalCarSubForm.propTypes = {
    service: PropTypes.object.isRequired,
};

export default RentalCarSubForm;
