import React from 'react';
import PropTypes from 'prop-types';
import {arrowLeftIcon, arrowRightIcon, Icon} from '../icons';
import {useStyles} from '../index';

const Pagination = props => {
    const {cx} = useStyles(props);
    const {pagesLength, onPageSwitch, currentPage} = props;
    const {firstPages, lastPages} = props;

    const handleOnPageSwitch = page => {
        if (page === currentPage) return;
        onPageSwitch(page);
    };

    return (
        <div
            className={cx([
                'global!ace-u-flex',
            ])}
        >
            <Icon
                className={cx({
                    'ace-c-icon--color-warning': currentPage !== 1,
                })}
                icon={arrowLeftIcon}
                onClick={() => handleOnPageSwitch(currentPage - 1)}
                isDisabled={currentPage === 1}
            />
            {firstPages.length > 0 && (
                firstPages.map((page, index) => (
                    <p
                        className={cx('global!ace-u-typography--color-warning', {
                            'global!ace-u-typography--variant-body-bold': currentPage === page,
                            'global!ace-u-cursor--pointer': currentPage !== page,
                        })}
                        key={index}
                        onClick={() => handleOnPageSwitch(page)}
                    >
                        {page}{index !== firstPages.length - 1 ? ', ' : ''}
                    </p>
                ))
            )}
            {(firstPages.indexOf(currentPage) > -1 || lastPages.indexOf(currentPage) > -1)
            && (
                <p className={cx('global!ace-u-typography--color-warning')}>
                    {firstPages.length + lastPages.length === pagesLength
                        ? lastPages.length > 0
                            ? ', ' : ''
                        : '...'}
                </p>
            )}
            {firstPages.indexOf(currentPage) <= -1 && lastPages.indexOf(currentPage) <= -1
                && (
                <p
                    className={cx([
                        'global!ace-u-typography--color-warning',
                        'global!ace-u-typography--variant-body-bold',
                    ])}
                >
                    ...{currentPage}...
                </p>
                )
               }
            {lastPages.length > 0 && (
                lastPages.map((page, index) => (
                    <p
                        className={cx('global!ace-u-typography--color-warning', {
                            'global!ace-u-typography--variant-body-bold': currentPage === page,
                            'global!ace-u-cursor--pointer': currentPage !== page,
                        })}
                        key={index}
                        onClick={() => handleOnPageSwitch(page)}
                    >
                        {page}{index !== lastPages.length - 1 ? ', ' : ''}
                    </p>
                ))
            )}
            <Icon
                className={cx({
                    'ace-c-icon--color-warning': currentPage !== pagesLength,
                })}
                icon={arrowRightIcon}
                onClick={() => handleOnPageSwitch(currentPage + 1)}
                isDisabled={currentPage === pagesLength}
            />
        </div>
    );
};

Pagination.propTypes = {
    pagesLength: PropTypes.number.isRequired,
    onPageSwitch: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    firstPages: PropTypes.array.isRequired,
    lastPages: PropTypes.array.isRequired,
};

export default Pagination;
