export const services = {
    ROADSIDE_ASSISTANCE: 'ROADSIDE_ASSISTANCE',
    TOWING: 'TOWING',
    VEHICLE_OPENING: 'VEHICLE_OPENING',
};

export const servicesTexts = {
    [services.ROADSIDE_ASSISTANCE]: 'Pannenhilfe',
    [services.TOWING]: 'Abschleppen',
    [services.VEHICLE_OPENING]: 'Schlüsselservice',
};
