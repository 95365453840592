
export const getGeolocationFromString = (stringAddress, geocoder = new window.google.maps.Geocoder()) => {
    if (!stringAddress) {
        return Promise.resolve({
            location: null,
            address: null,
        });
    }

    return geocoder.geocode({address: stringAddress}).then(({results}) => {
        if (results && results.length) {
            const [bestResult] = results;
            const location = bestResult.geometry.location;
            const lc = {
                lat: location.lat(),
                lng: location.lng(),
            };

            const resultingAddress = parseAddress(results);
            return {
                location: lc,
                address: resultingAddress,
            };
        }
        return null;
    })
        .catch(() => {
            return {
                location: null,
                address: null,
            };
        });
};


export const parseAddress = query => {
    const resultAddr = query.find(({types}) => {
        return types.indexOf('street_address') !== -1 // allow street addresses queries
            || types.indexOf('route') !== -1 // allow freeway queries
            || (types.indexOf('locality') !== -1 && types.indexOf('political') !== -1) // allow cities queries
            || types.indexOf('premise') !== -1 // allow building queries
            || types.indexOf('point_of_interest') !== -1; // allow POI queries
    });

    if (resultAddr && resultAddr.hasOwnProperty('address_components')) {
        const res = getParsedAddress(resultAddr.address_components);
        if (res.name) {
            res.street = res.name.concat(', ', res.street);
        }
        if (res.num) {
            res.street = res.street.concat(' ', res.num);
        }
        res.formattedAddress = query[0].formatted_address;
        return res;
    }
};

export const getParsedAddress = addressComponents => {
    const res = {};
    if (addressComponents) {
        addressComponents.forEach(component => {
            const types = component.types;
            if (types.indexOf('route') !== -1) {
                res.street = component.long_name;
            } else if (types.indexOf('point_of_interest') !== -1) {
                res.name = component.long_name;
            } else if (types.indexOf('street_number') !== -1) {
                res.num = `${parseInt(component.long_name, 10)}`;
            } else if (types.indexOf('locality') !== -1 && types.indexOf('political') !== -1) {
                res.city = component.long_name;
            } else if (types.indexOf('postal_code') !== -1) {
                res.postCode = component.long_name;
            } else if (types.indexOf('country') !== -1) {
                res.country = component.long_name;
            }
        });
    }
    return res;
};

