import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {createInvoiceSubmissionStatuses} from '../constants/createInvoiceSubmissionStatuses';
import {InvoiceSubmission} from '../../mia-entities/invoice-submission';

const submitInvoiceSubmission = function* submitInvoiceSubmission() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION);
        const {statusData, invoiceSubmissionId, invoiceSubmissionData} = payload;

        if (invoiceSubmissionData) {
            yield fork(
                fetchRequest,
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST,
                miaService.updateInvoiceSubmission,
                {
                    invoiceSubmissionDraftData: InvoiceSubmission.objectToPatchDTO(invoiceSubmissionData),
                    invoiceSubmissionId,
                },
            );

            const actionResult = yield take([
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
                invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED,
            ]);

            if (actionResult.error) {
                // TBD: handle errors
            }
        }

        yield fork(fetchRequest,
            invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION_REQUEST,
            miaService.updateInvoiceSubmissionStatus, {
                statusData,
                invoiceSubmissionId,
            });

        const actionResult = yield take([
            invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION_REQUEST_FAILED,
        ]);

        if (actionResult.error) {
            const {resubmitCount} = yield select(state => state.invoiceSubmissions);
            yield put({
                type: invoiceSubmissionActionTypes.STORE_CREATE_STATUS,
                payload: {
                    createInvoiceSubmissionState: resubmitCount < 0
                        ? createInvoiceSubmissionStatuses.FAILED
                        : createInvoiceSubmissionStatuses.FAILED_AGAIN,
                },
            });
            yield put({
                type: invoiceSubmissionActionTypes.STORE_RESUBMISSION_COUNT,
                payload: resubmitCount + 1,
            });
            continue;
        }
        yield put({
            type: invoiceSubmissionActionTypes.STORE_CREATE_STATUS,
            payload: {createInvoiceSubmissionState: createInvoiceSubmissionStatuses.SUCCEEDED},
        });
    }
};

export default submitInvoiceSubmission;
