import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, mailIcon, Icon, phoneIcon, Triangle, ScrollToTop, Circle} from '../ui-components';
import Card from '../ui-components/app-layout/Card';
import newRequest from '../assets/images/new_request.svg';
import overview from '../assets/images/overview.svg';
import editDraft from '../assets/images/edit_draft.svg';
import ContactDetailRow from './view-elements/ContactDetailRow';
import ContactCard from './view-elements/ContactCard';
import routePaths from '../routePaths';
import invoiceSubmissionTabs from '../invoice-submission/constants/invoiceSubmissionTabs';
import {contactInfo} from './constants/contact';
import * as invoiceSubmissionActionTypes from '../invoice-submission/invoiceSubmissionActionTypes';

const DashboardScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const {invoiceSubmissionDraft, initiateInvoiceSubmissionDraftDeletion} = props;

    return (
        <div
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--small-direction-column',
            ])}
        >
            <ScrollToTop />
            <h1
                className={cx([
                    'global!ace-u-typography--variant-h1',
                    'global!ace-u-margin--small-bottom-24',
                ])}
            >
                {translateScreen('title.welcome')}
            </h1>
            <p
                className={cx([
                    'global!ace-u-typography--variant-body-bold',
                    'global!ace-u-margin--small-bottom-24',
                ])}
            >
                {translateScreen('text.about')}
            </p>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--small-direction-column',
                    'global!ace-u-flex--large-direction-row',
                    'global!ace-u-margin--large-bottom-128',
                ])}
            >
                {!!invoiceSubmissionDraft && (
                    <Card
                        className={cx([
                            'ace-c-card--variant-white',
                            'global!ace-u-margin--small-bottom-24',
                            'global!ace-u-margin--large-right-24',
                            'global!ace-u-flex--medium-basis-30',
                        ])}
                    >
                        <Card
                            className={cx([
                                'global!ace-u-padding--medium-128',
                                'global!ace-u-padding--small-48',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-center',
                                'ace-c-card--variant-blue',
                            ])}
                        >
                            <div
                                className={cx([
                                    'global!ace-u-display--small-none',
                                    'global!ace-u-display--medium-block',
                                ])}
                            >
                                <Circle
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-center',
                                    ])}
                                >
                                    <Icon icon={editDraft} className={cx('ace-c-icon--48')} />
                                </Circle>
                            </div>
                            <div className={cx('global!ace-u-display--medium-none')}>
                                <Circle
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-center',
                                        'ace-c-circle--small',
                                    ])}
                                >
                                    <Icon icon={editDraft} className={cx('ace-c-icon--48')} />
                                </Circle>
                            </div>
                        </Card>
                        <div
                            className={cx([
                                'global!ace-u-padding--small-top-16',
                                'global!ace-u-padding--small-right-16',
                                'global!ace-u-padding--small-left-16',
                                'global!ace-u-padding--small-bottom-32',
                                'global!ace-u-padding--large-top-32',
                                'global!ace-u-padding--large-right-32',
                                'global!ace-u-padding--large-left-32',
                                'global!ace-u-padding--large-bottom-64',
                                'global!ace-u-position--relative',
                            ])}
                        >
                            <Triangle className={cx('global!ace-u-display--medium-none')} />
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-padding--small-bottom-8',
                                ])}
                            >
                                {translateScreen('invoice_submission_draft_panel.text')}
                            </p>
                            <Link
                                to={invoiceSubmissionDraft.serviceCaseId
                                && !invoiceSubmissionDraft.serviceCaseId.includes('MIA')
                                    ? resolveRoute(routePaths.INVOICE_SUBMISSION_CREATION, {
                                        serviceCaseId: invoiceSubmissionDraft.serviceCaseId,
                                        invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
                                    })
                                    : resolveRoute(routePaths.NEW_INVOICE_SUBMISSION_CREATION, {
                                        invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
                                    })}
                            >
                                <p className={cx('global!ace-u-typography--variant-text-link')}>
                                    {translateScreen('invoice_submission_draft_panel.button')}
                                </p>
                            </Link>
                        </div>
                    </Card>
                )}
                <Card
                    className={cx([
                        'ace-c-card--variant-white',
                        'global!ace-u-margin--small-bottom-24',
                        'global!ace-u-margin--large-right-24',
                    ], {
                        'global!ace-u-flex--medium-basis-30': !!invoiceSubmissionDraft,
                        'global!ace-u-flex--medium-basis-50': !invoiceSubmissionDraft,
                    })}
                >
                    <Card
                        className={cx([
                            'global!ace-u-padding--medium-128',
                            'global!ace-u-padding--small-48',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-center',
                            'ace-c-card--variant-blue',
                        ])}
                    >
                        <div
                            className={cx([
                                'global!ace-u-display--small-none',
                                'global!ace-u-display--medium-block',
                            ])}
                        >
                            <Circle
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                ])}
                            >
                                <Icon icon={newRequest} className={cx('ace-c-icon--64')} />
                            </Circle>
                        </div>
                        <div className={cx('global!ace-u-display--medium-none')}>
                            <Circle
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                    'ace-c-circle--small',
                                ])}
                            >
                                <Icon icon={newRequest} className={cx('ace-c-icon--64')} />
                            </Circle>
                        </div>
                    </Card>
                    <div
                        className={cx([
                            'global!ace-u-padding--small-top-16',
                            'global!ace-u-padding--small-right-16',
                            'global!ace-u-padding--small-left-16',
                            'global!ace-u-padding--small-bottom-32',
                            'global!ace-u-padding--large-top-32',
                            'global!ace-u-padding--large-right-32',
                            'global!ace-u-padding--large-left-32',
                            'global!ace-u-padding--large-bottom-64',
                            'global!ace-u-position--relative',
                        ])}
                    >
                        <Triangle className={cx('global!ace-u-display--medium-none')} />
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-padding--small-bottom-8',
                            ])}
                        >
                            {translateScreen('service_case_panel.text')}
                        </p>
                        {invoiceSubmissionDraft ? (
                            <p
                                onClick={initiateInvoiceSubmissionDraftDeletion}
                                className={cx('global!ace-u-typography--variant-text-link')}
                            >
                                {translateScreen('service_case_panel.button')}
                            </p>
                        ) : (
                            <Link to={resolveRoute(routePaths.SERVICE_CASES)}>
                                <p className={cx('global!ace-u-typography--variant-text-link')}>
                                    {translateScreen('service_case_panel.button')}
                                </p>
                            </Link>
                        )}
                    </div>
                </Card>
                <Card
                    className={cx([
                        'ace-c-card--variant-white',
                        'global!ace-u-margin--small-bottom-24',
                    ], {
                        'global!ace-u-flex--medium-basis-30': !!invoiceSubmissionDraft,
                        'global!ace-u-flex--medium-basis-50': !invoiceSubmissionDraft,
                    })}
                >
                    <Card
                        className={cx([
                            'global!ace-u-padding--medium-128',
                            'global!ace-u-padding--small-48',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-center',
                            'ace-c-card--variant-blue',
                        ])}
                    >
                        <div
                            className={cx([
                                'global!ace-u-display--small-none',
                                'global!ace-u-display--medium-block',
                            ])}
                        >
                            <Circle
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                ])}
                            >
                                <Icon icon={overview} className={cx('ace-c-icon--64')} />
                            </Circle>
                        </div>
                        <div className={cx('global!ace-u-display--medium-none')}>
                            <Circle
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                    'ace-c-circle--small',
                                ])}
                            >
                                <Icon icon={overview} className={cx('ace-c-icon--64')} />
                            </Circle>
                        </div>
                    </Card>
                    <div
                        className={cx([
                            'global!ace-u-padding--small-top-16',
                            'global!ace-u-padding--small-right-16',
                            'global!ace-u-padding--small-left-16',
                            'global!ace-u-padding--small-bottom-32',
                            'global!ace-u-padding--large-top-32',
                            'global!ace-u-padding--large-right-32',
                            'global!ace-u-padding--large-left-32',
                            'global!ace-u-padding--large-bottom-64',
                            'global!ace-u-position--relative',
                        ])}
                    >
                        <Triangle className={cx('global!ace-u-display--medium-none')} />
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-padding--small-bottom-8',
                            ])}
                        >
                            {translateScreen('invoice_submissions_panel.text')}
                        </p>
                        <Link
                            to={resolveRoute(
                                routePaths.INVOICE_SUBMISSIONS,
                                {activeTab: invoiceSubmissionTabs.ACTIVE},
                            )}
                        >
                            <p className={cx('global!ace-u-typography--variant-text-link')}>
                                {translateScreen('invoice_submissions_panel.button')}
                            </p>
                        </Link>
                    </div>
                </Card>
            </div>
            <ContactCard>
                <h3
                    className={cx([
                        'global!ace-u-typography--variant-body-medium',
                        'global!ace-u-margin--small-bottom-24',
                        'global!ace-u-margin--large-bottom-0',
                        'global!ace-u-margin--large-right-48',
                    ])}
                >
                    {translateScreen('contact_panel.title.contact_member_service')}
                </h3>
                <ContactDetailRow>
                    <Icon
                        className={cx([
                            'ace-c-icon--24',
                            'ace-c-icon--color-highlight',
                            'global!ace-u-margin--right-8',
                        ])}
                        icon={mailIcon}
                    />
                    <a href={contactInfo.mail.link} className={cx(['global!ace-u-typography--variant-body-medium'])}>
                        {contactInfo.mail.text}
                    </a>
                </ContactDetailRow>
                <div className={cx(['global!ace-u-margin--bottom-16', 'global!ace-u-margin--large-right-48'])} />
                <ContactDetailRow>
                    <Icon
                        className={cx([
                            'ace-c-icon--24',
                            'ace-c-icon--color-highlight',
                            'global!ace-u-margin--right-8',
                        ])}
                        icon={phoneIcon}
                    />
                    <a href={contactInfo.phone.link} className={cx(['global!ace-u-typography--variant-body-medium'])}>
                        {contactInfo.phone.text}
                    </a>
                </ContactDetailRow>
            </ContactCard>
        </div>
    );
};

DashboardScreen.propTypes = {
    invoiceSubmissionDraft: PropTypes.object,
    initiateInvoiceSubmissionDraftDeletion: PropTypes.func.isRequired,
};

DashboardScreen.defaultProps = {
    invoiceSubmissionDraft: null,
};

const mapStateToProps = state => ({
    invoiceSubmissionDraft: state.invoiceSubmissions.invoiceSubmissionDraft,
});

const mapDispatchToProps = dispatch => ({
    initiateInvoiceSubmissionDraftDeletion: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_DELETE_INVOICE_SUBMISSION_DRAFT_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
