import {immerable} from 'immer';
import Service from './Service';
import {Address} from '../address';

/**
 * AccommodationService entity type definition
 *
 * @typedef {Object} AccommodationService
 * @property {?number} numberOfPeople
 * @property {?string} name
 * @property {?string} description
 * @property {?Address} startingAddress
 * @property {?Address} destinationAddress

 */

/**
 * AccommodationService entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

const accommodationServiceEntityDTOMapping = {
    numberOfPeople: {
        dtoProperty: 'numberOfPeople',
        defaultValue: '',
    },
    name: {
        dtoProperty: 'name',
        defaultValue: '',
    },
    description: {
        dtoProperty: 'description',
        defaultValue: '',
    },
    startingAddress: {
        dtoProperty: 'startingAddress',
        entity: Address,
    },
    destinationAddress: {
        dtoProperty: 'destinationAddress',
        entity: Address,
    },
};

/**
 * AccommodationService entity
 *
 * @class
 * @extends Service
 * @type {AccommodationService}
 */
export default class AccommodationService extends Service {
    [immerable] = true;

    static entityDTOMapping = this.extendEntityDTOMapping(accommodationServiceEntityDTOMapping);

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
