import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';

/**
 * Bank entity type definition
 *
 * @typedef {Object} Bank
 * @property {string} iban
 * @property {string} swift
 * @property {string} accountOwner
 */

/**
 * Bank entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const bankEntityDTOMapping = {
    iban: {
        dtoProperty: 'iban',
        defaultValue: '',
    },
    swift: {
        dtoProperty: 'swift',
        defaultValue: '',
    },
    accountOwner: {
        dtoProperty: 'accountOwner',
        defaultValue: '',
    },
};

/**
 * Bank entity
 *
 * @class
 * @extends Entity
 * @type {Bank}
 */
export default class Bank extends Entity {
    [immerable] = true;

    static entityDTOMapping = bankEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
