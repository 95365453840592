import React from 'react';
import PropTypes from 'prop-types';
import {useRouter} from '@computerrock/formation-router';
import {useStyles, Modal, Icon, closeIcon, phoneIcon} from '../../ui-components';
import modalIds from '../../modalIds';
import {ContentBlock, ContentItem} from '../../ui-components/app-layout';
import {Circle} from '../../ui-components/general';

const TestModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop} = props;
    const {closeModal} = useRouter();

    return (
        <Modal
            contentClassName={cx('global!ace-u-flex', 'global!ace-u-flex--justify-center')}
            action={(
                <Icon icon={closeIcon} onClick={() => closeModal(modalIds.TEST_MODAL)} />
            )}
            hasBackdrop={hasBackdrop}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <Circle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'global!ace-u-margin--bottom-32',
                            'ace-c-highlight-circle-color--base',
                        ])}
                    >
                        <Icon
                            icon={phoneIcon}
                            className={cx([
                                'ace-c-icon--xl',
                                'ace-c-icon--color-base',
                            ])}
                        />
                    </Circle>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--variant-h3',
                        ])}
                    >
                        Some Title
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        some textsome textsome textsome textsome textsome textsome textsome
                        textsome textsome textsome textsome textsome textsome text
                    </p>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

TestModal.propTypes = {
    hasBackdrop: PropTypes.bool,
};

TestModal.defaultProps = {
    hasBackdrop: true,
};

export default TestModal;
