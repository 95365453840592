import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {
    useStyles,
    ContentBlock,
    ContentItem,
    Card,
    ButtonSecondary,
    ButtonSecondaryContent,
    addIcon,
    Form, RadioButtonGroup, RadioTile, ButtonPrimary, StickyBar,
} from '../ui-components';
import routePaths from '../routePaths';
import {Pagination} from '../ui-components/navigation';
import config from '../config';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import {mafCasesIcons} from '../invoice-submission/constants/cases';
import * as invoiceSubmissionActionTypes from '../invoice-submission/invoiceSubmissionActionTypes';
import {personTypes} from '../invoice-submission/constants/person';


const ServiceCasesScreen = props => {
    const {isLoading, serviceCases, serviceCasesTotalCount, onPageClick, serviceCasesCurrentPage, memberInfo} = props;
    const {createInvoiceSubmissionDraft, storeInvoiceSubmissionDraft} = props;
    const {cx} = useStyles();
    const history = useHistory();

    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('service_case_screen');

    const numberOfServiceCasesPages = Math.ceil(
        serviceCasesTotalCount / config.defaultServiceCasesPageSize,
    );
    const firstServiceCasesPages = numberOfServiceCasesPages >= (config.defaultNumberOfFirstPages + config.defaultNumberOfLastPages) // eslint-disable-line max-len
        ? [...Array(config.defaultNumberOfFirstPages).keys()].map(page => page + 1)
        : [...Array(numberOfServiceCasesPages).keys()].map(page => page + 1).slice(0, config.defaultNumberOfFirstPages);
    const lastServiceCasesPages = numberOfServiceCasesPages >= (config.defaultNumberOfFirstPages + config.defaultNumberOfLastPages) // eslint-disable-line max-len
        ? [...Array(numberOfServiceCasesPages).keys()]
            .map(page => page + 1).slice(-config.defaultNumberOfLastPages)
        : config.defaultNumberOfFirstPages - numberOfServiceCasesPages === 0
            ? []
            : [...Array(numberOfServiceCasesPages).keys()]
                .map(page => page + 1).slice(config.defaultNumberOfFirstPages - numberOfServiceCasesPages);

    const onSubmit = formValues => {
        const {serviceCaseId} = formValues;
        const {firstname, surname, bank, email, phoneNumber, ...restMemberValues} = serviceCases[serviceCaseId].member;
        createInvoiceSubmissionDraft({
            invoiceSubmissionDraftData: {
                serviceCaseId,
                prefix: serviceCases[serviceCaseId]?.prefix,
                vehicle: serviceCases[serviceCaseId]?.vehicle,
                member: {
                    ...restMemberValues,
                    name: [firstname, surname].filter(value => !!value).join(' '),
                    type: personTypes.MEMBER,
                    email: email || memberInfo?.email || '',
                    phoneNumber: phoneNumber || memberInfo?.phoneNumber || '',
                },
            },
        });
    };

    const handleOnPageClick = page => {
        onPageClick({page});
    };

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--align-center',
                'global!ace-u-flex--justify-center',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--base-span-12',
                    'ace-c-content-item--small-span-8',
                    'ace-c-content-item--medium-span-6',
                    'ace-c-content-item--large-span-4',

                ])}
            >
                {!isLoading ? (
                    <Card
                        className={cx([
                            'global!ace-u-padding--small-16',
                            'global!ace-u-padding--large-32',
                        ])}
                    >
                        <h1
                            className={cx([
                                'global!ace-u-typography--variant-h1',
                                'global!ace-u-margin--small-bottom-16',
                                'global!ace-u-typography--align-left',
                            ])}
                        >
                            {translateScreen('title.case_selection')}
                        </h1>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--large-bottom-16',
                            ])}
                        >
                            {translateScreen('text.description')}
                        </p>
                        <ContentBlock>
                            <ContentItem>
                                <ButtonSecondary
                                    onClick={() => {
                                        storeInvoiceSubmissionDraft({invoiceSubmissionDraft: null});
                                        history.push(resolveRoute(routePaths.NEW_INVOICE_SUBMISSION_CREATION));
                                    }}
                                    className={cx([
                                        'global!ace-u-margin--small-bottom-16',
                                        'global!ace-u-width--small-full',
                                        'global!ace-u-width--medium-auto',
                                    ])}
                                >
                                    <ButtonSecondaryContent
                                        icon={addIcon}
                                        iconPosition="left"
                                    >
                                        {translateScreen('button_label.create_new_case')}
                                    </ButtonSecondaryContent>
                                </ButtonSecondary>
                            </ContentItem>
                        </ContentBlock>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--small-bottom-16',
                            ])}
                        >
                            {translateScreen('radio_button_group_label.existing_cases')}
                        </p>
                        {Object.values(serviceCases).length ? (
                            <Form name="serviceCaseForm" onSubmit={onSubmit}>
                                {formValues => {
                                    return (
                                        <Fragment>
                                            <div
                                                className={cx([
                                                    'global!ace-u-padding--small-bottom-200',
                                                    'global!ace-u-padding--medium-bottom-0',
                                                ])}
                                            >
                                                <RadioButtonGroup name="serviceCaseId" value="">
                                                    { // eslint-disable-next-line max-len
                                                        Object.values(serviceCases).sort((serviceCaseA, serviceCaseB) => serviceCaseB.emergencyCallDateTime - serviceCaseA.emergencyCallDateTime)
                                                            .map(serviceCase => {
                                                                const text = `${translate(`global.case.${serviceCase.case.toLowerCase()}`)} ${serviceCase.case === 'VEHICLE' && serviceCase.vehicle.licensePlate ? `- ${serviceCase.vehicle.licensePlate}` : ''}`;
                                                                const title = serviceCase.emergencyCallDateTime ? `${serviceCase.emergencyCallDateTime.format('DD.MM.YYYY')} - ${serviceCase.emergencyCallDateTime.format('HH:mm')} Uhr` : '';
                                                                return (
                                                                    <RadioTile
                                                                        className={cx([
                                                                            'global!ace-u-full-width',
                                                                            'global!ace-u-margin--small-bottom-8',
                                                                        ])}
                                                                        key={serviceCase.serviceCaseId}
                                                                        name={serviceCase.serviceCaseId}
                                                                        title={title}
                                                                        text={text}
                                                                        icon={mafCasesIcons[serviceCase.case]}
                                                                        value={serviceCase.caseId}
                                                                        isWiderContent={true}
                                                                    />
                                                                );
                                                            })}
                                                </RadioButtonGroup>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--justify-center',
                                                        'global!ace-u-flex--align-center',
                                                    ])}
                                                >
                                                    <Pagination
                                                        pagesLength={numberOfServiceCasesPages}
                                                        onPageSwitch={handleOnPageClick}
                                                        currentPage={serviceCasesCurrentPage}
                                                        firstPages={firstServiceCasesPages}
                                                        lastPages={lastServiceCasesPages}
                                                    />
                                                </div>
                                            </div>
                                            <StickyBar
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--small-direction-column',
                                                    'global!ace-u-flex--small-align-center',
                                                    'ace-c-sticky-bar--bottom',
                                                    'ace-c-sticky-bar--margin-small-32',
                                                ])}
                                            >
                                                <ButtonPrimary
                                                    className={cx([
                                                        'global!ace-u-margin--small-top-8',
                                                        'global!ace-u-width--full',
                                                    ])}
                                                    type="submit"
                                                    name="submitButton"
                                                    isDisabled={!formValues.serviceCaseId}
                                                >
                                                    {translateScreen('button_label.choose_and_continue')}
                                                </ButtonPrimary>
                                                <ButtonSecondary
                                                    className={cx([
                                                        'global!ace-u-margin--small-top-8',
                                                        'global!ace-u-width--full',
                                                    ])}
                                                    onClick={() => {
                                                        history.push(resolveRoute(routePaths.DASHBOARD));
                                                    }}
                                                >{translateScreen('button_label.to_homepage')}
                                                </ButtonSecondary>
                                            </StickyBar>
                                        </Fragment>
                                    );
                                }}

                            </Form>
                        ) : (
                            <Fragment>
                                <h2
                                    className={cx([
                                        'global!ace-u-typography--variant-h2',
                                        'global!ace-u-margin--small-bottom-16',
                                        'global!ace-u-typography--align-center',
                                    ])}
                                >
                                    {translateScreen('no_existing_cases.message')}
                                </h2>
                                <ButtonSecondary
                                    className={cx([
                                        'global!ace-u-margin--small-top-8',
                                        'global!ace-u-width--small-full',
                                        'global!ace-u-width--medium-auto',
                                    ])}
                                    onClick={() => {
                                        history.push(resolveRoute(routePaths.DASHBOARD));
                                    }}
                                >{translateScreen('button_label.to_homepage')}
                                </ButtonSecondary>
                            </Fragment>
                        )}
                    </Card>
                ) : (
                    <h1
                        className={cx([
                            'global!ace-u-typography--variant-h1',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translate('global.loading')}
                    </h1>
                )}
            </ContentItem>
        </ContentBlock>
    );
};

ServiceCasesScreen.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    serviceCases: PropTypes.object.isRequired,
    serviceCasesTotalCount: PropTypes.number,
    onPageClick: PropTypes.func.isRequired,
    serviceCasesCurrentPage: PropTypes.number,
    createInvoiceSubmissionDraft: PropTypes.func.isRequired,
    storeInvoiceSubmissionDraft: PropTypes.func.isRequired,
    memberInfo: PropTypes.object,
};

ServiceCasesScreen.defaultProps = {
    serviceCasesTotalCount: null,
    serviceCasesCurrentPage: null,
    memberInfo: null,
};

const mapStateToProps = state => {
    return {
        isLoading: state.serviceCases.isLoading,
        serviceCases: state.serviceCases.serviceCases,
        serviceCasesTotalCount: state.serviceCases.serviceCasesTotalCount,
        serviceCasesCurrentPage: state.serviceCases.serviceCasesCurrentPage,
        memberInfo: state.member.memberInfo,
    };
};

const mapDispatchToProps = dispatch => ({
    onPageClick: payload => dispatch({
        type: serviceCaseActionTypes.GET_SERVICE_CASES_ON_PAGE_CHANGE,
        payload,
    }),
    createInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
    storeInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCasesScreen);
