import {all, fork} from 'redux-saga/effects';
import fetchServiceCasesPerPage from './sagas/fetchServiceCasesPerPage';

const serviceCasesWatcher = function* serviceCasesWatcher() {
    yield all([
        fork(fetchServiceCasesPerPage),
    ]);
};

export default serviceCasesWatcher;
