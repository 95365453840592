import {FilesPlugin} from '../files-plugin';
import config from '../config';
import * as errorsActionTypes from '../application/errorsActionTypes';
import {File} from '../mia-entities';

const invoiceSubmissionFiles = new FilesPlugin({
    name: 'invoiceSubmissionFiles',
    fileParamsUrl: `${config.MIA_SERVICE_URL}/v1/attachments/upload-parameters`,
    errorHandlingActionType: errorsActionTypes.STORE_ERROR,
    fileEntity: File,
});

export default invoiceSubmissionFiles;
