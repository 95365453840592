
export const formNames = {
    ROADSIDE_ASSISTANCE_AND_TOWING: 'ROADSIDE_ASSISTANCE_AND_TOWING',
    JOURNEY_CONTINUATION_AND_ACCOMMODATION: 'JOURNEY_CONTINUATION_AND_ACCOMMODATION',
    AID: 'AID',
    OTHER: 'OTHER',
    BANK_INFO: 'BANK_INFO',
    ATTACHMENTS: 'ATTACHMENTS',
};

export const formStepNames = {
    MEMBER_AND_VEHICLE_DATA: 'MEMBER_AND_VEHICLE_DATA',
    SERVICE_CATEGORY_DETAILS: 'SERVICE_CATEGORY_DETAILS',
    INVOICE_FORM: 'INVOICE_FORM',
    BANK_FORM: 'BANK_FORM',
    SUMMARY: 'SUMMARY',
};
