import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import config from '../../config';

const fetchServiceCasesPerPage = function* fetchServiceCasesPerPage() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.GET_SERVICE_CASES_ON_PAGE_CHANGE);
        const {page} = payload;

        yield put({
            type: serviceCaseActionTypes.STORE_REQUEST_STATE,
            payload: {isLoading: true},
        });

        yield fork(
            fetchRequest,
            serviceCaseActionTypes.GET_SERVICE_CASES_REQUEST,
            miaService.getServiceCases,
            {
                page,
                size: config.defaultServiceCasesPageSize,
            },
        );

        const actionResult = yield take([
            serviceCaseActionTypes.GET_SERVICE_CASES_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.GET_SERVICE_CASES_REQUEST_FAILED,
        ]);

        if (!actionResult.error) {
            yield put({
                type: serviceCaseActionTypes.STORE_SERVICE_CASES,
                payload: {
                    serviceCasesDTO: actionResult.payload.response.data,
                    serviceCasesTotalCount: actionResult.payload.response.totalCount,
                    serviceCasesCurrentPage: page,
                },
            });
            yield put({
                type: serviceCaseActionTypes.STORE_REQUEST_STATE,
                payload: {isLoading: false},
            });
        }
    }
};

export default fetchServiceCasesPerPage;
