import React from 'react';
import {useTranslate} from '@computerrock/formation-i18n';
import {Card, Circle, ContentBlock, ContentItem, Icon, infoIcon, mailIcon, phoneIcon, useStyles} from '../ui-components';
import ContactDetailRow from './view-elements/ContactDetailRow';
import {contactInfo} from './constants/contact';
import ContactCard from './view-elements/ContactCard';
import './Maintenance.scss';

const Maintenance = () => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateMaintenance = createTranslateShorthand('maintenance');

    return (
        <div
            className={cx([
                'maintenance-wrapper',
                'global!ace-u-flex',
                'global!ace-u-flex--justify-space-between',
                'global!ace-u-flex--small-direction-column',
            ])}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-full-height',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'ace-c-content-item--base-span-12',
                        'ace-c-content-item--small-span-8',
                        'ace-c-content-item--medium-span-6',
                        'ace-c-content-item--large-span-6',

                    ])}
                >
                    <Card
                        className={cx([
                            'global!ace-u-padding--small-16',
                            'global!ace-u-padding--large-32',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-center',
                            'ace-c-card--variant-white',
                        ])}
                    >
                        <Circle className={cx('global!ace-u-margin--small-bottom-16')}>
                            <Icon
                                icon={infoIcon}
                                className={cx([
                                    'ace-c-icon--48',
                                    'ace-c-icon--color-highlight',
                                ])}
                            />
                        </Circle>
                        <h2
                            className={cx([
                                'global!ace-u-typography--variant-h2',
                                'global!ace-u-margin--small-bottom-0',
                                'global!ace-u-margin--large-bottom-8',
                                'global!ace-u-typography--align-center',
                            ])}
                        >{translateMaintenance('section_title.maintenance')}
                        </h2>
                        <h3
                            className={cx([
                                'global!ace-u-typography--variant-h3',
                                'global!ace-u-margin--large-bottom-32',
                                'global!ace-u-margin--small-bottom-24',
                                'global!ace-u-typography--align-center',
                            ])}
                        >
                            {translateMaintenance('section_message.maintenance_on')}<br />
                            {translateMaintenance('section_message.try_later')}<br />
                            {translateMaintenance('section_message.signature')}
                        </h3>
                    </Card>
                </ContentItem>
            </ContentBlock>
            <ContactCard
                className={cx('global!ace-u-flex')}
            >
                <h3
                    className={cx([
                        'global!ace-u-typography--variant-body-medium',
                        'global!ace-u-margin--small-bottom-24',
                        'global!ace-u-margin--large-bottom-0',
                        'global!ace-u-margin--large-right-48',
                    ])}
                >
                    {translateMaintenance('title.contact_member_service')}
                </h3>
                <ContactDetailRow>
                    <Icon
                        className={cx(['ace-c-icon--24', 'ace-c-icon--color-highlight', 'global!ace-u-margin--right-8'])}
                        icon={mailIcon}
                    />
                    <a
                        href={contactInfo.mail.link}
                        className={cx(['global!ace-u-typography--variant-body-medium'])}
                    >{translateMaintenance('title.email')} {contactInfo.mail.text}
                    </a>
                </ContactDetailRow>
                <div className={cx(['global!ace-u-margin--bottom-16', 'global!ace-u-margin--large-right-48'])} />
                <ContactDetailRow>
                    <Icon
                        className={cx(['ace-c-icon--24', 'ace-c-icon--color-highlight', 'global!ace-u-margin--right-8'])}
                        icon={phoneIcon}
                    />
                    <a
                        href={contactInfo.phone.link}
                        className={cx(['global!ace-u-typography--variant-body-medium'])}
                    >{translateMaintenance('title.phone_number')} {contactInfo.phone.text}
                    </a>
                </ContactDetailRow>
            </ContactCard>
        </div>
    );
};

export default Maintenance;
