import {AccommodationService, AidService, serviceTypes, FurtherJourneyService, Service, RentalCarService, VehicleService} from './index';

const getServiceTypeClass = type => {
    switch (type) {
        case serviceTypes.ROADSIDE_ASSISTANCE:
        case serviceTypes.RECOVERY:
        case serviceTypes.TOWING:
        case serviceTypes.VEHICLE_OPENING: {
            return VehicleService;
        }
        case serviceTypes.BROKEN_GLASS:
        case serviceTypes.MARTEN_BITE:
        case serviceTypes.ROADKILL: {
            return AidService;
        }
        case serviceTypes.ACCOMMODATION: {
            return AccommodationService;
        }
        case serviceTypes.OTHER: {
            return Service;
        }
        case serviceTypes.FURTHER_JOURNEY:
        case serviceTypes.SHORT_TRIP: {
            return FurtherJourneyService;
        }
        case serviceTypes.RENTAL_CAR: {
            return RentalCarService;
        }

        default:
            return Service;
    }
};

export default getServiceTypeClass;
