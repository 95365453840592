import {select, take} from 'redux-saga/effects';
import invoiceSubmissionFiles from '../../invoice-submission/invoiceSubmissionFiles';
import * as authActionTypes from '../../auth/authActionTypes';

const provideTokenToServicesFlow = function* provideTokenToServicesFlow() {
    const {serviceManager} = yield select(state => state.application);

    while (true) {
        const {payload} = yield take(authActionTypes.AUTH_SESSION_STARTED);
        try {
            const miaService = serviceManager.loadService('miaService');
            miaService.setAccessToken(payload.token);
        } catch (e) {
            // no-op
        }
        try {
            invoiceSubmissionFiles.setAccessToken(payload.token);
        } catch (e) {
            // no-op
        }
    }
};

export default provideTokenToServicesFlow;
